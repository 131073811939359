<!-- HTML -->
<template>

<div class='notification-container' :style='style' v-show="isVisible">
	<inline-svg :src='iconSRC' class='icon h-center'/> 
	<div class='message'>
		<h2> {{ title }} </h2>
		<p> {{ content }} </p>
		<slot />
	</div>
	<!--<inline-svg :src='closeNotificationSRC' class='close-notification h-center' @click="isVisible = false"/>-->
</div>

</template>

<!-- JavaScript -->
<script>
import { mapState, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import { useSurfStore } from '@/store/surf'
import InlineSvg from 'vue-inline-svg'

export default {
	name: 'flood-risk',
	components: {
		'inline-svg': InlineSvg,
	},
	props: {
		variant: {
			type: String,
			default: 'default',
			validator: function (value) {
				return ['default', 'warning'].indexOf(value) !== -1;
			},
		},
		title: {
			type: String,
			default: '',
		},
		content: {
			type: String,
			default: '',
		},
		icon: {
			type: String,
			default: 'warning'
		},
		backgroundColor: {
			type: String,
			default: 'grey',
		},
		color: {
			type: String,
			default: 'white',
		}
	},
	data() {
		return {
			isVisible: true,
		}
	},
	mounted() {

	},
	computed: {
		style() {
			if (this.variant == 'warning') {
				return {
					'background-color': '#D34D45',
					'color': '#fff',
				}
			} else {
				return {
					'background-color': this.backgroundColor,
					'color': 'white',
				}
			}
		},
		iconSRC() {
			return require(`@/assets/icons/${this.icon}.svg`)
		},
		closeNotificationSRC() {
			return require(`@/assets/icons/xmark.svg`)
		},
		...mapState(useConfigStore, []),
		...mapState(useSurfStore, ['meanNFIP']),
	},
	methods: {
		closePanel() {
			//this.$emit('closePanelContent') 
		},
		...mapActions(useSurfStore, []),
		...mapActions(useConfigStore, []),	
	},
}

</script>

<!-- CSS -->
<style lang="scss" scoped>

h2 {
	margin: 0;
	text-align: start;
}

p {
	margin: 0;
	text-align: center;
	width: fit-content;
}

.icon {
	color: inherit;
	grid-area: icon;
}

.message { 
	display: flex;
	flex-direction: column;
	padding-left: 10px;
}

.message p {
	display: inline-block;
	text-align: start;
}

.close-notification {
	grid-area: close;
}

.close-notification:hover {
	filter: brightness(90%) !important;
}

.notification-container {
	display: grid;
	grid-template-columns: 30px 1fr 30px;
	grid-template-rows: 1fr;
	grid-template-areas:
		" icon message close";
	width: 100%;
	height: auto;
	padding: 10px;
	gap: 10px;
	border-radius: 5px;
	margin-top: 10px;
	margin-bottom: 10px;
}

</style>
