<template>
<div class='share-container' >
	<modal v-model='isReportVisible' v-show='isReportVisible'>
		<carousel
			v-if="state == 'info'"
			variant='modal' 
			class='rocketflood-modal'
			lastButton="Submit"
			:validation="{
				'getting-started': validateGettingStarted(),
				'crit-asset-type': (critAssetTypes[0].value || critAssetTypes[1].value || critAssetTypes[2].value || critAssetTypes[3].value) && (critAssetGeomTypes[0].value || critAssetGeomTypes[1].value || critAssetGeomTypes[2].value),
				'year-scenario':  isYearAndScenarioValid(),
				'report-sections': isReportSectionsValid(),
			}"
			@hasValidationError="state => hasValidationError = state"
			@lastButton='submitReport'
			:restartCarousel='restart'
			@hasRestarted='(el) => restart = false '
			@slideChanged='slideChanged'
			@closeCarousel='closeCarousel'
			noScroll
			
		>
			<!-- getting started -->
			<div class='info-container' id='getting-started'>
				<span>
				<h5 class='title' style="color:red;" v-show="hasValidationError"> {{ textContent['VulnerabilityReport']['getting-started-slide-options']['title'] }}</h5>
				<h5 class='title'> {{ textContent['VulnerabilityReport']['getting-started-slide']['title'] }} </h5>
				<p> {{ textContent['VulnerabilityReport']['getting-started-slide']['text'] }} </p>
				</span>
				<div class='info-content'>
					<div class='info'>
						<p> {{ textContent['VulnerabilityReport']['getting-started-slide-options']['text'].split('|')[0]}} </p>
						<o-field 
								:rootClass="isValid(countyOrCityName) ? 'success' : 'missing'" 
							>	
							<o-input inputClass='arkly-input' v-model="countyOrCityName" type='string' placeholder="" ref='countyOrCityName'/>
						</o-field>
					</div>
					<div class='info'>
						<p> {{ textContent['VulnerabilityReport']['getting-started-slide-options']['text'].split('|')[1]}}</p>
						<div class='select'>
							<div> 
								<input type="radio" id='isCityTrue' :value='true' v-model='isCity' />
								<label> {{ textContent['VulnerabilityReport']['getting-started-slide-options']['text'].split('|')[2]}}</label>
							</div>
							<div>
								<input type="radio" id='isCityFalse' :value='false' v-model='isCity' />
								<label> {{ textContent['VulnerabilityReport']['getting-started-slide-options']['text'].split('|')[3]}}</label>
							</div>
						</div>
					</div>
				</div>
				<div class='info' v-if="isCity == false">
					<p> {{ textContent['VulnerabilityReport']['getting-started-slide-options']['text'].split('|')[4]}}</p>
					<div class='select'>
						<div> 
							<input type="radio" id='includeCityTrue' :value='true' v-model='includeCity' />
							<label> Yes</label>
						</div>
						<div>
							<input type="radio" id='includeCityFalse' :value='false' v-model='includeCity' />
							<label> No</label>
						</div>
					</div>
				</div>
			</div>

			<!-- critical asset type selections -->
			<div class='selection-container' id='crit-asset-type'>
				<span>
				<h5 class='title' style="color:red;" v-show="hasValidationError"> {{ textContent['VulnerabilityReport']['crit-asset-types-slide-options']['title'] }}</h5>
				<h5 class='title'> {{ textContent['VulnerabilityReport']['crit-asset-types-slide']['title'] }} </h5>
				<p> {{ textContent['VulnerabilityReport']['crit-asset-types-slide']['text'] }} </p>
				</span>
				<div id='crit-asset-type-selection' class='selection-content' > 
					<p> {{ textContent['VulnerabilityReport']['crit-asset-types-slide-options']['text'].split('|')[0]}}</p>
					<div 
						class='option' 
						:class="{'selected': option.value}"
						v-for='option in critAssetTypes' 
						@click='option.value = !option.value'
					>
						<div class='label'>
							<label>{{ option.label }}</label>
							<input type="radio" :id="option.label" :value="option.label" :checked="option.value" v-model="option.value" />	
						</div>
					</div>
				</div>
				<div id='crit-asset-geom-type-selection' class='selection-content' > 
					<p> {{ textContent['VulnerabilityReport']['crit-asset-types-slide-options']['text'].split('|')[1]}}</p>
					<div 
						class='option' 
						:class="{'selected': option.value}"
						v-for='option in critAssetGeomTypes' 
						@click='option.value = !option.value'
					>
						<div class='label'>
							<label>{{ option.label }}</label>
							<input type="radio" :id="option.label" :value="option.label" :checked="option.value" v-model="option.value" />	
						</div>
					</div>
				</div>
			</div>

			<!-- year and scenario selections -->
			<div class='selection-container' id='year-scenario'>
				<span>
				<h5 class='title' style="color:red;" v-show="hasValidationError"> {{ textContent['VulnerabilityReport']['year-scenario-slide-options']['title'] }}</h5>
				<h5 class='title'> {{ textContent['VulnerabilityReport']['year-scenario-slide']['title'] }} </h5>
				<p> {{ textContent['VulnerabilityReport']['year-scenario-slide']['text'] }} </p>
				</span>
				<div id='scenario-selection' class='selection-content' > 
					<p> {{ textContent['VulnerabilityReport']['year-scenario-slide-options']['text'].split('|')[0]}}</p>
					<div style="display: flex; flex-direction: row; flex-wrap: wrap">
						<div 
							class='option' 
							:class="{'selected': option.value}"
							v-for='option in yearOptions' 
							@click='option.value = !option.value'
						>
							<div class='label'>
								<label>{{ option.label }}</label>
								<input type="radio" :id="option.label" :value="option.label" :checked="option.value" v-model="option.value" />	
							</div>
						</div>
					</div>
				</div>
				<div id='year-selection' class='selection-content' > 
					<p> {{ textContent['VulnerabilityReport']['year-scenario-slide-options']['text'].split('|')[1]}}</p>
					<div 
						class='option' 
						:class="{'selected': option.value}"
						v-for='option in scenarioOptions' 
						@click='option.value = !option.value'
					>
						<div class='label'>
							<label>{{ option.label }}</label>
							<input type="radio" :id="option.label" :value="option.label" :checked="option.value" v-model="option.value" />	
						</div>
					</div>
				</div>
			</div>

			<!-- report sections section -->
			<div class='selection-container' id='report-sections'>
				<span>
				<h5 class='title' style="color:red;" v-show="hasValidationError"> {{ textContent['VulnerabilityReport']['report-section-slide-options']['title'] }}</h5>
				<h5 class='title'> {{ textContent['VulnerabilityReport']['report-section-slide']['title'] }} </h5>
				<p> {{ textContent['VulnerabilityReport']['report-section-slide']['text'] }} </p>
				</span>
				<div id='section-selection' class='selection-content' > 
					<p> {{ textContent['VulnerabilityReport']['report-section-slide-options']['text'].split('|')[0]}}</p>
					<div style="display: flex; flex-direction: row; flex-wrap: wrap">
						<div 
							class='option' 
							:class="{'selected': option.value}"
							v-for='option in reportSections' 
							@click='option.value = !option.value'
						>
							<div class='label'>
								<label>{{ option.label }}</label>
								<input type="radio" :id="option.label" :value="option.label" :checked="option.value" v-model="option.value" />	
							</div>
						</div>
					</div>
				</div>
			</div>

			<div 
				v-for="option in reportSectionsOn"
				class='selection-container' 
				:id='option.ref'>
				<span>
				<h5 class='title'> {{ option.label }} Section </h5>
				<p> {{ this.textContent['VulnerabilityReport'][option.ref]['title'] }} </p>
				</span>
				<o-field>
					<o-input class='input' v-model="option.text" type="textarea" style="resize: none;"/>
				</o-field>
			</div>

		</carousel>

	</modal>
	<modal v-model="submitModal">
		<div class='criticalAssetPopup'>
			<h5>{{ textContent['VulnerabilityReport']['submit-modal']['title']}}</h5>
			<p>{{ textContent['VulnerabilityReport']['submit-modal']['text']}}</p>
			<div class='criticalAssetButtons'>
				<btn variant="arkly" label="saved" outlined size='medium' v-on:click="submitModal = !submitModal">Great!</btn>
			</div>
		</div>
	</modal>
</div>
</template>

<script>
import { reactive, computed } from 'vue'
import { mapState, mapGetters, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import { useSurfStore } from '@/store/surf'
import { event } from 'vue-gtag'
import axios from 'axios'


import Button from '@/components/Button'
import InlineSvg from 'vue-inline-svg'
import Modal from '@/components/Modal'
import Carousel from '@/components/Carousel'

const controller = new AbortController()

//console.log(process.env, process.env.VUE_APP_API_URL)

export default {
	name: 'VulnerabilityReport',
	components: {
		'inline-svg': InlineSvg,
		'btn': Button,
		'modal': Modal,
		'carousel': Carousel,
	},
	props: {
		modelValue: {
			type: Boolean,
		}
	},
	emits: ['update:modelValue'],
	data() {
		return {
			submitModal: false,
			countyOrCityName: undefined,
			isCity: undefined,
			includeCity: true,
			critAssetTypes: [
				{
					label: 'NCH',
					value: true,
				},
				{
					label: 'CCF',
					value: true,
				},
				{
					label: 'Transport',
					value: true,
				},
				{
					label: 'Utility',
					value: true,
				},
			],
			critAssetGeomTypes: [
				{
					label: 'Points',
					value: true,
				},
				{
					label: 'Lines',
					value: true,
				},
				{
					label: 'Polygons',
					value: false,
				},
			],
			scenarioOptions: undefined,
			yearOptions: undefined,
			reportSections: undefined,
			reportSectionsOn: undefined,
			state: 'info',
			Economic_Analysis: '',
			Tax_Analysis: '',
			Socioeconomic_Analysis: '',
			Insurance_Analysis: '',
			Mitigation_Analysis: '',
			Critical_Assets_Exposure: '',

			infoSlideNumber: 0,
			currentPage: 2,
			restart: false,
			carousel: undefined,
			advanceQuoteSlide: false,
			hasValidationError: false,
		}
	},
	mounted() {
		var yearOptions = []
		this.settings.years.forEach(currYear => {
			if ([2020,2040,2070,2100].includes(currYear)) {
				var currValue = true
			} else {
				var currValue = false
			}
			yearOptions.push({'label':currYear, 'value':currValue})
		})
		this.yearOptions = yearOptions
		var scenarioOptions = []
		this.settings.storm.forEach(currStorm => {
			if (['10 year','50 year','100 year'].includes(currStorm)) {
				var currValue = true
			} else {
				var currValue = false
			}
			scenarioOptions.push({'label':currStorm, 'value':currValue})
		})
		this.scenarioOptions = scenarioOptions
		
	},
	methods: {
		submitReport() {
			console.log(this.reportSectionsOn)
			this.restart = true
			this.submitModal = true
			setTimeout(()=> {
				this.isReportVisible = false
			},1000)
			if (1 ==2) { //!this.hasValidationError){
				// If testing is on or we used the secret hightide testing phone and email, then skip phone verification

				const instance = axios.create()

				let url = `${this.url}/auth/send/phone-number`

				var data = new FormData()
				data.append('phone_number', this.user.phone)

				return	instance.post(
					url, data, {
						headers: {
							"Content-Type": "multipart/form-data",
						},	
					},
				)
					.then(response => {
						event('rocketflood_verify_sent', {'event_category': 'rocketflood', 'send_to': this.gtagID, 'location_ID' : window.location.href.split('/').slice(-1)})
						confection.submitEvent('rocketflood_verify','location ID: ' + window.location.href.split('/').slice(-1) + ', verify code sent to: ' + this.user.phone + ',' + this.user.first_name + ',' + this.user.last_name + ',' + this.user.email)
						//console.log('rocketflood_verify','location ID: ' + window.location.href.split('/').slice(-1) + ', verify code sent to: ' + this.user.phone + ',' + this.user.first_name + ',' + this.user.last_name + ',' + this.user.email)
										
					}).catch(error => {
						console.log(error)
						event('rocketflood_verify_error', {'event_category': 'rocketflood', 'send_to': this.gtagID, 'errorMsg' : error.response.data.detail.message, 'location_ID' : window.location.href.split('/').slice(-1)})
						confection.submitEvent('rocketflood_verify','location ID: ' + window.location.href.split('/').slice(-1) + ', verify error: ' + error)

					})
			}
		},
		resetCarousel(){
			this.countyOrCityName = undefined
			this.isCity = undefined
		},
		closeCarousel(value) {
			if(value) {
				this.isReportVisible = false
			}

		},
		slideChanged(newSlide) {
			
			if (newSlide.id == 'foundation-type'){
				//console.log('rocketflood_funnel','location ID: ' + window.location.href.split('/').slice(-1) + ', current slide id: ' + newSlide.id + ', occupancyType: ' + this.selected.occupancyType)
				confection.submitEvent('rocketflood_funnel','location ID: ' + window.location.href.split('/').slice(-1) + ', occupancyType: ' + this.selected.occupancyType)
				event('rocketflood_occupancy_type', {'event_category': 'rocketflood', 'send_to': this.gtagID, 'occupancy_type' : this.selected.occupancyType, 'location_ID' : window.location.href.split('/').slice(-1)})

			} else if (newSlide.id == 'house-details'){
				//console.log('rocketflood_funnel','location ID: ' + window.location.href.split('/').slice(-1) + ', current slide id: ' + newSlide.id + ', foundationType: ' + this.selected.foundationType)
				confection.submitEvent('rocketflood_funnel','location ID: ' + window.location.href.split('/').slice(-1) + ', foundationType: ' + this.selected.foundationType + ', steps: ' + this.selected.frontDoorType + ', raisedHouseType: ' + this.selected.raisedHouseType + ', garageType ' +  this.selected.garageType + ', basementType' + this.selected.basementType)
				event('rocketflood_foundation_type', {'event_category': 'rocketflood', 'send_to': this.gtagID, 'foundationType' : this.selected.foundationType, 'numSteps' : this.selected.frontDoorType, 'raisedHouseType' : this.selected.raisedHouseType, 'garageType' : this.selected.garageType, 'basementType' : this.selected.basementType, 'location_ID' : window.location.href.split('/').slice(-1)})

			} else if (newSlide.id == 'personal-info'){
				//console.log('rocketflood_funnel','location ID: ' + window.location.href.split('/').slice(-1) + ', current slide id: ' + newSlide.id + ', yearBuilt: ' + parseInt(this.yearBuilt) + ', area: ' + parseInt(this.area) + ', stories: ' + parseInt(this.numberOfStories) + ', constructionType: ' + this.constructionType)
				confection.submitEvent('rocketflood_funnel','location ID: ' + window.location.href.split('/').slice(-1) + ', yearBuilt: ' +  + ', area: ' + parseInt(this.area) + ', stories: ' + parseInt(this.numberOfStories) + ', constructionType: ' + this.constructionType)
				event('rocketflood_house_details', {'event_category': 'rocketflood', 'send_to': this.gtagID, 'yearBuilt' : parseInt(this.yearBuilt), 'area' : parseInt(this.area), 'stories' : parseInt(this.numberOfStories), 'constructionType' : this.constructionType, 'location_ID' : window.location.href.split('/').slice(-1)})

			} else if (newSlide.id == 'quotes'){
				//console.log('rocketflood_funnel','location ID: ' + window.location.href.split('/').slice(-1) + ', current slide id: ' + newSlide.id + ', userInfo: ' + this.user.phone + ',' + this.user.first_name + ',' + this.user.last_name + ',' + this.user.email)
				confection.submitEvent('rocketflood_funnel','location ID: ' + window.location.href.split('/').slice(-1) + ', userInfo: ' + this.user.phone + ',' + this.user.first_name + ',' + this.user.last_name + ',' + this.user.email)
				event('rocketflood_personal_info', {'event_category': 'rocketflood', 'send_to': this.gtagID, 'location_ID' : window.location.href.split('/').slice(-1)})
			}
			//console.log(newSlide)
		},
		isValid(variable) {
			return Boolean(variable) && !this.hasValidationError
		},
		validationMessage(variable, message) {
			if ((!Boolean(variable) && this.hasValidationError)) {
				return message
			} else {
				return ''
			}
		},
		iconSRC(icon) {
			return require(`@/assets/icons/${icon}.svg`)
		},
		validateGettingStarted(){
			if ((this.isCity == true || this.isCity == false) && this.countyOrCityName) {
				return true
			} else {
				return false
			}
		},
		isReportSectionsValid(){
			var isValid = false
			var tempList = []
			this.reportSections.forEach(currSection => {
				if (currSection.value) {
					isValid = true
					tempList.push(currSection)
				}
			})
			this.reportSectionsOn = tempList
			return isValid
		},
		isYearAndScenarioValid(){
			var isValidYear = false
			var isValidScenario = false
			var isValid = false
			this.yearOptions.forEach(currYear => {
				if (currYear.value) {
					isValidYear = true
				}
			})
			this.scenarioOptions.forEach(currStorm => {
				if (currStorm.value) {
					isValidScenario = true
				}
			})
			if (isValidYear && isValidScenario) {
				isValid = true
			}
			return isValid
		},
		...mapActions(useSurfStore, []),
	},
	computed: {
		isReportVisible: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
		currentSlide: {
			get() {
				return this.currentPage
			},
			set(value, oldValue) {
				//const oldValue = this.currentPage
				this.currentPage = value
			}
		},
		...mapState(useConfigStore, ['userLanguage','mapConfig','settings','userIP','colors', 'componentRefs', 'url', 'gtagID','textContent']),
		...mapState(useSurfStore, ['attributes', 'fullAddress', 'location', 'rocketflood', 'buildingArea','floodZone']),
	},
	watch: {
		state(newState) {
			if (newState != 'info') {
				this.slideChanged({'current': 1, 'numberOfSlides':1, 'id': newState})
			} 
		},
		isReportVisible(newState) {
			if (!newState) {
				this.resetCarousel()
			} else {
				var reportSections = []
				var tempList = this.textContent['VulnerabilityReport']['report-section-slide-options']['text'].split('|')
				tempList.slice(1,tempList.length).forEach(item => {
					reportSections.push({'label':item, 'value': true, 'ref': item.replace(' ','_').replace(' ','_'), 'text': this.textContent['VulnerabilityReport'][item.replace(' ','_').replace(' ','_')]['text']})
				})
				this.reportSections = reportSections
				this.reportSectionsOn = reportSections
			}
		},
		currentPage: {
			handler: function(newPage, oldPage) {
				if (newPage == 3 && oldPage == 2) {
					this.currentPage = 2
					//console.log(this.currentPage)
				}
			},
		},
	}
}
</script>

<style lang="scss" scoped>
@import "../../style/oruga.scss";
@import "../../style/style.scss";
@import "../../style/arkly.scss";

.error-msg {
	color: red;
	text-align: center;
	font-weight: bolder;
	font-size: larger;
}

.p .o-field__message {
	border: 7px solid red !important;
	position: relative !important;
	top: -7px !important;
}

#controls {
	background-color: $sand;
}

.title {
	flex-direction: column;
	grid-column: 1/-1;
}

.selection-content {
	/*
	display: flex;
	flex-direction: row;
	grid-auto-flow: column;
	justify-content: center;
	grid-template-rows: 1fr;
	grid-gap: 10px;
	*/

	display: flex;
	//flex-direction: column;
	max-height: 100%;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 5px;
	margin-top: 5px;
	gap: 10px;
	overflow: none;
	overflow-y: scroll;
}

.switch {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	padding: 20px;
	background: #FFFFFF;

	/* Base/Overlay 60 */
	border: 2px solid rgba(131, 126, 118, 0.79);
	border-radius: 4px;
}

.quote-error {
	display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    align-content: flex-end;
}

.switch > .option:hover {
	color: $blue;
	border: 2px solid $blue;
	background-color: #{$blue}10;		
}


.option {
	/* Auto layout */
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	padding: 20px;
	gap: 21px;

	//width: 330px;
	height: auto;
	min-height: 147.53px;
	max-width: 200px;

	background: #FFFFFF;

	/* Base/Overlay 60 */
	border: 2px solid rgba(131, 126, 118, 0.79);
	border-radius: 4px;

	/* Inside auto layout */
	//flex: none;
	//order: 0;
	//align-self: stretch;
	//flex-grow: 0;

	flex: 1 1 0px;
}

.option > .label {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.selection-icon {
	width: 100%;
	max-width: 100%;
	height: auto;
}

.selection-content .option {
	flex: 1 1 0px;
	flex-direction: row;
	min-height: auto;
	padding: 10px;
	justify-content: space-between;
}

.selection-content .option:hover {
	color: $blue;
	border: 2px solid $blue;
	background-color: #{$blue}10;	
}

.selection-content .selected {
	color: $blue;
	border: 2px solid $blue;
	background-color: #{$blue}10;
}

.selection-content .option > .label {
	color: inherit;
	flex-direction: row;
	gap: 8px;
}

.selection-content .option > .label > input[type="radio" i] {
	min-width: 25px;
}

.selection-content .option:hover > .label > input[type="radio" i] {
	outline: 2px solid $blue;
}

.selection-content .option .selection-icon {
	width: auto;
	height: 100%;
	max-width: 40%;
	fill: black;
}

.selection-content .option:hover > .selection-icon {
	fill: $blue;
	color: $blue;
}

.info-container {
	display: flex;
	flex-direction: column;
	gap: 30px;
}

.info-content {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;
	grid-gap: 30px;
	height: min-content;
}

.info-container .tile {

}

#getting-started .info-content .select {
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 1fr;	
}

.info-container .info {
	display: flex;
	flex-direction: column;
	gap: 20px;
	max-width: 400px;
	height: auto;
}

.info-container .info > * {
	margin: 0;
}

.info-container .info > input, .info-container .info .arkly-input {
	padding-top: 18px;
	padding-bottom: 14px;
	border: none;
	border-bottom: 2px solid black;
	font-family: $header-family;
	font-weight: normal; 
	color: black;
	margin: 0;
	text-shadow: 0 0.5px 0 #585858;
	font-size: 1rem;
}

.info-container .info > input::placeholder, .info-container .info .arkly-input::placeholder {
	font-family: $header-family;
	font-weight: normal; 
	color: black;
	margin: 0;
	text-shadow: 0 0.5px 0 #585858;
	font-size: 1rem; 
}

.info-container .select {
	display: grid;
	grid-template-columns: 1fr 1fr;
	background-color: $oat;
	padding: 8px;
	border-radius: 5px;
	grid-gap: 5px;
}

.info-container .select > div {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	gap: 4px;
}

.info-container .select label {
	font-size: 14px;
	font-family: $header-family;
	font-weight: normal; 
	color: black;
	margin: 0;
	text-shadow: 0 0.5px 0 #585858;
	font-size: 1rem;
	height: 10px;
	white-space: nowrap;
}

.taking-too-long {
	height: 75px !important;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 10px;
}

.quote-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	height: 100%;
	width: 100%;
}

#footer  {
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: flex-end;
	padding: 0 20px 5px;
}

.quotes {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	grid-gap: 10px;
	width: 100%;
	height: auto;
}

.quotes::last-child {
	top: 80px;
}

.quotes .quote {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	padding-bottom: 8px;
	border: none;
	border-bottom: 2px solid black;
	font-family: $header-family;
	font-weight: normal; 
	color: black;
	margin: 0;
	text-shadow: 0 0.5px 0 #585858;
	font-size: 0.8rem;
}

.quotes .quote:first-of-type {
	background-color: rgba(197, 153, 1, 0.4);
}

.quotes .quote strong {
	font-family: $header-family;
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 160%;

	/* or 38px */
	letter-spacing: 1px;
	font-feature-settings: 'pnum' on, 'lnum' on;
}

.result-modal {
	padding: 0 5px;
}

@media screen and (max-width: $mobile-breakpoint) {

	.quotes {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 10px;
		width: 100%;
		height: auto;

	}

	.selection-container {
		overflow: clip;
	}

	.selection-content {
		display: flex;
		flex-direction: column;
		max-height: calc(100% - 100px);
		height: 100%;
		overflow: none;
		overflow-y: scroll;
	}

	.selection-content .option {
		flex: 1 1 0px;
		min-height: auto;
		padding: 10px;
		justify-content: space-between;
	}

	.selection-content .option > .label {
		flex-direction: row;
		gap: 8px;
	}

	.selection-content .option .selection-icon {
		width: auto;
		height: 100%;
	}

	.info-container {
		overflow-y: scroll;
		padding-top: 0;
		padding-bottom: 0;
	}

	.selection-container {
		overflow-y: scroll;
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}

	.carousel > * {
		overflow-y: scroll;
		padding-top: 0;
		padding-bottom: 0;
	}

	.option {
		flex-direction: row;
		max-width: 100%;
	}

	.info-content {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr;
		grid-gap: 30px;
		height: min-content;
	}
}

/*
.hightide-button {
	background-color: $dark-sand !important;
}
*/
</style>
