<!-- HTML -->
<template> 
	<div class='grid'>	
		<base-map v-if='coordinates' id='map' />

		<not-found class='not-found-modal' />
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import { useSurfStore } from '@/store/surf'
import NotFound from '@/components/NotFound'
import Map from '@/components/map/Map'

export default {
	name: 'NotFoundView',
	components: {
		'not-found': NotFound,
		'base-map': Map,
	},
	created() {
		console.log('query: ', this.$route.query)
		this.coordinates.lat = this.$route.query.lat
		this.coordinates.lng = this.$route.query.lng
		this.zoom = this.$route.query.zoom

	},
	mounted() {
		console.log('not found')
	},
	data() {
		return {
			'coordinates': {
				'lat': undefined,
				'lng': undefined,
			},
			zoom: undefined
		}
	},
	computed: {
		...mapState(useConfigStore, ['textContent']),
	},
	methods: {

	}
}


</script>

<!-- CSS -->
<style lang="scss" scoped>
@import "../style/mapbox.scss";
@import "../style/arkly.scss";

#sidepanel { 
	//background-color: transparent;
	grid-area: sidepanel; 
}

#map {
	grid-area: map;
}

#footer { grid-area: footer; }

#timeline {
	height: auto;
	width: 100%;
	grid-area: timeline;
}

.grid {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	grid-template-areas:
		" map";
	height: 100vh;
	width: 100%;
	background-color: rgba(255,255,255,0.1);
}

@media only screen and (max-width: $mobile-breakpoint) {
	.grid {
		position: relative;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 400px auto;
		grid-template-areas:
			" map       "
			" sidepanel ";
		height: 100vh;
		width: 100%;
		background-color: rgba(255,255,255,0.1);
	}

}

</style>

