<!-- HTML -->
<template> 
	<loading-screen :isLoading="isLoading" variant="arkly" :slowInternet="slowInternet"/>
	<div class='grid'>	
		<arkly-map v-if='coordinates && textContent' id='map' :style='mapStyle'/> <!--:class="{'missing-property': isRouteLocation}"-->

		<sidepanel v-if='isMobile && textContent' v-show='coordinates && !isRouteLocation' id='sidepanel'>	
			<arkly-content :isPuertoRico='isPuertoRico' />
		</sidepanel>

		<not-found class='not-found-modal' />
	</div>
</template>

<!-- JavaScript -->
<script>
import { mapState, mapGetters, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import { useSurfStore } from '@/store/surf'
import SidePanel from '@/components/sidepanel'
import ArklyPanelContent from '@/components/sidepanel/ArklyContent'
import LoadingScreen from '@/components/LoadingScreen'
import Map from '@/components/map/Map'
import NotFound from '@/components/NotFound'

export default {
	name: 'arkly',
	props: ['isPropertyMissing'],
	components: {
		'loading-screen' : LoadingScreen,
		'sidepanel': SidePanel,
		'arkly-content': ArklyPanelContent,
		'arkly-map': Map,
		'not-found': NotFound,
	},
	data() {
		return{
			'isPuertoRico': true,
			'useSurfStore': useSurfStore,
			runningFunction: undefined,
			isLoading: true,
			currentLayer: false,
			timesCalled: 0,
			slowInternet: false,
		}
	},
	computed: {
		mapStyle() {
			if (this.isRouteLocation) {
				if (window.location.href.includes('offset')) {
					var offset = parseInt(window.location.href.split('offset=')[1])
				} else {
					var offset = 0
				}
				//console.log(window.innerHeight,window.outerHeight)
				//console.log(`height: ${window.innerHeight - offset}px;`)
				return  `height: ${window.innerHeight - offset}px;` /*${window.innerHeight};``height: 100vh;`*/
			} 
		},
		...mapState(useConfigStore, ['isRouteLocation','componentRefs','isSidePanelVisible','mapConfig','userLanguage', 'textContent', 'url', 'controls', 'mapConfig', 'tutorial', 'settings', 'isMobile']),
		...mapState(useSurfStore, ['homeInsInfo','coordinates', 'AAL', 'surfData', 'propertyNotFound']),
	}, 
	created() {
		if (this.$route.params.id == 'Location') {
			const lat = this.$route.query.lat
			const lng = this.$route.query.lng
			var zoom = this.mapConfig.layerSettings.property.startingZoom
			if (this.$route.query.zoom){
				zoom = this.$route.query.zoom
			}
			//console.log(`lng: ${lng}, lat: ${lat}, zoom: ${zoom}`)
			if (!this.propertyNotFound.value) {
				this.setPropertyNotFound(true, `lng: ${lng}, lat: ${lat}, zoom: ${zoom}`)
			}

			this.setLngLat(lng, lat)
		} else {
			this.setStorm('100 year')
			this.setFloodZoneVisibility(true)
			this.updateSurf().then((surfData) => {
				this.updateNFIP()
				this.updateHouseLiftingLoan()	
				this.getHomeInsRates(this.homeInsInfo.type)
			})
		}
	},
	mounted() {
		document.title = 'Arkly'

		if (this.$route.params.id == 'Location') {
			this.setRouteIsLocation(true)
		} else {
			this.setRouteIsLocation(false)

		}
		this.currentLayer = this.mapConfig.propertySources[this.$route.meta.region]
		this.setYearOptions(this.mapConfig.yearOptions[this.$route.meta.region])
		
		var t = setInterval(this.sourceCallback,100);
		this.runningFunction = t
		this.setFavicon()
		this.setTitle()

	},
	watch: {
		'$route': {
			handler: function(newRoute) {
				
				if (newRoute.params.id == 'Location') {
					this.setRouteIsLocation(true)
				} else {
					this.setRouteIsLocation(false)
				}

				if (newRoute.meta.platform == 'arkly') {
					if (this.$route.params.id != 'Location') {
						//console.log('Arkly not found setting')
						this.setPropertyNotFound(false, undefined)
						//console.log('switch locations: ', newRoute.params.id)
						this.updateSurf().then((surfData) => {
							if (this.mapConfig.routesWithInsurance.includes(this.$route.name)) {
								this.updateNFIP()
								this.getHomeInsRates(this.homeInsInfo.type)
							}
							if (this.mapConfig.routesWithMitigation.includes(this.$route.name)) {
								this.updateHouseLiftingLoan()
							}
						})
					} else {
						const lat = this.$route.query.lat
						const lng = this.$route.query.lng
						this.setLngLat(lng, lat)
					}
				}
				
				if (newRoute.name == 'puertoRico') {
					this.setYearOptions([2020, 2030, 2040, 2050, 2060, 2070, 2080, 2090, 2100, 2120])
				} else {
					this.setYearOptions([2020, 2030, 2040, 2050, 2060, 2070, 2080, 2090, 2100])
				}


			},
			deep: true,
		},
		propertyNotFound: {
			handler: function(newState) {

			},
			deep: true,
		},
		coordinates(newCoordinate) {

		},
	},
	methods: {
		sourceCallback() {
			// not doing this as sometimes it takes awhile to load on slow internet, adding a msg to the 
			// loading screen instead
			this.timesCalled = this.timesCalled + 1
			if (this.timesCalled > 100) {
				//clearInterval(this.runningFunction)
				//this.isLoading = false
				this.slowInternet = true
			}
			// assuming 'map' is defined globally, or you can use 'this'
			if (this.mapConfig.map){
				if (this.mapConfig.map.getSource(this.currentLayer) && this.mapConfig.map.isSourceLoaded(this.currentLayer)) {
					clearInterval(this.runningFunction)
					this.isLoading = false
				}
			}
		},
		...mapActions(useSurfStore, ['getHomeInsRates','updateSurf', 'updateAAL', 'updateEL', 'updateHouseLiftingLoan', 'updateNFIP', 'setPropertyNotFound', 'setLngLat']),
		...mapActions(useConfigStore, ['setDataSource','setTitle','setFavicon','setRouteIsLocation','setSidePanelVisibility','setYearOptions', 'checkout', 'setYear', 'setZoom', 'setElevation', 'setFloodZoneVisibility', 'setStorm', 'addComponentRef', 'setTutorialVisibility']),	
	},
}
</script>

<!-- CSS -->
<style lang="scss" scoped>
@import "../style/mapbox.scss";
@import "../style/arkly.scss";

#sidepanel { 
	//background-color: transparent;
	grid-area: sidepanel; 
}

#map {
	grid-area: map;
	min-height: 370px;
}

.missing-property {
	height: 100vh;
}

#footer { grid-area: footer; }

#timeline {
	height: auto;
	width: 100%;
	grid-area: timeline;
}

.grid {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	grid-template-areas:
		" map";
	height: 100vh;
	width: 100%;
	background-color: rgba(255,255,255,0.1);
}

@media only screen and (max-width: $mobile-breakpoint) {
	.grid {
		position: relative;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 445px auto;
		grid-template-areas:
			" map       "
			" sidepanel ";
		height: 100vh;
		width: 100%;
		background-color: rgba(255,255,255,0.1);
	}
	.mapboxgl-ctrl-logo {
		bottom: 40px !important;
	}

}

</style>
