<template>
<div class='share-container' >
	<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css" integrity="sha256-h20CPZ0QyXlBuAw7A+KluUYx/3pK+c7lYEpqLTlxjYQ=" crossorigin="anonymous" />

	<modal v-model='isShareVisible'>
		<div class='share-content'>
			<div class='title'> <h6> {{getPropertyAddress}} </h6> </div> 	
			

			<div class="share-network-list">
				<div id="copy-link-box" @click='copyToClipboard'>
					<i 
						id='copy-url' 
						class="fas fah fa-lg fa-copy" 
					/>
					<p>{{ textContent['buttons']['share-button-copy-link']['label'] }}</p>
				</div>	
				<ShareNetwork
					v-for="network in networks"
					:network="network.network"
					:key="network.network"
					:style="{backgroundColor: network.color}"
					:url="sharing.url"
					:title="sharing.title"
					:description="sharing.description"
					:quote="sharing.quote"
					:hashtags="sharing.hashtags"
					:twitterUser="sharing.twitterUser"
					:id="network.network"
					v-on:click="recordShareClick($event)"
				>
					<i :class="network.icon"></i>
					<span>{{ network.name }}</span>
				</ShareNetwork>
				

			</div>
		</div>
	</modal>
</div>
</template>

<script>
import { reactive, computed } from 'vue';
import { mapState, mapGetters, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'

import Button from '@/components/Button'
import InlineSvg from 'vue-inline-svg'
import Modal from '@/components/Modal'
import { event } from 'vue-gtag'


export default {
	name: 'Share',
	components: {
		'inline-svg': InlineSvg,
		'btn': Button,
		'modal': Modal,
	},
	props: {
		modelValue: {
			type: Boolean,
		}
	},
	emits: ['update:modelValue'],
	data() {
		return {
			panelDimensions: undefined,
			sharing: {
				url: window.location.href,
				title: undefined,
				description: undefined,
				quote: undefined,
				hashtags: 'floodrisk,floodinsurance,floodmitigation',
			},
			networks: [
				{ network: 'email', name: 'Email', icon: 'far fah fa-lg fa-envelope', color: '#333333' },
				{ network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f', color: '#1877f2' },	
				{ network: 'reddit', name: 'Reddit', icon: 'fab fah fa-lg fa-reddit-alien', color: '#ff4500' },
			]
		}
	},
	methods: {
		recordShareClick(clickEvent) {
			event('share_button_network_click', {'event_category': 'share', 'location_ID' : window.location.href.split('/').slice(-1), 'network': clickEvent.currentTarget.id})
			confection.submitEvent('share_button_network_click','location ID: ' + window.location.href.split('/').slice(-1) + ', network: ' + clickEvent.currentTarget.id)
		},
		makeShareVisible() {		
			this.isShareVisible = !this.isShareVisible
		},
		async copyToClipboard() {
			// Noah this would be good for this to report what network or what they clicked on
			event('share_button_copied', {'event_category': 'share', 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent('share_button_copied','location ID: ' + window.location.href.split('/').slice(-1))
			try {
				await navigator.clipboard.writeText(window.location.href)
				console.log('Copied')
			} catch(e) {
				console.log('Cannot copy', e)
			}
		},
		iconSRC(icon) {
			return require(`../../assets/icons/${icon}.svg`)
		},
		...mapGetters(useConfigStore, ['getComponentRef']),
	},
	computed: {	
		isShareVisible: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
		getPropertyAddress() {
			var address = window.location.href.split('/').slice(-1)[0]
			var street = address.split('_')[0].replace('-',' ')
			var city = address.split('_')[1].replace('-',' ')
			var state = address.split('_')[2]
			this.sharing.description = this.textContent['buttons']['share-btn-desc-pt1']['label'] + street + ', ' + city + ', ' + state + this.textContent['buttons']['share-button-desc-pt2']['label']
			this.sharing.quote = this.textContent['buttons']['share-btn-quote']['label']
			this.sharing.title = this.textContent['buttons']['share-btn-email-subject']['label']
			this.sharing.hashtags = this.textContent['buttons']['share-btn-hashtags']['label']
			return this.textContent['buttons']['share-btn']['label']
		},
		...mapState(useConfigStore, ['textContent','colors', 'componentRefs', 'screenSize']),
	},
	watch: {
		'componentRefs': {
			handler: function(newRef) {

			},
			deep: true,
		},
		isShareVisible(newState) {
			if (newState == true) {
				event('share_button_visible', {'event_category': 'share', 'location_ID' : window.location.href.split('/').slice(-1)})
				confection.submitEvent('share_button','location ID: ' + window.location.href.split('/').slice(-1) + ', share visible')
			} else {
				event('share_button_closed', {'event_category': 'share', 'location_ID' : window.location.href.split('/').slice(-1)})
				confection.submitEvent('share_button','location ID: ' + window.location.href.split('/').slice(-1) + ', share not visible')
			}
		},
	}
}
</script>

<style lang="scss" scoped>
@import "../../style/oruga.scss";
@import "../../style/style.scss";
@import "../../style/arkly.scss";

#copy-link-box {
	height: 35.99px;
	width: 155px;
	background-color: #6177ED;
	border-radius: 3px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

#copy-link-box:hover{
	cursor: pointer;
	filter: brightness(90%);
}

#copy-link-box p {
	color: white;
	padding-left: 10px;
}

#copy-url {
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #6177ED;
	background-color: rgba(0, 0, 0, 0.2);
	position: relative;
	border-radius: 3px 0 0 3px;
	width: 41.34px;
	height: 100%;
}

.share-container {
	display: flex;
	align-items: flex-start;
	justify-content: center;
}

.share-content {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 10px;
	width: 350px;
	height: auto;
}

.title {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 7px;
	font-size: 25px;
	color: black;
	line-height: 1;
	width: 100%;
}

.close-panel {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
}

.close-panel:hover, .fa-copy:hover {
	filter: brightness(90%);
	fill: grey;
	color: grey;
}

h1 {
	text-align: center;
	margin: 50px 0 80px;
}

.share-network-list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	gap: 10px;
	justify-content: space-between;
	max-width: 320px;
	height: auto;
	margin: auto;
	text-decoration: none;
	margin: 0 0 10px 15px;
}

.share-network-list * {	
	text-decoration: none;
}

a[class^="share-network-"] {
	flex: none;
	color: #FFFFFF;
	background-color: #333;
	border-radius: 3px;
	overflow: hidden;
	//width: auto;
	width: 155px;
	display: flex;
	flex-direction: row;
	align-content: center;
	align-items: center;
	cursor: pointer;
}

a[class^="share-network-"]:hover {
	filter: brightness(90%);
}

a[class^="share-network-"] .fah {
	background-color: rgba(0, 0, 0, 0.2);
	padding: 10px;
	flex: 0 1 auto;
}

a[class^="share-network-"] span {
	padding: 0 10px;
	flex: 1 1 0%;
	font-weight: 500;
}

@media only screen and (max-width: $mobile-breakpoint) {
	.share-panel {
		position: relative;
		//max-width: calc(100vw - 10px) !important;
		//top: 0px;
		left: calc((100vw - 250px) /2) !important;
		right: calc((100vw - 250px) /2) !important;
	}

}

</style>
