<template>
	<div class='container' v-if='$route.params.id != "Location"'>
		<div id='mask' class='tutorial-mask' :style='getMaskStyle(steps[currentStep])'/>
		
		<div id='message' :class="{'mobile-message': isMobile}">

			<div id='close-tutorial-container'>
				<p id='progress-number'> {{ ( currentStep +1)}}/{{this.steps.length}} </p>

				<btn 
					label="close-tutorial"
					backgroundColor='transparent'
					size='small' 
					color='black' 
					icon='close'
					noPadding
					@click='closeTutorial'	
				/>
			</div>

			<h6 style='overflow-y: scroll;' v-html=tutorialMessage> </h6>
			<div id='future-visibility' v-if="textContent['buttons']['tutorial-dont-show-again']">
				<p> {{ textContent['buttons']['tutorial-dont-show-again']['label']}} </p>
				<o-checkbox id="dontShowCheckBox" v-model='isTutorialNotShownAgain' style='margin-right: 5px;'/> 
			</div>
			<div id='progress-container' v-if="textContent['buttons']['tutorial-next']">
			<div id='next-step'>
					<btn 
						label="advance-tutorial"
						variant='arkly' 
						outlined 
						width='auto'
						@click='advanceStep'
					> 
					{{ ( (currentStep != steps.length - 1) ? textContent['buttons']['tutorial-next']['label'] : textContent['buttons']['tutorial-done']['label'])  }} 

					</btn>	
				</div>
				<div id='previous-step'>
					<btn 
						label="retreat-tutorial"
						v-show='currentStep != 0'
						variant='arkly' 
						outlined 
						width='auto'
						@click='retreatStep'
					> 
						{{ textContent['buttons']['tutorial-back']['label'] }}

					</btn>

				</div>
				
				
			</div>
		</div>

	</div>
</template>

<script>
import debounce from 'lodash/debounce'
import { reactive, computed, nextTick } from 'vue';
import { mapState, mapGetters, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import Button from '@/components/Button'
import InlineSvg from 'vue-inline-svg'
import Modal from '@/components/Modal'
import { event } from 'vue-gtag'
export default {
	name: 'Tutorial',
	components: {
		'inline-svg': InlineSvg,
		'btn': Button,
		'modal': Modal,
	},
	props: {
		steps: {
			type: Array,
		},
	},
	data() {
		return {
			currentLayer: undefined,
			runningFunction: undefined,
			isTutorialNotShownAgain: false,
			isScrollingAllowed: true,
			originalMaskHeight: undefined,
			originalMaskTop: undefined,
			components: this.componentRefs,
			currentStep: 0,
			numberOfSteps: undefined,
			currentStepName: undefined,
			originalDivTops: {},
		}
	},
	created() {	
		document.addEventListener('keydown', this.handleEscape);	
		window.addEventListener('resize', this.windowResized)
		if (this.sidePanel.currentView != 'arkly-home') {
			this.setTutorialVisibility(false)
		} else {
			this.isTutorialNotShownAgain = this.getCookie
			this.setTutorialVisibility(!this.getCookie)
		}
		//window.addEventListener('scroll', this.handleScroll, false)
		//this.updateTutorialMasks()
	},
	mounted() {	
		// Sidepanel Visibility must be set to true, so that 
		// it is visible, since the tutorial references it
		if (this.$route.params.id!='Location'){
			//console.log('tutorial setting vis')
			this.setSidePanelVisibility(true)
		}
		this.currentLayer = this.mapConfig.propertySources[this.$route.meta.region]
		var t = setInterval(this.sourceCallback,100);
		this.runningFunction = t
		//console.log(this.steps)
		//console.log(this.componentRefs)
		
	},
	unmounted () {	
		//console.log(this.componentRefs)
		if (!(this.isMobile)) {
			if (this.componentRefs['panel-content']) {
				this.componentRefs['panel-content'].parentElement.removeEventListener('scroll', this.handleScroll)
			}
		}
		document.removeEventListener('keydown', this.handleEscape)
		window.removeEventListener('resize', this.windowResized)
	},
	computed: {
		tutorialSteps() {
			return this.textContent['tutorial']
		},
		tutorialMessage() {
			
			if (this.steps[this.currentStep]) {
				//console.log(this.steps[this.currentStep],this.isMobile)
				if ((this.steps[this.currentStep]['mobile_message']) && (this.isMobile == true)){
					return this.steps[this.currentStep]['mobile_message']
				} else {
					return this.steps[this.currentStep].message
				}
			} else {
				return ''
			}
		},
		getCookie() {
			var name = 'isTutorialShownAtStart' + "="
			var arrayOfCookies = decodeURIComponent(document.cookie).split(';')
			for (var i = 0; i < arrayOfCookies.length; i++) {
				var aCookie = arrayOfCookies[i]
				while (aCookie.charAt(0) == ' ') { aCookie = aCookie.substring(1) }
				if (aCookie.indexOf(name) == 0) {
					return String(aCookie.substring(name.length, aCookie.length)).toLowerCase() == 'true'
				}
			}
			return ""
		},
		...mapState(useConfigStore, ['mapConfig','userLanguage','isRouteLocation','componentRefs', 'getComponentRefs','isMobile','tutorial', 'sidePanel','textContent']),
	},
	methods: {
		sourceCallback() {
			// assuming 'map' is defined globally, or you can use 'this'
			if (this.mapConfig.map && this.currentLayer){
				if (this.mapConfig.map.getSource(this.currentLayer) && this.mapConfig.map.isSourceLoaded(this.currentLayer)) {
					clearInterval(this.runningFunction)
					this.updateTutorialMasks()
				}
			}
		},
		updateTutorialMasks(){
			setTimeout(() => {
				// Filter removes the empty string, that exists if there is no componentRef
				// forEach allow us to step through each remaining componentRef
				//console.log(this.componentRefs)
				this.steps.filter(step => step.componentRef != '').forEach(step => {
					if ( this.componentRefs[step.componentRef]) {
						var offsetToUse = 0
						if (!this.isMobile && step.componentRef != 'map' && step.componentRef != 'timeline' && step.componentRef != 'legend') {
							offsetToUse = this.componentRefs['panel-content'].parentElement.scrollTop
						}
						this.originalDivTops[step.componentRef] = this.componentRefs[step.componentRef].getBoundingClientRect().top + offsetToUse
					}
				})

				if (!(this.isMobile)) {
					this.componentRefs['panel-content'].parentElement.addEventListener('scroll', this.handleScroll)
					//this.componentRefs['panel-content'].parentElement.style['smooth-scrolling'] = 'smooth'	
					
				}	
			}, 100)
		},
		preventUserScroll() {
			const preventScroll = (e) =>  {
				if (this.tutorial.isVisible == true) {
					e.preventDefault()
					e.stopPropagation()
					return false
				}
			}
			if ( this.componentRefs['panel-content']) {
				if(this.componentRefs['panel-content'].parentElement) {
					//console.log('prevent user scroll')
					this.componentRefs['panel-content'].parentElement.addEventListener('wheel', preventScroll, {passive: false})
				}
			}
		},
		setCookie(newState) {
			// Set Cookie to Expire in 10 Years (Max Possible)
			const expiration = new Date(new Date().setFullYear(new Date().getFullYear() + 10)).toUTCString()
			document.cookie = 'isTutorialShownAtStart=' + String(newState) + ';expires =' + expiration + ';'
		},
		windowResized(){
			setTimeout(() => {
				this.updateTutorialMasks()
			},1000)
		},
		handleEscape (event) {
	        if (event.key === 'Escape') {
	         //if esc key was not pressed in combination with ctrl or alt or shift
	            const isNotCombinedKey = !(event.ctrlKey || event.altKey || event.shiftKey);
	            if (isNotCombinedKey) {
	                //console.log('Escape key was pressed with out any group keys')
	              	this.setTutorialVisibility(false)
	            }
	        }
		},
	    handleScroll (event) {
			const maskElem = document.getElementById("mask")
			const headerElemDims = document.getElementById("header").getBoundingClientRect()

			// Parent of panel-content is Scrolling, not the panel-content itself
			const topOffset = !this.isMobile ? this.componentRefs['panel-content'].parentElement.scrollTop : this.componentRefs['panel-content'].scrollTop

		    var floatTop = parseFloat(maskElem.style.top.replace('px',''))
		    var floatHeight = parseFloat(maskElem.style.height.replace('px',''))
	        maskElem.style.top = this.originalDivTops[this.currentStepName] - 5 - topOffset + "px"
	        if (this.currentStepName == 'search') {
		        if (floatTop < (headerElemDims.top + headerElemDims.height)) {
		        	maskElem.style.height = this.originalMaskHeight - topOffset + 'px'
		        	maskElem.style.top = headerElemDims.top + headerElemDims.height - 5 + 'px'
		        }
			} 
	    },
		getMaskStyle(step) {
			//console.log(this.steps, step)
			if (this.tutorial.isVisible == true) {
			try {	
				let maskStyle
				//console.log(step.componentRef)
				if (step.componentRef != '') {
					

					this.currentStepName = step.componentRef
					var nameToUse = this.currentStepName
					if (this.isMobile && nameToUse == 'panel-content'){
						nameToUse = 'panel-content-not-tutorial'
					}
					// Scroll Component into view, if it's not in view already
					const ref = this.componentRefs[nameToUse]
					const dimensions = ref.getBoundingClientRect()
					const topOffsetMobile = window.scrollY
					//const topOffset = this.componentRefs['panel-content'].scrollTop
					
					//if (nameToUse == 'panel-content') {
						//console.log(this.componentRefs['panel-content'], this.componentRefs['panel-content'].getBoundingClientRect(), topOffset, topOffsetMobile)
					//}
					// Get the Dimensions of the element that is being
					// described
					const padding = 10
					maskStyle = {
						'top': (dimensions.y - padding/2) + 'px', 
						'left': (dimensions.x - padding/2) + 'px', 
						'height': (dimensions.height + padding) + 'px',
						'width': (dimensions.width + padding) + 'px',
					}

					//console.log(maskStyle)
					this.originalMaskHeight = (dimensions.height + padding)
					this.originalMaskTop = (dimensions.y - padding/2)
					if (this.isMobile) {
						maskStyle.top = (dimensions.y + topOffsetMobile - padding/2) + 'px'
					}
					
					//console.log(maskStyle)
					if (this.currentStepName == 'panel-content' || nameToUse == 'panel-content-not-tutorial') {
						var height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
						if (this.isMobile) {
							maskStyle.height = (dimensions.height + padding) + 'px'
						} else {
							maskStyle.height = (height) + 'px'
						}
						//console.log(maskStyle.height,dimensions.height,padding,height)
					}
					//console.log(maskStyle)
					// If manual dimension are set, they will overwrite
					// the calculated ones
					if (this.isMobile) {
						if (step.dimensionsMobile != undefined) {
							maskStyle = step.dimensionsMobile
						}
						if (this.currentStep == 6) {
							maskStyle.left = (dimensions.x - padding/2) - 15 + 'px'
							maskStyle.width = (dimensions.width + padding) + 30 + 'px'
						} else if (this.currentStep == 8 || this.currentStep == 9 || this.currentStep == 10) {
							maskStyle.left = (dimensions.x - padding/2) - 10 + 'px'
							maskStyle.width = (dimensions.width + padding) + 20 + 'px'
						}
					} else {
						if (step.dimensions != undefined) {
							maskStyle = {
								'top': step.dimensions.top, 
								'left': step.dimensions.left, 
								'height': step.dimensions.height,
								'width': step.dimensions.width,
							}
						}
					}
					//console.log(maskStyle)
				
					// If the browser is Chrome
					if (window.navigator.userAgent.toLowerCase().indexOf("chrome") > -1) {
						//console.log(this.componentRefs[step.componentRef],this.currentStep)
						var topScrollOffset = 0
						if (this.isMobile){
							if (nameToUse == 'bugReport' || nameToUse == 'tutorial-button' || nameToUse == 'riskReport') {	
								this.originalDivTops[nameToUse] = this.componentRefs[nameToUse].getBoundingClientRect().top + window.scrollY
							}
						}
						//if ((this.currentStep == 8) || (this.currentStep == 9) || (this.currentStep == 10)){
						if (this.originalDivTops[nameToUse] + dimensions.height > window.innerHeight && nameToUse  != 'panel-content' && nameToUse != 'panel-content-not-tutorial') {
							//console.log('calculating top scroll offset')
							topScrollOffset = this.originalDivTops[nameToUse] + dimensions.height - window.innerHeight
							
							
						}
						if (!(this.isMobile)) { 
							//console.log('Scrolling to: ',topScrollOffset)
							this.componentRefs['panel-content'].parentElement.scrollTo({top: topScrollOffset,left: 0,behavior: "smooth"})
						} else {
							//console.log('Scrolling to: ',topScrollOffset)
							window.scrollTo({top: topScrollOffset,left: 0,behavior: "smooth"})

						}
					} else {
						//console.log('non chrome top scrolling running')
						if (nameToUse == 'panel-content' || nameToUse == 'panel-content-not-tutorial') {
							this.componentRefs[nameToUse].scrollIntoView({ behavior: "smooth", block: 'start', inline: 'nearest' })		
						} else {
							this.componentRefs[step.componentRef].scrollIntoView({ behavior: "smooth", block: 'end', inline: 'nearest' })
						}
					}

					//this.componentRefs[step.componentRef].scrollIntoView({ block: 'end'})
				} else {	

					//console.log('running backup scroll to up when theres no ref found')
					this.componentRefs['panel-content'].parentElement.scrollTo({top: 0,left: 0,behavior: "smooth"})

					// If no component is specified, it will not show
					// a bounding box and the sidepanel will be scrolled to the top
					if (this.componentRefs['search']) {
						//this.componentRefs['search'].scrollIntoView({ behavior: "smooth", block: 'end', inline: 'nearest' })
						this.componentRefs['search'].scrollIntoView({ block: 'end' })
					}
					maskStyle = {
						'width': 0,
						'height': 0,
					}
				}
				//console.log(maskStyle)
				return maskStyle
			
			} catch (e) {
				console.log('catch', e, step)
				return {
					'width': 0,
					'height': 0
				}
			}
			}
		},
		advanceStep() {
			//console.log(this.currentStep)
			//console.log(this.steps[this.currentStep + 1])
			if (this.currentStep != (this.steps.length - 1)) {


				if (this.steps[this.currentStep + 1].isNavMenu) {	
					this.setNavMenuVisibility(true)

					setTimeout(() => { 
					this.currentStep = this.currentStep + 1
					//console.log(this.currentStep)
					}, 5)
				} else {
					this.setNavMenuVisibility(false)
					/*
					if (!this.isMobile) {
						if (this.steps[this.currentStep + 1].componentRef == 'map') {
							this.setSidePanelVisibility(false)
						} else {
							this.setSidePanelVisibility(true)
						}
					}
					*/

					setTimeout(() => { 
					this.currentStep = this.currentStep + 1
					//console.log(this.currentStep)
					}, 5)
				}
					
				event('tutorial_advance_step', {'event_category': 'tutorial', 'tutorial_step': this.currentStep, 'location_ID' : window.location.href.split('/').slice(-1)})
				confection.submitEvent('tutorial_advance_step','location ID: ' + window.location.href.split('/').slice(-1) + ', tutorial step: ' + this.currentStep)
				
			} else {
				event('tutorial_finished', {'event_category': 'tutorial', 'location_ID' : window.location.href.split('/').slice(-1)})
				confection.submitEvent('tutorial_finished','location ID: ' + window.location.href.split('/').slice(-1))
				this.currentStep = 0
				this.setTutorialVisibility()
				this.setNavMenuVisibility(false)
			}
		},
		retreatStep() {
			if (this.currentStep != 0) {

				if (this.steps[this.currentStep - 1].isNavMenu) {
					//console.log('bug report', this.steps[this.currentStep - 1].componentRef)
					this.setNavMenuVisibility(true)

					setTimeout(() => { 
					this.currentStep = this.currentStep - 1
					}, 5)
				} else {
					this.setNavMenuVisibility(false)
					this.currentStep = this.currentStep - 1
				}
				event('tutorial_retreat_step', {'event_category': 'tutorial', 'tutorial_step': this.currentStep, 'location_ID' : window.location.href.split('/').slice(-1)})
				confection.submitEvent('tutorial_retreat_step','location ID: ' + window.location.href.split('/').slice(-1) + ', tutorial step: ' + this.currentStep)
			} else {
				this.currentStep = 0
				this.setTutorialVisibility()
				this.setNavMenuVisibility(false)
			}
		},
		closeTutorial() {
			event('tutorial_closed', {'event_category': 'tutorial', 'tutorial_step': this.currentStep, 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent('tutorial_closed','location ID: ' + window.location.href.split('/').slice(-1) + ', tutorial step: ' + this.currentStep)
			this.currentStep = 0
			this.setTutorialVisibility(false)	
			this.setNavMenuVisibility(false)
		},
		...mapActions(useConfigStore, ['getComponentRef', 'setTutorialVisibility', 'setSidePanelVisibility', 'setNavMenuVisibility']),
	},
	watch: {
		userLanguage(newLang) {
			setTimeout(() => {
				this.updateTutorialMasks()
			},1000)
		},
		'componentRefs': {
			handler: function(newState, oldState) {
				//console.log(newState)
				if ('panel-content' in this.componentRefs) {
					this.preventUserScroll()

				}
				
				//const componentRefs = this.steps.filter(step => step.componentRef != '').map(step => step.componentRef)

				//if (componentRefs.every((key) => newState.hasOwnProperty(key)))

				/*
				if (newState['panel-content']) {
					console.log('new panel content state')
					this.steps.filter(step => step.componentRef != '').forEach(step => {
						this.originalDivTops[step.componentRef] = this.componentRefs[step.componentRef].getBoundingClientRect().top
					})

					if (!(this.isMobile)) {
						//this.componentRefs['panel-content'].parentElement.style['smooth-scrolling'] = 'smooth'	
						this.componentRefs['panel-content'].parentElement.addEventListener('scroll', this.handleScroll)
					}	
					
				}
				const steps = this.steps.filter(step => step.componentRef != '')
				const stepNames = steps.map(x => x.componentRef) 
				if (stepNames.every(name => Object.keys(newState).includes(name))) {
					this.preventUserScroll(false)
					// Filter removes the empty string, that exists if there is no componentRef
					// forEach allow us to step through each remaining componentRef
					steps.forEach(step => {
						this.originalDivTops[step.componentRef] = this.componentRefs[step.componentRef].getBoundingClientRect().top
					})
					if (!(this.isMobile)) {
						this.componentRefs.sidepanelcontent.addEventListener('scroll', this.handleScroll)
					}
				}	
				*/
			},
			deep: true,
		},
		isRouteLocation(newState) {
			//console.log(newState)
			if (!newState) {
				setTimeout(() => {
					// Filter removes the empty string, that exists if there is no componentRef
					// forEach allow us to step through each remaining componentRef

					this.steps.filter(step => step.componentRef != '').forEach(step => {
						if ( this.componentRefs[step.componentRef]) {
							this.originalDivTops[step.componentRef] = this.componentRefs[step.componentRef].getBoundingClientRect().top
						}
					})
					//console.log(this.componentRefs)
					if (!(this.isMobile)) {
						//this.componentRefs['panel-content'].parentElement.style['smooth-scrolling'] = 'smooth'	
						this.componentRefs['panel-content'].parentElement.addEventListener('scroll', this.handleScroll)
					}	
				}, 1000)
			}
		},
		'tutorial': {
			handler: function(state) {
			},
			deep: true,
		},
		'tutorialSteps': {
			handler: function(state) {

			},
			deep: true,
		},
		isMobile(){
			setTimeout(() => {
				// Filter removes the empty string, that exists if there is no componentRef
				// forEach allow us to step through each remaining componentRef
				//console.log(this.componentRefs)
				this.steps.filter(step => step.componentRef != '').forEach(step => {
					if ( this.componentRefs[step.componentRef]) {
						this.originalDivTops[step.componentRef] = this.componentRefs[step.componentRef].getBoundingClientRect().top
					}
				})

				if (!(this.isMobile)) {
					if (this.componentRefs['panel-content']) {
						this.componentRefs['panel-content'].parentElement.addEventListener('scroll', this.handleScroll)
					}
					//this.componentRefs['panel-content'].parentElement.style['smooth-scrolling'] = 'smooth'	
					
				}	
			}, 5000)
		},
		isTutorialNotShownAgain(newState) {
			this.setCookie(newState)
		},
	}
}
</script>

<style lang="scss" scoped>
@import "../../style/arkly.scss";
.container {
	position: absolute;
	width: 100vw;
	height: 100vh;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}
.tutorial-mask {
	position: absolute;
	bottom: 5px;
	left: 10px;
	width: 250px;
	height: 35px;
	border-radius: 5px;
	box-shadow: 0 0 0 400vw rgba(0, 0, 0, 0.7);
	z-index: 9998;
}
#progress-number {
	font-family: $body-family;
	font-size: 14px;
	position: relative;
	margin: 0;
}
#message {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	gap: 7px;
	font-size: 25px;
	height: min-content;
	max-width: 500px;
	color: black;
	background-color: white;
	padding: 10px 20px 20px;
	border-radius: 5px;
	border: solid black 2px;
	line-height: 1;
	z-index: 9999;
}
#next-step {
	width: auto;
	position: relative;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	z-index: 9999;
	grid-area: next;
}
#skip-steps {
	position: relative;
	z-index: 9999;
	grid-area: skip;
}
#future-visibility {
	margin-left: 2px;
	padding-top: 5px;
	padding-bottom: 5px;
	margin-bottom: 5px;
	display: flex;
	flex-direction: row;
	height: 100%;
	width: 100%;
	justify-content: flex-start;
	align-items: center;
}
#future-visibility > p {
	padding-top: 2px;
	line-height: 1;
	margin-right: 6px;
}
#future-visibility > label {
	margin-right: 0;
	margin-top: 2px;
}
#previous-step {
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	z-index: 9999;
	grid-area: previous;
}
#progress-container {
	display: grid;
	grid-template-columns: 1fr auto 1fr;
	grid-template-rows: 1fr;
	grid-template-areas: 
		"previous . next";
	width: 100%;
}
.mobile-message {
	max-height: 450px;
	position: fixed !important;
	top: 100px !important;
}
#close-tutorial-container {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 10px;
}
#close-tutorial-container .hightide-button {
	padding: 0 !important;
}
</style>

