<template>
<div id='parent-container' v-if="'property_view' in textContent">
	<div class='info' id='flood-risk' v-on:click="showMoreInfo($event)">
		<!--<h1> {{floodRisk ? floodRisk.toLocaleString() : ''}} /{{ textContent['property_view']['flood-risk-unit'] ? textContent['property_view']['flood-risk-unit']['label'] : '' }} </h1>
		<p> {{ textContent['property_view']['flood-risk']['label'] ? textContent['property_view']['flood-risk']['label'] : '' }} </p>-->
		<h1> {{floodRisk ? floodRisk.toLocaleString() : ''}} </h1>
		<p> {{ textContent['property_view']['flood-risk']['label'] ? textContent['property_view']['flood-risk']['label'] : '' }} </p>
	</div>

	<div class='info' id='flood-zone' v-on:click="showMoreInfo($event)">
		<h1> {{floodZoneFormatted}} &ensp;({{floodZoneDescription}}) </h1>
		<p> {{ textContent['property_view']['flood-zone']['label'] ? textContent['property_view']['flood-zone']['label'] : '' }} </p>	
	</div>

	<div class='info' id='ground-elevation' v-on:click="showMoreInfo($event)">
		<h1> {{ groundElevationFcn }} </h1>
		<p> {{ textContent['property_view']['ground-elevation']['label'] ? textContent['property_view']['ground-elevation']['label'] : '' }} </p>
	</div>

	<div class='info' id='flood-elevation' v-on:click="showMoreInfo($event)">
		<h1> {{ BFE }} </h1>
		<p style='white-space: normal'> {{ textContent['property_view']['flood-elevation']['label'] }} </p>
	</div>
	<div class='info' id='floor-area' v-if="variant == 'hightide'" v-on:click="showMoreInfo($event)">
		<h1> {{ footprintArea }} </h1>
		<p> {{ textContent['property_view']['floor-area-more-info']['label'] }}</p>
	</div>

	<div class='info' id='first-floor-elevation' v-if="variant == 'hightide'" v-on:click="showMoreInfo($event)">
		<h1> {{ FFH }} </h1>
		<p style='white-space: normal'> {{ textContent['property_view']['first-floor-elevation-more-info']['label'] }} </p>
	</div>
</div>
<!--
<p style="margin-top: -15px; width:100%; display: flex; justify-content: center;">More information >></p>

<inline-svg style="top:-50px;"
	:src="require('@/assets/icons/info.svg')" 
	class='info v-center'  
/>
-->

<modal v-model="infoVisible">
	<div class='extraInfo' v-html='currentHTML'>
	</div>
</modal>

</template>

<script>
import { mapState, mapGetters, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import { useSurfStore } from '@/store/surf'
import Modal from '@/components/Modal'
import { event } from 'vue-gtag'

export default {
	name: 'FloodInfo',
	components: {
		'modal': Modal,
	},
	props: {
		floodRisk: {
			type: String,
			default: '0',
		},
		floodZone:{
			type: String,
			default: 'N/A',
		},
		groundElevation: {
			type: Number,
		},
		floodElevation: {
			type: String,
		},
		variant: {
			type: String,
			default: 'arkly',
		},
		floorArea: {
			type: String,
			default: 'N/A',
		},
		firstFloorHeight: {
			type: String,
			default: 'N/A',
		},
	},/*{

		'floodRisk': {
			type: Int,
		},
		'floodZone': {
			type: String,
		},

		'groundElevation': {
			type: Int,	
		},
		'floodElevation': {
			type: Int,
		},


	},*/
	emits: ['update:modelValue'],
	data() {
		return {
			infoVisible: false,
			currentTitle: undefined,
			currentText: undefined,
			currentHTML: undefined,
			idClicked: undefined,

		}
	},
	mounted(){
		//console.log(this.variant)
		var d = document.getElementById("parent-container");
		if (this.variant == 'hightide') {
			d.className += " flood-info-container-hightide-grid"
		} else {
			d.className += " flood-info-container-arkly-grid";
		}
		
		
	},
	methods: {
		showMoreInfo(clickEvent) {
			var idToUse = undefined
			if (clickEvent.currentTarget.id == 'flood-risk' && !this.averageAnnualLoss.value) {
				idToUse = 'flood-risk-flood-zone'
			} else {
				idToUse = clickEvent.currentTarget.id
			}
			event(idToUse + '_more_info_click', {'event_category': 'sidepanel', 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent(idToUse + '_more_info_click','location ID: ' + window.location.href.split('/').slice(-1))
			this.idClicked = idToUse
			this.currentHTML = this.textContent['property_view'][idToUse + '-more-info']['text_html']
			this.makeInfoVisible()

		},
		makeInfoVisible() {		
			if (!this.infoVisible) {
				this.infoVisible = !this.infoVisible
			}
		},
	},
	computed: {
		FFH() {
			if (this.controls.elevation > 0) {
				return this.firstFloorHeight.toLocaleString() + ' (+' + this.controls.elevation.toLocaleString() + ') ft'
			} else {
				return this.firstFloorHeight.toLocaleString() + ' ft'
			}
		},
		groundElevationFcn(){
			if (this.groundElevation < 0 ){
				return (Math.round(this.groundElevation * 10)/10).toLocaleString() + ' ft'
			} else {
				return '+' + (Math.round(this.groundElevation * 10)/10).toLocaleString() + ' ft'
			}
		},
		footprintArea() {
			return this.floorArea + ' sqft'
		},
		BFE() {
			if (this.floodElevation < 0 ){
				return "N/A"
			} else {
				return "+" + Math.round(this.floodElevation * 10)/10 + " ft"
			}
		},
		floodZoneFormatted() {
			if (this.floodZone) {
				return this.floodZone.split('|')[0]
			}
		},
		floodZoneDescription() {
			if (this.floodZone) {
				if (this.floodZone.startsWith('A') || this.floodZone.startsWith('V')){
					const highRisk = this.textContent['property_view']['flood-zone-desc-high-risk']
					return  highRisk ? highRisk['label'] : ''
				} else {
					const lowRisk =  this.textContent['property_view']['flood-zone-desc-non-high-risk']
					return  lowRisk ? lowRisk['label'] : ''
				}
			} 
		},
		...mapState(useConfigStore, ['textContent','controls']),
		...mapState(useSurfStore, ['floodZoneExpectedLoss','averageAnnualLoss']),
	}
}

</script>

<style lang="scss" scoped>
@import "../../style/arkly.scss";



.extraInfo {
	margin: 20px;
}
.info {
	padding: 10px;
	border-radius: 5px;
	background-color: #F4F3F1;
	
}

.info:hover {
	//background-color: #F4F3F1;
	filter: brightness(90%);
	cursor: pointer;
	//box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;;
	//box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

h1 {
	font-style: normal;
	font-weight: bolder;
	font-size: 18px;
	line-height: 1;

	color: black;

	/* identical to box height */
	letter-spacing: 1px;
	font-feature-settings: 'pnum' on, 'lnum' on;
}

p {
	margin: 8px 0;
	font-weight: bold;
	//white-space: nowrap;
}

/*
.flood-info-container:first-child h1 {
	color: $red;
}
*/

#flood-risk {
	grid-area: risk;
}

#flood-risk h1 {
	color: $red;
}

#flood-zone h1 {
	color: $blue;
}

#flood-zone {
	grid-area: zone;
}


#ground-elevation {
	grid-area: gElevation;
}

#flood-elevation {
	grid-area: fElevation;
}

#floor-area {
	grid-area: fArea;
}

#first-floor-elevation {
	grid-area: ffElevation;
}

.flood-info-container {
	height: auto;
	width: 100%;
	max-width: 330px;
}

.flood-info-container-arkly-grid {
	display: grid;
	//grid-template-columns: 1fr auto;
	//grid-template-rows: 1fr 1fr;
	grid-template-areas: 
		" risk       zone       "
		" gElevation fElevation ";
	grid-gap: 16px;
}
.flood-info-container-hightide-grid {
	display: grid;
	grid-template-columns: 1fr auto;
	grid-template-rows: 1fr 1fr 1fr;
	grid-template-areas: 
		" risk       zone       "
		" gElevation fElevation "
		" fArea ffElevation ";
	grid-gap: 16px;
}

</style>
