<template>
<div class='loss-over-time' :style="{ height: height, width: width }">
	<h2 class='title' v-if='$route.params.type == "city"'>  
		This city's total damage, during a Category {{ stormCategory.slice(-1) }} Storm, is predicted to increase by the following amount: 
	</h2>
	<h2 class='title' v-else> 
		Due to sea level rise, this {{$route.params.type}}'s annual risk will increase by approximately ${{increasedAmount}} by {{endYear}}.
	</h2>

	<div class='chart'>
		<Line
			:data='chartData' 
			:options='options'
			
			ref="lossOverTime"
		/>
	</div>
</div>
</template>

<script>
import { Line } from 'vue-chartjs'
//import { LineChart } from 'vue-chart-3'
import { mapState, mapGetters, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import { Chart } from 'chart.js'

Chart.register('annotationPlugin')

const data = [
    { year: 2010, count: 10 },
    { year: 2011, count: 20 },
    { year: 2012, count: 15 },
    { year: 2013, count: 25 },
    { year: 2014, count: 22 },
    { year: 2015, count: 30 },
    { year: 2016, count: 28 },
  ]

export default{
	name: 'LossOverTime',
	components: {
		Line,
	},
	props: {
		AAL: {},
		maxAAL: {},
		buildingCategory: {},
		yAxesLabel: {},
		xAxesLabel: {},
		width: {
			type: String,
			default: '100%',
		},
		height: {
			type: String,
			default: '100%',
		},
		startYear: {
			type: String,
			default: '2020',
		},
		endYear: {
			type: String,
			default: '2070',
		},
		projection: {
			type: String,
			default: 'NOAA',
		},
		stormCategory: {
			type: String,
			default: 'category1'
		},
		year: {
			type: String,
			default: '2020',
		},
		max: {},
	},
	data() {
		return {
			stormColors: {
				'cat1': '#ffffcc',
				'cat2': '#ffe775',
				'cat3': '#ffc140',
				'cat4': '#ff8f20',
				'cat5': '#ff6060',
			},
		}
	},
	mounted() {			
		Chart.defaults.font.family = this.bodyFont.family
		Chart.defaults.font.size = this.bodyFont.size
		Chart.defaults.font.weight = '400'	
		/*
		setTimeout(() => { 
			//const chartInstance = this.$refs.lossOverTime.chart.update('none')
		}, 100)
		*/
	},
	computed: {
		chartData: function() {
			return {
				labels: this.formattedData['labels'],
				datasets: [
					{
						data: this.formattedData['lowerBound'],
						borderColor: 'rgb(80, 130, 196)',
						borderWidth: 0,
						radius: 0,
						tension: 0.3,
						fill: false,
					},
					{
						data: this.formattedData['data'],
						borderColor: 'rgb(80, 130, 196)',	
						borderWidth: 3,
						radius: 0,
						tension: 0.3,	
					},
					{
						data: this.formattedData['upperBound'],
						borderColor: 'rgb(80, 130, 196)',
						backgroundColor: 'rgb(80, 130, 196, 0.5)',
						borderWidth: 0,
						radius: 0,
						tension: 0.3,
						fill: '-2',
					},
				],
			}
		},
		options: function() {
			return {
				responsive: true,
				position: 'relative',
				maintainAspectRatio: false,
				animation: false,
				plugins: {
					legend: {
						display: false,
					},
					tooltip: {
						intersect: false,
						callbacks: {
							label: (context) => {
								const index = context['dataIndex']
								const lowerBound = this.abbreviateNumbers(this.formattedData['lowerBound'][index], 3)	
								const mean = this.abbreviateNumbers(this.formattedData['data'][index], 3)
								const upperBound = this.abbreviateNumbers(this.formattedData['upperBound'][index], 3)
								return [`The mean average annual loss is $${mean}.`, `The 68% confidence interval is from $${lowerBound} to $${upperBound}.`]
							}
						},
					},
				},
				/*
				annotation: {
					annotations: [{
						drawTime: 'afterDatasetsDraw',
						type: 'line',
						mode: 'vertical',
						//value: this.startYear,
						borderColor: 'rgb(130, 172, 222)',
						borderWidth: 4,
						label: {
							enabled: false,
							content: 'Test label'
						}
					}]
				},
				*/
				scales: {
					x: {
						title: {
							display: true,	
							text: 'Year',
							font: {
								size: 18,
								weight: '600'
							},
							padding: 6,
						}
					},
					y: {
						title: {
							display: true,	
							text: 'Average Annual Loss',
							font: {
								size: 14,
								weight: '600'
							},
							padding: 4,
						},
						type: 'linear',
						beginAtZero: true,
						min: 0,
						suggestedMax: this.maxAAL,
						ticks: {
							fontSize: 14,
							fontStyle: '400',				
							callback: (value, index, values) => {
								const abbreviatedValue = this.abbreviateNumbers(value, 3)
								return '$' + abbreviatedValue
							},
							padding: 10,
						}
					},	
				},

			}
		},
		formattedData: function() {
			
			
			const data = this.AAL[this.projection]
			const values = Object.values(data).filter(value => value != null)
			const mean = Object.values(values).map(value => value['mean'])
			var keys = Object.keys(data).map(key => Number(key))
			if (keys.includes(2120)) {
				keys = keys.slice(0,keys.length -1)

			}
			let upperBound, lowerBound

			// If the input data has 'lowerBound'
			if( 'lowerBound' in Object.values(data)[0] ) {
				
				upperBound = Object.values(values).map(value => value['upperBound'])
				lowerBound = Object.values(values).map(value => value['lowerBound'])

			// If data only has 'mean' and 'stdev'
			} else {	
				
				upperBound = Object.values(values).map(value => value['mean'] + value['stdev'])
				lowerBound = Object.values(values).map(value => value['mean'] - value['stdev'])
			}

			return { labels: keys, data: mean, upperBound: upperBound, lowerBound: lowerBound }
		},
		increasedAmount: function() {
			let data = this.AAL[this.projection]
	
			let increasedAmount = this.abbreviateNumbers(data[this.endYear]['mean'] - data['2020']['mean'], 3)	
			return increasedAmount
		},
		/*
		max() {
			let data = this.startingAAL

			//Makes array of RCP values
			let maxValues = Object.values(data).map(forProjection => {
				return Object.values(forProjection).map(atYear => { 
					return atYear['upperBound'] 
				}).flat()//merges each inner array into one array of all values
			}).flat()
				
			// Using Exponential Notation ie: 8e+2
			// Precision is set to 0, so only one digit is in front of the 'e'
			let max = Math.max(...maxValues).toExponential(3)
			
			// Take the number before the e and add 1 then merge with the rest of the exponential string
			// this finds the ceiling at precision of 1
			// **lodash and Math ceil functions precision did not reduce to 1 digit
			let ceil = Number(Number(max[0]) + max.slice(1))
			return (ceil == 0) ? 10 : ceil
		},
		*/
		...mapState(useConfigStore, ['bodyFont']),
	},
	methods: {
		findDeviation(data, numberAboveOrBelow){
			const deviation =  data['mean'] + numberAboveOrBelow*data['stdev']
			
			return deviation <= 0 ? 0.00001 : deviation
		},
		abbreviateNumbers ( number, precision ) {

			const max = this.max
			const abbrev = {'':1, 'K':1000, 'M':1000000, 'B':1000000000}
			const unrangifiedOrder = Math.floor(Math.log10(Math.abs(number)) / 3)
			const order = Math.max(0, Math.min(unrangifiedOrder, Object.keys(abbrev).length -1 ))
			const suffix = Object.keys(abbrev)[order]

			if ((number / Math.pow(10, order * 3)) == 0) {
				return 0
			} else {
				if (number < 100) {
					return (number / Math.pow(10, order * 3)).toFixed(0) + suffix
				} else {
					return (number / Math.pow(10, order * 3)).toPrecision(precision) + suffix
				}
			}
		},
	},
	watch: {
		height: function(newValue) {
			for (var id in Chart.instances) {
				Chart.instances[id].resize()
			}
		},
		width: function(newValue) {
			for (var id in Chart.instances) {
				Chart.instances[id].resize()
			}
		},
		AAL: {
			handler: function(newValue){
				//console.log(newValue)
			},
			deep: true,
		},
		maxAAL: function(newValue) {
			//console.log(newValue)
		},
		stormCategory: function(newValue){
			//console.log('lossOverTime: ', newValue)
		}

	}
}
</script>

<style lang="scss" scoped>
@import '../../style/style.scss';


.chart {
	height: 270px !important;
	grid-area: chart;
}

.loss-over-time {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows:
		auto
		350px;
	grid-template-areas: 
		" title  "
		" chart  ";
	min-height: 350px;  /* NEW */
	min-width: 0;   /* NEW; needed for Firefox */
	width: 100%;
	flex-direction: column;
	color: $grey;
	overflow: hidden;
}

.title {
	padding-bottom: 20px;
	grid-area: title;
}

@media screen and (max-width: 600px) {
	
	h3 {
		margin: 0px;
	}
}

</style>

