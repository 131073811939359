<template>
	<div :class="circular ? '' : 'btn-container'" :style="{'width': width}">
	<div type="button" :class="classes" :style="style" v-if='!circular'>
		
		<div class='text'>
			<slot />
		</div>

		<inline-svg	
			v-if='icon'
			class='btn-icon'
			ref='btn-icon'
			:class='btnClass'
			:style='iconStyle'
			:src='iconSRC'
			:title='title'
		/>
	</div>
	<span class='circular-btn-container' v-else>
		<div :class='classes' :style='style'>
			<inline-svg	
				class='btn-icon'
				:class='btnClass'
				:style='iconStyle'
				:src='iconSRC'
				:title='title'
			/>
		</div>
		<slot />
	</span>
</div>
</template>

<script>
import { reactive, computed, useSlots } from 'vue';
import { mapState, mapGetters, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import InlineSvg from 'vue-inline-svg'
import ArklyStyle from '@/style/_exports.module.scss'


export default {
	name: 'Button',
	components: {
		'inline-svg': InlineSvg,
	},
	props: {
		label: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			default: undefined
		},
		variant: {
			type: String,
			default: 'default',
			validator: function (value) {
				return ['default', 'alt', 'arkly', 'menu', 'variant', 'learn-more', 'modal'].indexOf(value) !== -1;
			},
		},
		size: {
			type: String,
			default: 'medium',
			validator: function (value) {
				return ['small', 'medium', 'large'].indexOf(value) !== -1;
			},
		},
		height: {
			type: String,
			default: undefined,
		},
		outlined: {
			type: Boolean,
			default: false
		},
		circular: {
			type: Boolean,
			default: false,
		},
		color: {
			type: String,
			default: 'black'
		},
		backgroundColor: {
			type: String,
			default: undefined,
		},
		width: {
			type: String,
			default: undefined,
		},
		icon: {
			type: String,
			default: undefined,
		},
		flipIcon: {
			type: Boolean,
			default: false
		},
		noPadding: {
			type: Boolean,
			default: false,
		}
	},
	mounted() {

	},
	computed: {
		hasDefaultSlot () {
			return !!this.$slots.default
		},
		iconSRC() {
			return require(`../assets/icons/${this.icon}.svg`)
		},
		btnClass() {
			let size = `btn-icon--${this.size}`
			return { size: true, 'flipped-btn-icon': this.flipIcon }
		},
		cssBackgroundColor() {
			if (this.outlined && (this.backgroundColor == undefined) && (this.variant != 'arkly')) {
				return 'white'
			} else {
				if (this.backgroundColor in this.colors) {
					return this.colors[this.backgroundColor] + ' !important'
				}  else {
					return this.backgroundColor
				}
			}
		},
		cssColor() {
			if (this.outlined || this.variant == 'learn-more') {
				return 'black'
			} else {
				if (this.color in this.colors) {
					return this.colors[this.color]
				}  else {
					return this.color
				}
			}
		},
		border() {
			if (this.variant != 'arkly') {
				if (this.outlined) {
					return '1px' + ' solid ' + this.cssColor
				} else {
					return 'none'
				}
			}
		},
		style() {
			let dimension
			if (this.height) {
				dimension = this.height
			} else {
				if (this.size == 'small') {
					dimension = '22px'
				} else if (this.size == 'medium') {
					dimension = '44px'
				} else {
					dimension = '65px'
				}
			}
			
			let color
			if (this.outlined ) {
				color = 'black'
			} else {
				color = this.cssColor
			}

			let style = {color: color, border: this.border, 'background-color': this.cssBackgroundColor, 'height': dimension}

			if (this.circular) {
				style['border-radius'] = '50%'
				style['padding'] = '8px'
				style['width'] = dimension
			} 
	
			if (this.noPadding) {
				style['padding'] = '0px !important'
			}
				
			if (this.width == undefined) {
				//style['width'] = ArklyStyle['default-btn-width']
			} else {
				style['width'] = this.width
			}

			if ( this.size == 'large') {
				style['padding'] = '24px 48px'
			}
			
			return style
		},
		iconStyle() {
			let dimension
				
			if (this.circular ) {
				if (this.size == 'small') {
					dimension = '12px'
				} else if (this.size == 'medium') {
					dimension = '24px'
				} else {
					dimension = '30px'
				}
			} else {
				if (this.size == 'small') {
					dimension = '18px !important'
				} else if (this.size == 'medium') {
					dimension = '18px !important'
				} else {
					dimension = '30px !important'
				}	
			}

			if (this.hasDefaultSlot) {
				return {'height': dimension, 'width': 'auto'}
			} else {
				return {'height': dimension, 'width': 'auto'}
			}
		},
		classes() {
			let classes = ['hightide-button', 'o-btn']
			if (this.size) {
				classes.push('o-btn--' + this.size)
			}

			if (this.variant == 'menu') {
				classes.push('menu')
			}

			if (this.variant == 'arkly') {
				if (this.outlined) {
					classes.push('arkly-button-outlined')
				} else {
					classes.push('arkly-button')
				}
			}
			if (this.variant == 'learn-more') {
				classes.push('learn-more-button')
			}
			if (this.variant == 'modal') {
				classes.push('modal')
			}
			return classes
		},
		...mapState(useConfigStore, ['colors']),
	}
}
</script>

<style lang="scss" scoped>
@import "../style/oruga.scss";
@import "../style/arkly.scss";


///////////////////////////////////////////////////////////////////
// All Button Styles 
///////////////////////////////////////////////////////////////////
.btn-container * {
	/* If you want to implement it in very old browser-versions */
	-webkit-user-select: none; /* Chrome/Safari */ 
	-webkit-touch-callout: none;
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+ */
	-khtml-user-select: none;

	/* The rule below is not implemented in browsers yet */
	-o-user-select: none;

	/* The rule below is implemented in most browsers by now */
	user-select: none;
}

.btn-container .text {
	padding: 0 30px;
}

.btn-container .text:empty {
	display: none;
}

.btn-container {
	display: flex;
	width: min-content;
	height: auto;
}

.circular-btn-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.btn-icon {
	display: flex;
	height: 14px;
	width: auto;
	//margin: 0 20px;
}

@media only screen and (max-width: 300px) {

	.btn-container .btn-icon {
		margin: 0 15px 0 0;
	}

}

.circular-btn-container .btn-icon {
	margin: 0 0;
}

.flipped-btn-icon {
	transform: rotate(180deg);
}

///////////////////////////////////////////////////////////////////
// Arkly Button Styles 
///////////////////////////////////////////////////////////////////

.arkly-button, .arkly-button-outlined, .modal {
	/* Button */
	/* Auto layout */
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	//padding: 24px 48px;
	gap: 8px;
	//height: 65px !important;
	height: 44px;

	/* Inside auto layout */
	flex: none;
	order: 1;
	align-self: stretch;
	flex-grow: 0;

	/* Web/CTA */
	font-family: $body-family;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;

	/* identical to box height */
	text-align: center;
	line-height: 1.1;
	font-feature-settings: 'pnum' on, 'lnum' on;

	// Border 
	/* ARKLY EFFECTS/Inner */
	//box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.1);
	border: 2px solid #000000;
	border-radius: 200px;

	/* Inside auto layout */
	flex: none;
	order: 0;
	flex-grow: 0;
}

.arkly-button #text {
	/* If you want to implement it in very old browser-versions */
	-webkit-user-select: none; /* Chrome/Safari */ 
	-webkit-touch-callout: none;
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+ */
	-khtml-user-select: none;

	/* The rule below is not implemented in browsers yet */
	-o-user-select: none;

	/* The rule below is implemented in most browsers by now */
	user-select: none;
}

.arkly-button {
	width: $default-btn-width;
	height: 74px;

	/* ARKLY COLORS/Ornaments/Canary */
	background: $yellow;

	/* ARKLY EFFECTS/Inner */
	//box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.1);
	border: 2px solid #000000;
	border-radius: 200px;


	/* ARKLY COLORS/Text/White */
	color: black;

}

.arkly-button-outlined {
	
	box-sizing: border-box;

	//width: 291px;
	//height: 65px;
	
	color: black;
	background-color: white !important;
	/* ARKLY COLORS/Text/Midnite */
	//border: 2px solid #040E29;
	//border-radius: 100px;

	/* Inside auto layout */
	flex: none;
	order: 0;
	flex-grow: 0;
}

///////////////////////////////////////////////////////////////////
// Learn More Button Styles 
///////////////////////////////////////////////////////////////////

.learn-more-button {
	font-family: $body-family;
	font-weight: bold;
	font-size: 16px;
	padding: 0;	
	color: $grey;
	background-color: rgb(242, 244, 245);
	
	//height: 24px;
	//width: $default-btn-width;
	border-radius: 100px;
	margin-bottom: 5px;
	margin-top: 5px;
}

.hightide-button {	
	cursor: pointer;
	display: inline-flex;
	opacity: 1 !important;	
	position: relative;
	vertical-align: top;
	outline: none;
	z-index: 10;
	color: grey;
	user-select: all;
}

.hightide-button:hover {
	filter: brightness(90%) !important;
}

.modal {
	filter: brightness(90%);
}

.modal:hover {
	filter: brightness(80%) !important;
}



/*
.btn-icon--small {
	height: 12px;
}

.btn-icon--medium {
	height: 24px;
}

.btn-icon--large {
	height: 30px;
}
*/

.hightide-button:hover .btn-icon{
	filter: brightness(140%) !important;
}


</style>
