<template>

<inline-svg 
	:src="getLogoPath(name)" 
	class='center icon'  
	:style='iconStyle'
	@loaded="console.log()"
/>

</template>

<script>
import InlineSvg from 'vue-inline-svg'

export default {
	components: {
		InlineSvg,
	},
	props: {
		name: {
			type: String,
		},
		size: {
			type: String,
			default: undefined,
		},
		height: {
			type: String,
			default: '25px',
		},
		width: {
			type: String,
			default: 'auto',
		},
		color: {
			type: String,
			default: 'inherit',
		}
	},
	data() {
		return {
			mySvg: undefined,
		}
	},
	computed: {
		iconStyle() {
			return `height: ${this.height}; width: ${this.width}; color: ${this.color};`
		},
	},
	methods: {
		getLogoPath(name) {
			var logo = require.context('./../assets/icons', false, /\.svg$/)
			return logo('./' + name + ".svg")
		},
	},

}

</script>

<style lang="scss" scoped>

.icon {
	width: 100%;
	height: inherit;
}

</style>
