<template>
<div class='checkout-container'>
	<inline-svg
          title='Arkly Logo'
          :src="require('@/assets/icons/arkly-logo-watermark-trademark.svg')"
          style="width:200px;height:200px;"
      /> 
	<h4> {{ textContent['buttons']['payment-not-complete-text']['label'] }} </h4>
	<btn 
				label="explore-arkly-btn"
				id='explore-arkly-btn'
				variant="arkly"
				size='large' 
				width='auto'
				@click="goHome('failed_payment')"
			> {{ textContent['buttons']['return-to-Arkly-home']['label'] }}</btn>
</div>
</template>

<script>

import Button from '@/components/Button'
import { mapActions, mapState } from 'pinia'
import { useConfigStore } from '@/store/config'

export default {
    name: 'FailedPayment',
	components: {
		'btn': Button,
	},
    data() {
        return {

		}
    },
	computed: {
		...mapState(useConfigStore, ['textContent']),
	},
	methods: {
	...mapActions(useConfigStore, ['goHome']),
	},
	watch: {
	},
}

</script>

<style>

.checkout-container{
	margin-top: 30vh !important;
	padding: 20px;
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 20px;
}

</style>
