<template>
<div class='card safariBullshitWrapper' v-bind:style='cardStyle'>
	<slot/>
</div>
</template>

<script>

export default {
	name: 'Card',
	props: {
		height: {
			type: String,
			default: '100%',
		},
		width: {
			type: String,
			default: '100%',
		}
	},
	computed: {
		cardStyle: function() {
			return {
				height: this.height,
				width: this.width
			}
		}
	},
}

</script>

<style lang="scss" scoped>
@import "../style/style.scss";

.card {
	position: relative;
	min-width: 0;
	max-width: 100%;
	height: auto;
	//box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
	border-radius: $border-radius;
	background-color: #fff;
	color: #4a4a4a;	
	overflow: visible;
}

</style>

