<!-- HTML -->
<template>

<carousel ref='insure' @slideChanged='slideChanged' >
<div class='first page' :style="{width: isMobile ? '90vw' : '350px'}" ref='mitigate' id="insurance_page1" v-if="textContent['insurance']">
	
	<div style='width:100%' v-html="textContent['insurance']['slide1_text1']['text_html']"></div>
	<div class='content'>
		<div class='price'>
			<h3> ${{ abbreviateNumbers(meanNFIP, 2, false) }} </h3>
			<h5> {{ textContent['buttons']['per-year']['label'] }} </h5>
		</div> 

		<p v-html="textContent['insurance']['slide1_text2']['text_html']"></p>

		<btn
			label="get-your-quote"
			variant='arkly'
			:title="textContent['buttons']['get-quote-btn-v2']['label']"
			icon='arrow-down-right'
			width='100%'
			@click="showFunnel('firstPage')"
			
		> {{textContent['buttons']['get-quote-btn-v2']['label']}}
		</btn>
	</div>

</div>

<div class='page' id="insurance_page2" v-if="textContent['insurance']">
	<notification 
			variant='warning' 
			:title="textContent['insurance']['slide2_alert']['title']"
			:content="textContent['insurance']['slide2_alert']['text_html']"
		/>
	<br>
	<div v-html="textContent['insurance']['slide2_text1']['text_html']"></div>
	<br>
	<div class='action'>
		<h5> {{textContent['buttons']['consult-an-expert']['label']}} </h5>
		<expert-consult 
			variant='arkly' 
			title=''
			width='72px !important'
			consultType='risk'
			icon='arrow-down-right'
		/>
	</div>
</div>

<div class='page' id="insurance_page3" v-if="textContent['insurance']">
	<div class='info' v-html="slide3_content"></div>
	<br>
	<btn
		label="get-your-quote-2"
		variant='arkly'
		:title="textContent['buttons']['get-quote-btn-v2']['label']"
		icon='arrow-down-right'
		@click="showFunnel('lastPage')"
	>
		{{textContent['buttons']['get-quote-btn-v2']['label']}}
	</btn>
	
	<rocketflood v-model='isRocketFloodVisible'/>
</div>

<div class='page' id="insurance_page4" v-if="textContent['insurance']">
	<div class='info' v-html="textContent['insurance']['slide4_text1']['text_html']">
	</div>
</div>
<div class='page' id="insurance_page5" v-if="textContent['insurance']">
	<div class='info' v-html="textContent['insurance']['slide5_text1']['text_html']">
	</div>
	<br>
	<div class="homeInsInfoContainer">
		<tbody>
			<tr v-for="item in homeInsInfo.rates">
			<th scope="row"><a :href=item[2]>{{ item[0] }}</a></th>  
			<td>${{ item[1].toLocaleString() }}/year</td> 
			</tr>
		</tbody>
	</div>
	<br>
	<div id='ins-type-selector-div'>
		<select id="ins-type-selector" v-model='currentHomeInsType' style="max-width: 315px;">
			<option value='noWindMit_pre2001_150000'> {{ textContent['insurance']['homeowners-ins-types']['text_html'].split('|')[0] }} </option>
			<option value='WindMit_pre2001_150000'> {{ textContent['insurance']['homeowners-ins-types']['text_html'].split('|')[1] }} </option>
			<option value='newConstruct_300000'> {{ textContent['insurance']['homeowners-ins-types']['text_html'].split('|')[2] }} </option>
		</select>
	</div>
	
</div>

</carousel>

</template>

<!-- JavaScript -->
<script>
import { mapState, mapGetters, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import { useSurfStore } from '@/store/surf'
import Button from '@/components/Button'
import Footer from '@/components/sidepanel/Footer'
import Notification from '@/components/sidepanel/Notification'
import Carousel from '@/components/Carousel'
import ExpertConsult from '@/components/arkly/ExpertConsult'
import RocketFlood from '@/components/arkly/RocketFlood'
import { event } from 'vue-gtag'

export default {
	name: 'flood-insurance',
	components: {
		'btn': Button,
		'notification': Notification,
		'sidepanel-footer': Footer,
		'carousel': Carousel,
		'expert-consult': ExpertConsult,
		'rocketflood': RocketFlood,
	},
	data() {
		return {
			isRocketFloodVisible: false,
		}
	},
	mounted() {
		this.addComponentRef('insure-your-risk', this.$refs.insure)
	},
	computed: {
		slide3_content() {
			var currContent = this.textContent['insurance']['slide3_text1']['text_html']
			currContent = currContent.replace('nfipValue',this.abbreviateNumbers(this.meanNFIP, 2, false))
			return currContent
		},
		currentHomeInsType: {
			get() {
				return this.homeInsInfo.type
			},
			set(newInsType) {
				this.setHomeInsType(newInsType)
				this.getHomeInsRates(this.homeInsInfo.type)
			},
		},
		...mapState(useConfigStore, ['textContent', 'isMobile']),
		...mapState(useSurfStore, ['meanNFIP','homeInsInfo']),
	},
	methods: {
		formatNumber(number){
			if (String(number).length == 4) {
				return String(number).slice(0) + ',' + String(number).slice(1,-1)
			} else {
				return String(number)
			}
		},
		async getInsRates(){
			await this.getHomeInsRates(this.homeInsInfo.type)
		},
		showFunnel(source){
			event('rocketflood_visible', {'event_category': 'rocketflood', 'source': source, 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent('rocketflood_visible','location ID: ' + window.location.href.split('/').slice(-1) + ', source: ' + source)
			this.isRocketFloodVisible = true
		},
		slideChanged(newSlide) {
			event('insurance_carousel', {'event_category': 'sidepanel', 'newPage': newSlide.id, 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent('insurance_carousel','location ID: ' + window.location.href.split('/').slice(-1) + ', new page: ' + newSlide.id)
		},
		closePanel() {
			this.$emit('closePanelContent') 
		},
		...mapActions(useSurfStore, ['setHomeInsType','getHomeInsRates','abbreviateNumbers']),
		...mapActions(useConfigStore, ['goToRocketFlood', 'addComponentRef']),	
	},
	watch: {
		isRocketFloodVisible(newState) {
			//console.log(newState)
		},
	},
}

</script>

<!-- CSS -->
<style lang="scss" scoped>
@import "../../style/arkly.scss";
@import "../../style/oruga.scss";

///////////////////////////////////////////
// drop down menus
///////////////////////////////////////////
#ins-type-selector {
	height: 44px;
	border-radius: 200px;
	border: 2px solid #000000;

}
#ins-type-selector:hover {
	background-color: #e5e5e5;
	
}

h1 {
	text-align: center;
	font-size: 30px;
	font-weight: bold;	
	margin: 20px 5px;
}

h2 {
	text-align: center;
	line-height: 1.4;
	margin: 20px 5px 10px;
}

p {
	margin-top: 10px;
}

.close-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: min-content;
	z-index: 999;
}

.page {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	text-align: left;
	padding: 30px;
	height: 100%;
	width: 100%;
}

.page .title {
	padding-top: 70px;
}

.page .btn-container {
	width: auto;
}

.first {
	background-color: $oat;
}

.page .content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 30px;
	width: 100%;
//	padding: 30px;
}

.page > .content > .price {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-bottom: 2px solid black;
	width: 100%;
}

.page .action {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.signUp {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
}

.o-field {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	max-height: 80px;
	width: 100%;
}

</style>

