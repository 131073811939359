<!-- HTML: This is Where You Put the HTML Code -->
<template>
	<div class='spinner-container center'>
			<p v-if='longLoad' class='spinner-text'>Data is still loading or does not exist for the area selected</p>
			<inline-svg v-if='!longLoad' :style="calcLoadingTime"
				:src="require('../assets/icons/spinner.svg')" 
				class='rotating'
			/>

	</div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

export default {
	name: 'Spinner',
	components: {
		InlineSvg,
	},
	data() {
		return {
			longLoad: false,

		}
	},
	props: {

	},
	computed: {
		calcLoadingTime() {
			this.longLoad = false
			setTimeout(() => {
				this.longLoad = true
			},3000)

		},

	},
	watch: {
		'$route': {
			handler: function(result) {
				this.longLoad = false
				setTimeout(() => {
					this.longLoad = true
				},3000)
			},
			deep: true,
		},
	},
	methods: {

	}
}
</script>


<!-- CSS -->
<style lang="scss">
@import "../style/style.scss";
@import "../style/oruga.scss";

.spinner-container {
	width: 100%;
	height: auto;
	//background-color: rgba(74, 74, 74, 0.1);
}

.spinner-text {
	padding: 10px;
}

.rotating {
	width: 60px;
	animation: rotate 1s infinite;
}

@keyframes rotate {
   from { transform: rotate(0deg); }
   to { transform: rotate(360deg); }
}

</style> 
