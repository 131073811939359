<!-- HTML -->
<template>
	<div class="grid">
		<control-bar
			id="topbar"
			@controls='handleControls'
			@changeInElevation='handleChangeInElevation'
		/>

		<mapbox 
			:isMapVisible="isMapVisible"
			:isProperty='true'
			:startCoordinates='startCoordinates'
			zoom="6"
			id="mapbox"	
			:layerLabel='mapConfig.currentLayer'
			useType='total'
			:units='controls.units'
			:year='controls.year'
			:stormCategory='stormCategory'
			@controls='handleControls'
			@zoomChanged='handleZoom'
		/>


		<foot 
			id="footer"
			borderRadius='3px 0px 0px 0px'
		/>

	</div>

</template>

<!-- JavaScript -->

<script>
import { mapState, mapGetters, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import { useSurfStore } from '@/store/surf'
import axios from 'axios'
import Map from '@/components/map/Map'
import Footer from '@/components/Footer'
import ControlBar from '@/components/govSub/ControlBar'
import { getAuthToken } from '@/helpers/auth.js'

import SampleData from '@/assets/data/sample_data.json'

export default {
	name: 'result',
	components: {
		'mapbox': Map,
		'foot': Footer,
		'control-bar': ControlBar,
	},
	data() {
		return{
			isMapVisible: true,
			isFloodTableReady: false,
			isAddressUnavailable: false,
			tooltips: null,
			mapZoom: 7,
			startCoordinates: {
				'lng': -81.760254,
				'lat': 27.994402
			},
			//firstFloorElevation: null,
			updatedFirstFloorElevation: null,
			changeInElevation: 0,
			expectedLoss: null,
			averageAnnualLoss: null,
			zoom: 14.5,
			stormCategory: '5year',
			stormCategoryLookup: {
				'5year': 'category1',
				'10year': 'category2',
				'25year': 'category3',
				'50year': 'category4',
				'100year': 'category5'
			},
			mapLayer: 'NOAA_5year_2020_mean',
			cmsQuery: `
				query{
					items{
						city_data(filter: { id: { _eq: "Ponce-Inlet" } }) {
							data
							revised_data
						}
						dashboard {
							sea_level_rise_projections
							expected_damage_time_series
							damage_distribution
						}
					}
				}
			`
		}
	},
	computed: {
		endpointURL: function() {
			if (this.$route.params.type == 'property') {
				return `${this.url}/surf/deterministic/${this.$route.params.type}/${this.$route.params.id}`
			} else {
				return `${this.url}/surf/aggregate/${this.$route.params.type}/${this.$route.params.id}`

			}
		},
		...mapState(useConfigStore, ['url', 'mapConfig', 'controls', 'user']),
		...mapState(useSurfStore, [
			'EL',
			'maxEL',
			'AAL',
			'maxAAL',
			'coordinates',
			'surfData',
			'FFE',
		]),
	}, 
	mounted() {	
		console.log(`${this.url}/surf/deterministic/${this.$route.params.type}/${this.$route.params.id}`)
	},
	watch: {
		'$route': {
			handler: function(result) {
				if ( result.name != 'login') {	
					this.setElevation(0)
					this.updateSurf() 
					this.updateAAL()
				}
				if(this.popup != null){
					this.popup.remove()
				}
			},
			deep: true,
		},
		firstFloorElevation: function(newElevation) {
			this.updatedFirstFloorElevation = newElevation + this.changeInElevation
		},
		coordinates: function(newValue) {

		},
		EL: {
			handler: function(result) {
				//console.log(result)
			},
			deep: true,
		},
	},
	methods: {
		handleControls(selection) {
			if (selection.label == 'view'){
				this.$router.push({name: 'economic', params: { view: selection.value, type: this.$route.params.type, id: this.$route.params.id}})
			}
			this.mapLayer = `${selection.dataSource}_${this.stormCategory.replace(/\D/g, '') + 'year'}_${selection.year}_mean`
		},
		handleZoom(newZoom) {
			//this.setZoom(newZoom)

		},
		handleChangeInElevation() {
			//this.updateEL()
		},
		...mapActions(useSurfStore, ['updateSurf', 'updateAAL', 'updateEL']),
		...mapActions(useConfigStore, ['setLocation', 'setZoom', 'setElevation']),	
	},
}
</script>

<!-- CSS -->
<style lang="scss" scoped>
@import "../style/style.scss";


#topbar {
	grid-area: topbar;
	position: fixed;	
	width: 100vw;
	//height: $topbar-height;
	z-index: 10;
	//top: $navbar-height;
	overflow: visible;
}

#values {
	grid-area: values; 
}

#mapbox {
	position: relative;
	height: 100%;
	grid-area: mapbox;
	width: 100%; 
}

/*

#mapbox {
	grid-area: mapbox;
	position: fixed;
	top: calc(#{$topbar-height} + #{$navbar-height});
	width: 50vw;
	height: calc(100vh - #{$topbar-height} - #{$navbar-height});
	z-index: 1;
}

*/
#footer { grid-area: footer; }

.grid {
	display: grid;
	grid-template-columns: 1fr; 
	grid-template-rows: $topbar-height 1fr $footer-height;
	grid-template-areas: 
		" topbar  "
		" mapbox  "
		" footer  ";
	width: 100%;
	height: calc(100vh - #{$navbar-height});
	background-color: #394149;
}



.grid {
	display: grid;
}

@media print {
	#mapbox {
		position: relative;
		height: 100%;
		grid-area: mapbox;
		width: 100%;  
	}

	#topbar {
		padding: 0px;
	}

	.grid {
		display: grid;
		max-width: 8.5in; 
		grid-template-columns: 8.5in;
		grid-template-rows: 0 auto $footer-height;
		grid-template-areas: 
		" mapbox " 
		" values "
		" footer "; 
	}

}

</style>
