<template>
	<div class='feedback-container'>
		<!--
		<btn 
			color='grey' 
			icon='message'
			title='Report a Bug'
			ref='editAttributes' 
			outlined 
			circular
			@click='isModalActive = true'
		> 
		Feedback
		</btn>
		-->
			
		<modal v-model='isModalActive'>
			<div class='bug-report'>	
				<div class='title'>
					<h6> {{ textContent['buttons']['send-feedback-btn']['label'] }} </h6>
				</div>
				<div class='description'>
					<o-field>
							<o-input class='input' v-model="message" type="textarea" :placeholder="this.textContent['buttons']['send-feedback-btn-placeholder']['label']" style="resize: none;"/>
					</o-field>
				</div>

				<div class='submit field'>
					<o-field horizontal>
						<btn 
							variant="arkly" 
							outlined
							width='auto'
							v-on:click="postBug"
						>
						{{	textContent['buttons']['submit-btn']['label'] }}
						</btn>
					</o-field>
				</div>
			</div>
		</modal>
		<modal v-model="feedbackSubmitted"><h6 class="submitted"> {{ textContent['buttons']['send-feedback-sent']['label'] }} </h6></modal>
	</div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import { mapState, mapGetters, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import { useSurfStore } from '@/store/surf'
import Button from '@/components/Button'
import Modal from '@/components/Modal'
import FormData from 'form-data'
import axios from 'axios'
import { getAuthToken } from '@/helpers/auth.js'
import { event } from 'vue-gtag'

export default {
	name: 'BugReport',
	components: {
		'inline-svg': InlineSvg,
		'btn': Button,
		'modal': Modal,
	},
	props: {
		modelValue: {
			type: Boolean,
		}
	},
	emits: ['update:modelValue'],
	data() {
		return {
			//isModalActive: false,
			message: '',
			errorMessage: '',
			feedbackSubmitted: false,
		}
	},
	mounted() {

	},
	methods: {
		closeModal() {
			this.isModalActive = false
		},
		postBug() {
			event('feedback_sent', {'event_category': 'bug_report', 'feedback_message': this.message, 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent('feedback','location ID: ' + window.location.href.split('/').slice(-1) + ', feedback message: ' + this.message)
			if (this.message) {
				this.reportBug(this.message)	
			} else {
				console.log('bug not report, because message is empty')
			}
			this.message = ''
			this.feedbackSubmitted = true
			this.isModalActive = false					
			this.$emit('reported')
		},
		...mapActions(useSurfStore, ['reportBug'])
	},
	computed: {
		placeHolderText() {
			return this.textContent['buttons']['send-feedback-btn-placeholder']['label'] 
		},
		isModalActive: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
		...mapState(useConfigStore, ['url', 'textContent'])
	}
}

</script>

<!-- CSS -->
<style lang="scss" scoped>
@import "../style/oruga.scss";
@import "../style/style.scss";
@import "../style/arkly.scss";

.submitted {
	width: 300px;
	padding: 20px;
}

.title {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 7px;
	font-size: 25px;
	color: black;
	line-height: 1;
	width: 100%;
	grid-area: title;
}


.modal {
	width: 100vw;
	height: 100vh;
	z-index: 99999;
	color: black;
	padding: 10px 20px 20px;
	border-radius: 5px;
	border: solid black 2px;
	background-color: white;
	line-height: 1;

}

.field-label {
	padding-top: 30px;
	color: var(--oruga-field-label-color, #363636);
	font-size: var(--oruga-base-font-size, 1rem);
	font-weight: var(--oruga-field-label-font-weight, 600);
}f

.field-label p {
	margin-top: 0;
	font-weight: normal;
}

.description {
	grid-area: description; 
}

.submit { 
	margin-top: 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	grid-area: submit; 
}

#screenshot-label { grid-area: screenshot-label; }

#description-label { grid-area: description-label; }

#close-tutorial-container { 
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
	grid-area: close; 
}

.defaultClose {
	display: none !important;
	color: transparent;
}

.file-upload {
	grid-area: screenshot;
	height: 100px;
	padding: 10px 0;
	justify-items: center;
}

.file-upload svg {
	height: 20px;
	width: 20px;
}

.file-upload > * * {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 0;
	background-color: #f7fafd;	
}

.field {
	display: flex;
	align-items: flex-end;
	background-color: white;
}

.bug-report {
	display: grid;
	grid-template-rows: 1fr 100px 100px;
	grid-template-columns:1fr 100px; 
	grid-gap: 20px;
	grid-template-areas: 
		" title			close       "
		" description   description "
		" submit        submit      ";
	width: 100%;
	height: auto;
	min-width: 350px;
	min-height: 200px;
	padding: 15px 20px;
	//background-color: white;
}

.feedback-container{ 
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}	

.feedback-text {
	margin: 0;
	//margin-top: 0px;
	font-size: 12px;
	font-family: $body-family;
	//margin-left: -5px;
}
@media only screen and (max-width: $mobile-breakpoint) {
	.feedback-text {
		//margin-top: 0px;
		font-size: 16px;
		font-family: $body-family;
		//margin-left: -5px;
		//padding-right: 5px;
	}
}

@media only screen and (max-width: 800px) {

	.bug-report {
		grid-template-rows: auto 1fr 1fr;
		grid-template-columns: 1fr 1fr;
		grid-template-areas: 
			" title       title "
			" description       description       "
			" screenshot        submit            ";
	}

}

@media only screen and (max-width: 650px) {
	.file-upload { display: none; }
	#screenshot-label { display: none; }

	.bug-report {
		grid-template-rows: auto 1fr 100px;
		grid-template-columns: 1fr 1fr;
		grid-template-areas: 
			" title       title "
			" description       description       "
			"   .               submit            ";
	}
}

</style>
