<template>
<loading-screen :isLoading="isLoading" variant="arkly" :slowInternet="slowInternet"/>
<modal v-model="goToPRModal">
	<div class='criticalAssetPopup'>
		<div>{{textContent['homepage']['slogan']['subheader']}}</div>
		<div class='criticalAssetButtons'>
			<btn variant="arkly" label="gotoarklypr" outlined size='medium' v-on:click="goToArklyPR">{{textContent['buttons']['btn-yes']['label']}}</btn>
			<btn variant="arkly" label="dontgotoarklypr" outlined size='medium' v-on:click="closeGoToPRModal">No</btn>
		</div>
	</div>
</modal>
<div 
	class="homepage-grid" 	
	:style="gridStyle"
	v-if="(['brand', 'buttons', 'homepage', 'footer']).every(key => key in textContent)"
>	
<Transition>
	<div v-if='scrolledDown' id="banner" >
		<inline-svg
				title='Arkly Logo'
				onclick="window.scrollTo(0, 0);"
				:src="require('@/assets/icons/arkly-logo-watermark-trademark.svg')"
				style="height:100%; position: absolute; top:0px ;width:40px; margin-right: calc(50vw - 20px); cursor: pointer;"
				/> 
		<div style="margin-right: 10px;">
			<div id='language-selector'>
				<language-selector />
			</div>
			<!--<settings v-if="$route.name == 'puertoRicoHome'" :style='settingsStyleBanner' onlyLanguage/>	-->
		</div>
	</div>
</Transition>
		<div class="map-cover" v-show="!showMap"></div>
		<div class='map' id='splash' ref='map'>  <!--:style="{'margin-top':isLoading ? '1000px' : '0px'}"-->
		<div id="map"></div>
		<map-control
			v-if="map != undefined"
			:map='map'
			position='top-right'
		>
		<div id='language-selector'>
			<language-selector />
		</div>

			<!--<settings  :style='settingsStyle' onlyLanguage/>-->
		</map-control>

		<div id='arkly-logo'>
			<inline-svg
				title='Arkly Logo'
				:src="require('@/assets/icons/arkly-logo-white-watermark-trademark.svg')"
				/> 
		</div>

		<div id='tagline'>
			<h1 v-html="textContent['homepage']['slogan']['header']"></h1>
			<!--<h1> {{ textContent['brand']['tagline'] }} </h1>-->
			<h5> {{ textContent['brand']['company_purpose'] }} </h5>
		</div>
		
		<div id='search'>
			<search
				id='search-bar'
				variant='alt'
				logo='none'
				color='primary'
				backgroundColor='white'
				size='large'
				placeholder='Look up your home address'
				style="width:35vw; min-width:270px"
			/>

			<btn 
				label="explore-arkly-btn"
				id='explore-arkly-btn'
				variant="arkly"
				size='large' 
				width='auto'
				@click='goToMap'
			> 
			{{ textContent['buttons']['explore-arkly-btn']['label'] }}	
			</btn>

			<div id="up-arrow" >
				<inline-svg
					title='Scroll Down'
					id="up-arrow-svg"
					:src="require('@/assets/icons/upArrow.svg')"
					@click="scrollDown"
					/> 
			</div>
		</div>

		
		<!--<div id='mask'>
			<inline-svg
				id='map-marker'
				title='map marker surround by circle'
				:src="require('@/assets/icons/marker-surrounded.svg')"
			/>
		</div>-->
	</div>
	
	<div class='section' id='mission'>
		<div class='subheader'>
			<div class='spacer-left' />
			<!--<Transition name="slide" mode="in-out">
				<div v-show="scrolledDown2">-->
					<h6> {{ textContent['homepage']['mission']['header'] }} </h6>
					<h3> {{ textContent['homepage']['mission']['subheader'] }}</h3>
				<!--</div>
			</Transition>-->
		</div>
		<div class='content'>
			<!--<Transition name="slide" mode="in-out">
				<div v-show="scrolledDown3">-->
					<h3 class="spacer-right"> {{ textContent['homepage']['mission']['content_header'] }} </h3>
				<!--</div>
			</Transition>-->
		</div>
	</div>

	<div id='tech'>
			<div class='subheader'>
				<h6> {{ textContent['homepage']['tech']['header'] }} </h6>
				<h5> {{ textContent['homepage']['tech']['subheader'] }} </h5>
				<p> {{ textContent['homepage']['mission']['tagline'] }} </p>
			</div>
			<div class='content'>
				<btn 
					label="take-a-tour-btn"
					id='take-a-tour-btn'
					size='large' 
					variant="arkly"
					style="padding-bottom: 20px;"
					outlined 
					width='auto' 
					@click='goToMap'
				> 
					{{ textContent['buttons']['take-a-tour-btn']['label'] }}
				</btn>
			</div>
			<div class='image'>
				<img :src="require('@/assets/images/computer-screen.jpg')" />
			</div>
	</div>

	<div class='section' id='solutions' >
		<div class='subheader' style="min-height: 70vh; justify-content: flex-end; padding-bottom: 80px;">
			<h6> {{ textContent['homepage']['solutions']['header'] }} </h6>
			<h3> {{ textContent['homepage']['solutions']['subheader'] }} </h3>
		</div>

		<div class='content' style="background-color: white;" v-if="$route.name != 'puertoRicoHome'">
			<div>
				<h6> {{ textContent['homepage']['solutions']['content_header'] }}  </h6>
				<h5> {{ textContent['homepage']['solutions']['content_body'] }} </h5>
				<!--<btn variant='learn-more' color='black' width='auto' backgroundColor='transparent' size='small' @click="openPanel('flood-risk')"> {{ textContent['buttons']['learn-more-btn']['label'] }} >> </btn>-->
			</div>

			<div class='action'>
				<btn size='large' variant="arkly" label="goToRocketFlood" outlined width='auto' @click="goToRocketFlood('home')"> 
				{{ textContent['buttons']['get-quote-btn']['label'] }}
				</btn>	
			</div>
		</div>

		<div class='image' v-if="$route.name != 'puertoRicoHome'"> 
			<img :src="require('@/assets/images/beach-house.jpg')" />
		</div>
	</div>

	<div id='expert'>			
		<div class='subheader'>
			<h6> {{ textContent['homepage']['flood-mitigation']['subheader'] }} </h6>
			<h5> {{ textContent['homepage']['flood-mitigation']['content_body'] }} </h5>
		</div>

		<div class='content'>
			<span>
				<!--<btn variant='learn-more' color='black' width='auto' backgroundColor='transparent' size='small' @click="openPanel('lift-house')"> {{ textContent['buttons']['learn-more-btn']['label'] }}  >> </btn>-->
			</span>
			<expert-consult style="padding-bottom: 20px;" label='expert-consult' size='large' outlined width='auto' :title="textContent['buttons']['expert-consult']['label']" consultType='mitigation'/>
		</div>	

		<div class='image'>
			<img :src="require('@/assets/images/expert.jpg')" />
		</div>
	</div>

	<div class='section' id='about'>
		<!--<div id='brands'>
			<div class='brand'>
				<inline-svg
					title='HighTide AI'
					:src="require('@/assets/icons/blue-logo-with-pelican.svg')"
				/>
			</div>
			<div class='brand'>
				<inline-svg
					title='B Corp Certified'
					:src="require('@/assets/icons/b-corp-certified.svg')"
				/>
			</div>			
		</div>-->

		
		<div class="subheader">
			<h6> {{ textContent['homepage']['about']['content_header'] }} </h6>
			<h5 style="text-align: right;"> {{ textContent['homepage']['about']['content_body'] }} </h5>
		</div>
		<div class='content'>
			
			<btn style="padding-bottom: 20px;" variant="arkly" label='meetTheTeam' outlined size='large' width='auto' @click='meetTheTeam'> 
				{{ textContent['buttons']['meet-the-team-btn']['label'] }} 	
			</btn>
		</div>
		<div class='image'>
			<img :src="require('@/assets/images/fort.jpg')" />
		</div>
		

	</div>

	<not-found class='not-found-modal' />
	<foot v-if="'footer' in textContent" variant='arklyHome' style="z-index: 6;"> 

	</foot>

	<not-found class='not-found-modal' />
</div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import { mapActions } from 'pinia'
import { mapState } from 'pinia'
import { useConfigStore } from '@/store/config'
import Mapbox from 'mapbox-gl'
import Settings from '@/components/arkly/Settings'
import MapControl from '@/components/map/Control'
import Footer from '@/components/sidepanel/Footer'
import LoadingScreen from '@/components/LoadingScreen'
import Card from '@/components/Card'
import Search from '@/components/Search'
import Button from '@/components/Button'
import ExpertConsult from '@/components/arkly/ExpertConsult'
import NotFound from '@/components/NotFound'
import { event } from 'vue-gtag'
import Modal from '@/components/Modal'
import LanguageSelector from '@/components/arkly/LanguageSelector'

export default {
	name: 'Home',
	components: {
		'loading-screen' : LoadingScreen,
		'inline-svg': InlineSvg,
		'foot': Footer,
		'card': Card,
		'search': Search,
		'btn': Button,
		'foot': Footer,
		'expert-consult': ExpertConsult,
		'not-found': NotFound,
		'map-control': MapControl,
		'settings': Settings,
		'language-selector': LanguageSelector,	
		'modal': Modal,
	},
	data() {
		return {
			//backgroundImage: 'venice-beach-rodnae-productions.jpeg',
			imageDimensions: { 'width' : undefined, 'height' : undefined },
			windowDimensions: { 'width' : window.innerWidth, 'height' : window.innerHeight, 'scale': window.innerWidth/window.innerHeight},
			minx: 576,
			maxx: 1035,
			randomLocation: {
				id: undefined,
				view: undefined,
			},
			zoomHeight: undefined,
			zoomWidth: undefined,
			userLocation: undefined,
			map: undefined,
			exploreId: undefined,
			layerSelector: undefined,
			currentLayer: undefined,
			scrolledDown: false,
			scrolledDown2: false,
			scrolledDown3: false,
			scrolledDown4: false,
			showMap: true,
			upArrowOffset: undefined,
			isLoading: true,
			timesCalled: 0,
			slowInternet: false,
			isVisible: false
		}
	},
	mounted() {

		this.moveElementCSS('35','-5','.mapboxgl-ctrl-logo','bottom')
		this.moveElementCSS('95','-5','.mapboxgl-ctrl-logo','bottom')
		this.moveElementCSS('0','20','.mapboxgl-ctrl-bottom-right','bottom')
		this.moveElementCSS('0','-10','.mapboxgl-ctrl-top-right','top')
		if (!navigator.userAgent.includes('Chrome')) {
			this.upArrowOffset = true
		} 
		/*
		var coordinates = undefined
		if (navigator.geolocation) {
				console.log(navigator)
				navigator.geolocation.getCurrentPosition(function(position) {
					coordinates = [position.coords.latitude, position.coords.longitude];
			});
		}
		console.log(coordinates)
		console.log(this.userIP)
		if (this.userIP){
			this.getUserLocation(this.userIP)
		}*/

		//if (this.textContent['buttons'] && this.textContent['homepage'] && this.textContent['brand']) {
		let idArray, routeName, locationArray, randomNumber
		if (this.$route.name == 'puertoRicoHome') {
			locationArray = [
				[-67.155054, 18.428108],
				[-65.323043, 18.319481],
				[-67.192527, 18.07528],
				[-67.183177, 18.012499],
				[-67.163177, 18.202499]
			]
			idArray = [
				'Calle-Ramón-Emeterio-Betances_Aguadilla_Aguadilla_PR_7',
				'Carretera-Principal-de-Culebra_Culebra_Culebra_PR_19',
				'Avenida-Juan-A.-Acarón-Correa_Puerto-Real_Cabo-Rojo_PR_135',
				'Calle-Jose-de-Diego_Boquerón_Cabo-Rojo_PR_2',
				'Calle-Quintita_Mayagüez_Mayagüez_PR_2'

			]
			this.randomLocation.view = 'puertoRico'

			routeName = 'puertoRico'
			this.layerSelector = '-pr-'

		} else {
			locationArray = [
				[-82.825126,27.991469],
				[-80.1392,25.81868],
				[-82.846197, 27.890565],
				[-80.176066, 25.693697],
				[-81.841895, 26.32915]
			]
			idArray = [
				'28-Mango-St_Clearwater_Pinellas_FL',
				'4240-Royal-Palm-Ave_Miami-Beach_Miami-Dade_FL',
				'322-La-Hacienda-Dr_Indian-Rocks-Beach_Pinellas_FL',
				'401-Warren-Ln_Key-Biscayne_Miami-Dade_FL',
				'5320-Barefoot-Bay-Ct_Naples_Collier_FL',
				//'7014-Ramoth-Dr_Jacksonville_FL'
			]
			this.randomLocation.view = 'arkly'
			
			routeName = 'arkly'
			this.layerSelector = 'fl-'
		}
		randomNumber = Math.round(Math.random()*(idArray.length-1))
		this.randomLocation.id = idArray[randomNumber]
		this.exploreId = idArray[randomNumber]
		this.setFavicon()
		this.setTitle()
		// Get Dimensions of Image
		let image = new Image()
		image.onload = () =>  {
			this.imageDimensions = {
				'width': image.width, 
				'height': image.height,
				'scale': image.width/image.height,
			}
		}
		//image.src = require('../assets/images/' + this.backgroundImage)

		// Get Dimensions of Page
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
			window.addEventListener("scroll", this.onScroll);
		})
		Mapbox.accessToken = this.mapConfig.accessToken
		setTimeout(() => {
			const map = (window.map = new Mapbox.Map({
				container: 'map', 
				style: 'mapbox://styles/hightideintelligence/cloenivrh002u01pde2qpadb3',
				center: locationArray[randomNumber], 
				//center: [-67.163177, 18.202499],
				zoom: 13.6, 
				antialias: true,
				attributionControl: false,
				preserveDrawingBuffer: true,
			}))
			map.scrollZoom.disable();
			map.doubleClickZoom.disable();
			
			map.on('load', () => {
				this.mapConfig.homepageLayers.forEach(layer => {
					if (layer.includes(this.layerSelector)){
						this.currentLayer = layer
						this.addMapboxLayer(layer, map, this.mapConfig.layerToInsertUnder)
						map.setPaintProperty(layer, 'fill-color', ["interpolate",["linear"],[  "get",  "abk"],0,"#ffffff",500,"#fff5f0",1000,"#fcbba1",10000,"#fb6a4a",100000,"#cb181d",500000,"#67000d"])
						map.setPaintProperty(layer, 'fill-opacity', ['match', ['get', 'damageInLastYear'], 0, 0.3, 1])
					}
				})
				this.map = map
				map.addControl(new Mapbox.AttributionControl({'compact':true}), 'bottom-right');
				map.scrollZoom.disable();
				map.doubleClickZoom.disable();
			})
			var t = setInterval(this.sourceCallback,100);
			this.runningFunction = t
			
		}, 200)
	},
	beforeDestroy() { 
		window.removeEventListener('resize', this.onResize); 
  		window.removeEventListener("scroll", this.onScroll)
	},
	created() {

		window.addEventListener("resize", this.getSizes, false);
	},
	computed: {
		settingsStyle() {
			//console.log(this.isMobile)
			if (this.isMobile) {
				return {position: 'fixed', top: '-40px', right: '0'}
			} else {
				return {position: 'relative'}
			}
		},
		settingsStylBanner() {
			return {position: 'relative'}
		},
		watchZoom() {
			return this.zoomWidth + ", " + this.zoomHeight
		},
		//imageURL() {
		//	return `url(${require('../assets/images/' + this.backgroundImage)})`
		//},
		gridStyle() {
			if (!this.imageDimensions.height) {
				return undefined
			} else {
				/*
				// If image height is fullsize and width is cropped
				let ratio = this.windowDimensions.height/this.imageDimensions.height
				let visibleHeight = this.imageDimensions.height
				let distanceFromTop = this.minx*ratio
				let distanceFromBottom = this.maxx*ratio
				let rows = `${distanceFromTop}px 1fr ${distanceFromBottom}px` 
				
				// If image width is full size and height is cropped
				if (this.windowDimensions.scale > this.imageDimensions.scale) {
					const currentImageHeight = (this.windowDimensions.width/this.imageDimensions.scale)
					const middleSectionHeight = ((this.imageDimensions.height - this.maxx - this.minx)*(currentImageHeight/this.imageDimensions.height))
					const offset = (this.maxx - (this.imageDimensions.height/2))*(currentImageHeight/this.imageDimensions.height)
					distanceFromTop = (this.windowDimensions.height/2) - offset - middleSectionHeight
					rows = `${distanceFromTop}px ${middleSectionHeight}px 1fr`
				} 
				return { 
					'background-image': this.imageURL,
					'grid-template-rows': rows
				}
				return
				*/
			}
		},
		...mapState(useConfigStore, ['goToPRModal','isDevOrLocal','mapConfig','textContent', 'userIP', 'setTutorialVisibility', 'isMobile']),
	},
	destroyed(){
		this.map.remove()
	},
	methods: {
		goToArklyPR(){
			this.closeGoToPRModal()
			if (this.$route.name == 'arkly') {
				this.$router.push({ 
					name: 'puertoRico', 
					params: {
						id: encodeURIComponent(this.$route.params.id),
					}
				})
			} else if (this.$route.name == 'home') {
				this.$router.push({name: 'puertoRicoHome'})
			}	
			setTimeout(() => {
				location.reload()
			},200)
			
		},
		sourceCallback() {
		// assuming 'map' is defined globally, or you can use 'this'
			this.timesCalled = this.timesCalled + 1
				if (this.timesCalled > 100) {
					this.slowInternet = true
				}
			if (this.map){
				if (this.map.getSource(this.currentLayer) && this.map.isSourceLoaded(this.currentLayer)) {
					clearInterval(this.runningFunction)
					this.isLoading = false
				}
			}
		},
		scrollDown(){
			if (this.isMobile) {
				window.scrollBy({top: window.outerHeight,left: 0,behavior: "smooth"});
			} else {
				window.scrollBy({top: window.innerHeight,left: 0,behavior: "smooth"});
			}
		},
		onResize() {
			this.windowDimensions.height = window.innerHeight
			this.windowDimensions.width = window.innerWidth
		},
		onScroll() {
			if (window.top.scrollY >= this.windowDimensions.height*1.2){
				this.showMap = false
			} else {
				this.showMap = true
			}
			if (window.top.scrollY >= this.windowDimensions.height){
				event('homepage_scroll_down', {'event_category': 'homepage'})
				confection.submitEvent('homepage_scroll_down','location ID: homepage')
				if (!this.scrolledDown) {
					this.scrolledDown = true

				}
			} else {
				if (this.scrolledDown) {
					event('homepage_scroll_up', {'event_category': 'homepage'})
					confection.submitEvent('homepage_scroll_up','location ID: homepage')
					this.scrolledDown = false	
				}
			}
			/*
			if (window.top.scrollY >= this.windowDimensions.height/3){
				event('homepage_scroll_down', {'event_category': 'homepage'})
				confection.submitEvent('homepage_scroll_down','location ID: homepage')
				if (!this.scrolledDown2) {
					this.scrolledDown2 = true

				}
			}
			if (window.top.scrollY >= this.windowDimensions.height){
				event('homepage_scroll_down', {'event_category': 'homepage'})
				confection.submitEvent('homepage_scroll_down','location ID: homepage')
				if (!this.scrolledDown3) {
					this.scrolledDown3 = true

				}
			}
			if (window.top.scrollY >= this.windowDimensions.height*1.2){
				event('homepage_scroll_down', {'event_category': 'homepage'})
				confection.submitEvent('homepage_scroll_down','location ID: homepage')
				if (!this.scrolledDown4) {
					this.scrolledDown4 = true

				}
			}
			*/
		},
		initializeMap() {
			
		},
		openPanel(view) {
			this.setTutorialVisibility(false)
			this.setSidePanelView(view)
			this.$router.push({ 
				name: 'arkly', 
				params: { 'id': '28-Mango-St_Clearwater_FL', 'type': 'property'
			}})
		},
		getSizes() {
			let body = document.body
	        this.zoomWidth = body.clientWidth + 'px'
	        this.zoomHeight = body.clientHeight + 'px'
		},
		goToMap() {
			let routeName
			if (this.$route.name == 'puertoRicoHome') {
				routeName = 'puertoRico'

			} else {
				routeName = 'arkly'
			}
			event('explore_Arkly_click', {'event_category': 'homepage'})
			confection.submitEvent('explore_arkly_click','location ID: homepage')
			this.$router.push({ 
				name: routeName, 
				params: { 'id': encodeURIComponent(this.exploreId), 'type': 'property', 'priorURL': 'home'
			}})
		},
		...mapActions(useConfigStore, ['closeGoToPRModal','setTitle','setFavicon','moveElementCSS','addMapboxLayer','setTextContent', 'goToTerms','meetTheTeam','goToPrivacy','goToRocketFlood', 'scheduleExpertMeeting', 'startTour', 'setSidePanelView']),
	},
}

</script>

<!-- CSS -->
<style lang="scss" scoped>
@import "../style/oruga.scss";
@import "../style/arkly.scss";

#language-selector {
	width: 100%;
	margin: 10px;
	border-radius: 5px;
	background-color: white;
}

.map-cover {
	height: 100vh;
	width: 100vw;
	position: fixed !important;
	background-color: white;
	top: 0px;
	left: 0px;
	z-index:5;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.slide-enter-active {
  transition: 1s;
}

.slide-enter-from, .slide-leave-to {
  //transform: translateX(20px);
  transform: translate(-10%, 0);
  opacity: 0;
}

#arkly-logo {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	width: auto;
	height: 100%;
	max-width: 100%;
	max-height: 100%;
	color: white;
	grid-area: logo;
	overflow: hidden;
	z-index: 3 !important;
}

#arkly-logo svg {
	height: 100%;
	width: auto;
}

#brands {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	height: 100%;
	width: 100%;
	grid-area: brands;
}

.brand {
	height: min-content;
	width: min-content;
}

.brand svg {
	padding: $padding-medium;
	height: 150px;
	width: auto;
	color: black;
}

#tagline {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	text-align: left;
	padding: 20px;
	grid-area: tagline;
	height: 100%;
	max-height: 100%;
	z-index: 1 !important;
}

#tagline h1 {
	width: min-intrinsic;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    display: table-caption;
    display: -ms-grid;
    -ms-grid-columns: min-content;
	width: 100%;
}

#search {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 20px;
	font-size: 18px;
	height: 100%;
	width: 100%;
	max-width: 1000px;
	padding: 40px; 
	background-color: transparent;
	grid-area: search;
	z-index: 3 !important;
}

#search #search-bar {
	font-family: 'New Order', sans-serif;
	background-color: transparent;
	z-index: 99;
}

#mask {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	//top: calc(50vh - ((50vw - 200px)/2));
	//left: 50vw;
	//width: calc(50vw - 200px);
	//height: calc(50vw - 200px);
	//border-radius: calc(50vw - 200px);
	//box-shadow: 0 0 0 400vw rgba(0, 0, 0, 0.7);	
	z-index: 1 !important;
	grid-area: mask;
}

#mask > svg {
	width: 80%;
	height: 80%;
}
/*
#up-arrow {
	//background-image: url("../assets/icons/upArrow.svg");
	//background-position: center;
	//background-repeat: no-repeat;
	//background-size: contain;
	height: 40px;
	width: 100%;
	vertical-align: middle;
	position: fixed;
	bottom: 0px;
	color: white;
	margin: auto;
}*/

#up-arrow-svg {
	position: fixed;
    bottom: 0px;
    width: 100%;
    left: 0px;
    height: 40px;
    margin-right: calc(50vw - 20px);
    vertical-align: middle;
    position: fixed;

}

#up-arrow-svg:hover {
	cursor: pointer;
	height: 50px;
}

#splash {
	position: fixed !important;
	top:0px; 
	left:0px; 
	height: 100vh;
	display: grid;
	grid-template-columns: 3fr 2fr;
	grid-template-rows: 1fr 2fr 1fr;
	grid-template-areas: 
		" logo    .    "
		" tagline mask "
		" search  .    ";
	width: 100%;	
	//gap: 20px;
	font-family: 'New Order', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 22.9091px;
	line-height: 27px;
	color: white;
	padding: 40px;
	//background-image: url('../assets/images/map-background.jpg');
	//background-size: cover;
	overflow: visible;
	grid-area: splash;
}

#splash * {
	z-index: 2;
}

#splash #map {
	z-index: -2 !important;
	width: 100vw;
    height: 100vh !important;
    position: absolute;
}

@media only screen and (max-height: 850px) {

	//Necessary to allow the map buttons to be clicked on
	#arkly-logo {
		margin-right: 200px;
	}

	#arkly-logo svg {
		//height: 10vh;
		//width: 400px;	
	}

	#splash h1 {
		font-size: 8vh ;
	}

}

.subheader {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: inherit;
	padding: $padding-medium;
	grid-area: subheader;
}

.section .content > * {
	display: flex;
	flex-direction: inherit;
	justify-content: inherit;
	align-items: inherit;
}

.action {
	display: flex;
	flex-direction: column;
	justify-content: inherit;
	gap: $padding;
	align-items: inherit;
}

.content {
	display: flex;
	flex-direction: column;
	justify-content: inherit;
	align-items: inherit;
	height: 100%;
	gap: $padding;
	padding-left: $padding-medium;
	padding-right: $padding-medium;
	grid-area: content;	
}


.image {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	//height: 100%;
	max-width: 100%;
	grid-area: image;
}

.image img {
	max-width: inherit;
	width: 100%;
}

#banner {
	background-color: white;
    position: fixed;
    top: 0px;
    z-index: 9;
    display: flex;
    justify-content: flex-end;
    width: 100%;
	height: 60px;
	//background: linear-gradient(180deg, rgba(255, 255, 255, 1) 80%,rgba(255,255,255,0) 90%);
	//padding-bottom: 10px;
	
	
}

#mission {
	background-color: #f4f3f1;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 1fr;
	grid-template-areas: 
		" subheader "
		" content   ";
	justify-content: end;
	align-items: start;
	min-height: 100vh;
	margin-top: 100vh;
}

#mission > .subheader {
	text-align: left;
}

#mission > .content {
	text-align: right;
}

#tech {
	z-index: 6;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr auto auto;
	grid-template-areas: 
	//	" .         .         "
		" subheader image     "
		" content   image     ";
	align-items: flex-start;
	text-align: left;
	justify-content: flex-end;
	padding-left: 0;
	background-color: white;
	width: 100%
	//padding-top: 300px;
}

#solutions {
	//padding-bottom: 80px;
	z-index: 6;
	background-color: #f4f3f1;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto 1fr;
	grid-template-areas: 
		" subheader subheader "
		" image     content   ";
	align-items: flex-end;
	text-align: right;
	justify-content: space-around;
	padding-left: 0;	
	//padding-top: 300px;
	//min-height: 1000px;
}

#expert {
	z-index: 6;
	width: 100%;
	background-color: white;
	display: grid;
	grid-template-columns: 4fr 4fr;
	grid-template-rows:  1fr 1fr;
	grid-template-areas:
	//	" .         .         "
		" subheader image     "
		" content   image     ";
	align-items: flex-start;
	text-align: left;
	justify-content: space-between;
	//padding-top: 300px;
}

#about {
	z-index: 6;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;
	grid-template-areas: 
		" image subheader      "
		" image content        ";
	//background-image: url('../assets/images/fort.jpg');
	//background-repeat:  no-repeat;
	//background-position: bottom;
	//background-size: cover;
	justify-content: flex-start;
	align-items: flex-end;
	//padding-bottom: 80px;
	background-color: #f4f3f1;
}

#footer {
	width: 100vw;
	height: 100vh;
}

.section {
	background-color: white;
	width: 100vw;
	//min-height: 100vh;
	height: auto;
	//padding-bottom: 10vh;
	//padding: $padding-medium;
	background-color: white;	
	z-index: 6;
}


#terms-of-use {
	position: absolute;
	bottom: 10px;
	display: flex;
	height: auto;
	width: 100vw;
	justify-content: center;
	gap: 10px;
	align-items: center;
}

.homepage-grid {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: auto;
	width: 100vw;
	max-width: 100vw;
	overflow: clip;
	background-position: center;
	background-size: cover;
	box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.3);
}


@media only screen and (max-height: $mobile-height-breakpoint) {

#arkly-logo {
	//height: auto;
	//width: 80vw;
	//padding-top: 20px;
	min-height: 80px;
	min-width: 80px;
	max-height: 122px
}

#arkly-logo svg {
	//height: auto;
	//width: 80vw;
	//padding-top: 20px;
	height: 100%;
}
#splash {
	position: relative;
	top: 0;
	left: 0;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 1fr 10fr;
	grid-template-areas:
	"logo    " 
	"tagline " 
	"search  ";
	padding: $padding;
}
}

@media only screen and (max-width: $mobile-breakpoint) {

	#tagline h1{
		font-size: 36px;
	}
	#tagline h5{
		font-size: 26px;
	}
	#arkly-logo {
		//height: auto;
		//width: 80vw;
		//padding-top: 20px;
		min-height: 80px;
		min-width: 80px;
		max-height: 122px
	}

	#arkly-logo svg {
		//height: auto;
		//width: 80vw;
		//padding-top: 20px;
		height: 100%;
	}

	#search{
		padding-top: 0px;
		padding-bottom: 100px;
		z-index: 10;
	}

	#brands {
		display: flex;
		flex-direction: row;
		width: 100%;
		max-width: 100%;
		gap: 10px;
	}

	.brand {
		height: 100%;
		width: auto;
	}

	.brand svg {
		padding: 0;
		height: auto;
		max-width: 100%;
		max-height: 100px;
		width: auto;
		color: black;
	}

	#mask {
		top: 20vh;
		left: -30vw;
		width: calc(50vw + 300px);
		height: calc(50vw + 300px);
		border-radius: calc(50vw + 300px);
	}

	#mask * {
		display: none;
	}

	#splash {
		position: relative;
		top: 0;
		left: 0;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr 10fr;
		grid-template-areas:
		"logo    " 
		"tagline " 
		"search  ";
		padding: $padding;
	}

	.section {
		height: auto;
		min-height: 100vh;
	}

	.image {
		//width: 90%;
	}

	#tech {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr auto;
		grid-template-areas: 
		" image     "
		" subheader "
		" content   ";
		align-items: flex-start;
		text-align: left;
		justify-content: flex-end;
	}

	#solutions {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: auto 1fr 2fr;
		grid-template-areas: 
		" subheader "	
		" image     "
		" content   ";
		gap: 20px;
		min-height: 900px;	
	}

	#expert {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr auto;
		grid-template-areas:
		" image     "
		" subheader "
		" content   ";
	}

	#about {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr auto 1fr;
		grid-template-areas: 
		" subheader "
		" content   "
		" image     ";
		grid-gap: 40px;
		padding-top: 0;
		//background-image: url('../assets/images/fort.jpg');
		//background-repeat:  no-repeat;
		//background-position: bottom;
		//background-size: cover;

	}

	#brands {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	#brands .brand {
		max-width: 200px;
	}
}

@media only screen and (max-width: $mobile-breakpoint) {
	.section {
		background-size: 400px auto !important;
	}

	#about {
		background-size: cover !important;
	}

}

</style>
