<template>
  <div v-if='currentLanguage' class='language-selector'>
    <v-select
		class='selector'
		v-model='currentLanguage'
		:reduce='(lang) => lang.code'
		label='language'
		:options='options'
		:clearable='false'
		:searchable='false'
		appendToBody

	>
		<template v-slot:selected-option='selected'>
			<div class='option-container'>
				<inline-svg 
					v-if="$route.name.startsWith('puertoRico')"
					:src="require(`@/assets/icons/flags/USA/${selected.icon}.svg`)" 
					class='icon' 
				/>
				<p class='v-center'> {{ selected.language }} </p>
			</div>
		</template>
		<template v-slot:option="option" >
			<div class='option-container'>
				<inline-svg 
					v-if="$route.name.startsWith('puertoRico')"
					:src="require(`@/assets/icons/flags/USA/${option.icon}.svg`)" 
					class='icon' 
				/>
				<p class='v-center'> {{ option.language }} </p>
			</div>
		</template>
	</v-select>
  </div>
</template>

<script>
import { reactive, computed } from 'vue'
import { mapState, mapGetters, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import InlineSvg from 'vue-inline-svg'

export default {
	name: 'LanguageSelector',
	created() {
		this.currentLanguage = this.userLanguage
	},
	components: {
		'inline-svg': InlineSvg,
	},
	props: {

	},
	data() { 
		return {
			currentLanguage: 'en-US',
			options: [
				{ 
					language: 'English', 
					code: 'en-US',
					icon: 'united-states'
				}, 
				{ 
					language: 'Español', 
					code: 'es-ES',
					icon: 'puerto-rico',
				}
			], 
			placement: 'bottom' 
		}
	},
	computed: {
		...mapState(useConfigStore, ['userLanguage']),
	},
	methods: {
		...mapActions(useConfigStore, ['setUserLanguage'])
	},
	watch: {
		// Sync with store/config
		userLanguage(newLang) {
			this.currentLanguage = newLang
		},
		currentLanguage(newLang) {
			//if (newLang == 'es-ES') {
			//	this.options[0].language = 'Inglés'
			//	this.options[1].language = 'Español'
			//} else {
			//	this.options[0].language = 'English'
			//	this.options[1].language = 'Spanish'
			//}
			this.setUserLanguage(newLang)
		},
	}
}
</script>

<style lang='scss'>
@import 'vue-select/dist/vue-select.css';

.language-selector {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	
}

.selector {
	color: black;
	font-size: 16px;
	width: fit-content;
	min-width: 160px;
}

.option-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;
}

.option-container > .icon {
	width: 30px;
	height: auto;
}

.v-select.drop-up.vs--open .vs__dropdown-toggle {
	border-radius: 0 0 4px 4px;
	border-top-color: transparent;
	border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement='top'] {
	border-radius: 4px 4px 0 0;
	border-top-style: solid;
	border-bottom-style: none;
	box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}
</style>

