<template>
	<div style="position:relative;margin-top: 10vh;width:100%">
	<inline-svg
                title='Arkly Pro Logo'
                :src="require('@/assets/icons/ArklyProLogo.svg')"
                style="width:200px;height:250px;"
            /> 
		</div>
    <div>
        <div class="input-group">

			<o-field 
				:variant='passwordStyle.variant'   
				horizontal 
				label="Username" 
			>
				<o-input class='input' v-model="username" maxlength="30" />
			</o-field>

			<o-field  
				horizontal 
				:variant='passwordStyle.variant'  
				label="Password"	
			>
				<o-input class='input' type="password" v-model="password" maxlength="30" v-on:keyup.enter="login" passwordReveal />
			</o-field>

			<o-field
				:variant='passwordStyle.variant'
				:message='passwordStyle.message'
				style="display: flex;flex-direction: column-reverse; gap:10px;"
			>
			  <o-button variant="primary" v-on:click="login">
				Login
			  </o-button>
			</o-field>
			<inline-svg
                v-if="variant == 'hightide'"
                title='HighTide Logo'
                :src="require('@/assets/icons/blue-logo-with-pelican.svg')"
                style="width:200px;height:100px"
            /> 

			<h2 v-if='!hasAccess'> Sorry You Do Not Have Access to this Version of the Site </h2>

        </div>
    </div>
</template>

<script>

import axios from 'axios'
import { loginUser, getUserInfo } from '@/helpers/auth.js'
import { mapState, mapGetters, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import { event } from 'vue-gtag'

export default {
    name: 'login',
    data() {
        return {
            username: '',
            password: '',
			passwordStyle: {
				'variant': 'primary',
				'message': ''
			},
			usernameStyle: {
				'variant': 'primary',
				'message': ''
			},
			hasAccess: true,
        }
    },
	created() {
		this.setTitle()
		this.setFavicon()
	}, 
	computed: {
		...mapGetters(useConfigStore, ['user']),
	},
	methods: {
		async login() {
			try {
				this.hasAccess = true
				if (this.username == '' && this.password == ''){
					await loginUser(' ', ' ')
				} else if (this.username == '') {
					await loginUser(' ', this.password)
				} else if (this.password == '') {
					await loginUser(this.username, ' ')
				} else {
					await loginUser(this.username, this.password)
				}
				const redirect = router.currentRoute.value.query
				this.$gtag.config({  user_id: this.user['_id'] })
				this.$gtag.set({ 'user_id': this.user['_id'] })
				this.$gtag.set('user_properties', { 
					'_id': this.user['_id']
				})

				if (
						(this.user.access.role.arkly || !location.hostname.includes('arkly')) 

						&& 

						(this.user.access.role.dev || !location.hostname.includes('dev.'))

					) { 

					if (redirect.name == 'arkly') {
							router.push({
								name: 'arkly', 
								params: {
									id: redirect.id ? redirect.id : this.user.address,
								}
							})
					} else {
						if (this.user.access.role.govSub) {
							event('good_login', {'user_name': this.username, 'event_category': 'login_page'})
							confection.submitEvent('good_login','user_name: ' + this.username + ', location ID: login_page')
							router.push({
								name: redirect.name ? redirect.name : 'result', 
								params: {
									view: redirect.view ? redirect.view : 'economic',
									id: redirect.id ? redirect.id : this.user.address, 
									type: redirect.type ? redirect.type : 'property',
								}
							})
						} else {
							event('bad_login_access', {'user_name': this.username, 'event_category': 'login_page'})
							confection.submitEvent('bad_login_access','user_name: ' + this.username + ', location ID: login_page')
							this.hasAccess = false
							throw 'LOGIN_BAD_CREDENTIALS'
						}
					}
				} else {
					event('bad_login_access', {'user_name': this.username, 'event_category': 'login_page'})
					confection.submitEvent('bad_login_access','user_name: ' + this.username + ', location ID: login_page')
					this.hasAccess = false
					throw 'LOGIN_BAD_CREDENTIALS'
				}
			}
			catch (error) {
				event('bad_login_creds', {'user_name': this.username, 'event_category': 'login_page'})
				confection.submitEvent('bad_login_creds','user_name: ' + this.username + ', location ID: login_page')
				console.log(error)
				if (error == 'LOGIN_BAD_CREDENTIALS'){
					this.passwordStyle = {
						'variant': 'error',
						'message': 'Password or Email is Incorrect'
					}
				}  else {
					alert(`Error: ${error}`)
				}
			} 
		},
		...mapActions(useConfigStore, ['setTitle','setFavicon','escapeRegExp','replaceAll','svgToDataUri']),
	},
	watch: {
		password: function(newValue){
		},
	},
}

</script>

<style>

    .input-group {
		display: flex;
		gap: 10px;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;
		margin-top: 10px;
		width: 100%;
    }

	.input-group * {
		font-family: 'New Order' sans-serif;
    }

	.input-group .o-field {
		display: flex;
		align-items: center;
	}

    .input-group label {
        margin-right: 0.5rem;
    }

	.input-group .input {
		width: 80vw;
        max-width: 300px;
    }

	.o-field__horizontal-body > .o-field__message-error {
		display: none;
	}

	.o-input__icon-right {
		right: 5px;
	}

	@media only screen and (max-width: 800px) {

		.input-group .input {
			width: 60vw;
		}
	
		.o-input__icon-right {
			right: 5px;
		}
	}

</style>

