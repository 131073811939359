<template>
	<div class='expert-consult-container' :style="{'width': width}">

	<btn 
		label="show-expert-consult"
		variant='arkly' 
		:size='size' 
		:outlined='outlined' 
		:width='width' 
		:icon='icon'
		@click='isModalActive = true'
	> 
		<span v-if='title' style="white-space: pre-wrap;"> {{ title }} </span>
	</btn>

	<modal v-model='isModalActive'>
		<div class='expert-modal-content'>

			<div class='text' v-if="consultType == 'risk'">
				<span v-html="textContent['property_view']['flood-risk-expert']['text']">
					
				</span>

				<btn variant='arkly' label="pay-now" outlined width='auto' @click="scheduleExpertMeeting('risk-expert')"> 
					{{ textContent['buttons']['expert-buy']['label'] }}
				</btn>
			</div>

			<div class='modal-image risk-image' v-if="consultType == 'risk'">
				<img title='House at Risk' :src="require('/src/assets/images/expertConsult/vulnerableHouse.jpg')" alt='House at Risk' />
			</div>

			<div class='modal-image risk-mitigation' v-if="consultType == 'mitigation' || consultType == 'suggestedMitigation'">
				<img title='Elevated House' :src="require('/src/assets/images/expertConsult/elevatedHouse.jpg')" alt='Elevated House' />
			</div>

			<div class='text' v-if="consultType == 'mitigation'">
				<span v-html="textContent['property_view']['mitigation-expert']['text']">
					
				</span>

				<btn variant='arkly' label="pay-now2" outlined width='auto' @click="scheduleExpertMeeting('mitigation-expert')"> 
					{{ textContent['buttons']['expert-buy']['label'] }}
				</btn>	
			</div>

			<div class='text' v-if="consultType == 'suggestedMitigation'">
				<span v-html="textContent['property_view']['mitigation-expert']['text']">

				</span>

				<btn variant='arkly' label="pay-now3" outlined width='auto' @click="scheduleExpertMeeting('mitigation-expert')"> 
					{{ textContent['buttons']['expert-buy']['label'] }}
				</btn>	
			</div>
			
		</div>
	</modal>
</div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import { mapState, mapGetters, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import Button from '@/components/Button'
import Modal from '@/components/Modal'
import { event } from 'vue-gtag'

export default {
	name: 'ExpertConsult',
	components: {
		'inline-svg': InlineSvg,
		'btn': Button,
		'modal': Modal,
	},
	props: {
		title: {
			type: String,
			default(rawProps) {	
				if (rawProps.consultType == 'mitigation') {
					return 'Consult a Mitigation Expert'
				} else if (rawProps.consultType == 'suggestedMitigation') {
					return 'Suggested Solution: Consult a Mitigation Expert'
				} else if (rawProps.consultType == 'risk') {
					return 'Consult a Flood Risk Expert'
				} else {
					return 'Consult an Expert'
				}
			},
		},
		consultType: {
			type: String,
			required: true,
			validator: function (value) {
				return ['mitigation', 'risk', 'suggestedMitigation'].indexOf(value) !== -1;
			},
		},
		imageSRC: {
			type: String,		
		},
		outlined: {
			type: Boolean,
			default: true,
		},
		size: {
			type: String,
			default: 'medium'
		},
		width: {
			type: String,
			default: undefined,
		},
		icon: {
			type: String,
			default: undefined,
		},
	},
	data() {
		return {
			isModalActive: false,
			message: '',
			errorMessage: '',
			mitigationImageSRC: 'expertConsult/elevatedHouse.jpg',
			suggestedMitigationImageSRC: 'expertConsult/elevatedHouse.jpg',
			riskImageSRC: 'expertConsult/vulnerableHouse.jpg',
		}
	},
	mounted() {

	},
	methods: {
		closeModal() {
			this.isModalActive = false
		},
		...mapActions(useConfigStore, ['scheduleExpertMeeting'])
	},
	computed: {
		image() {
			let imageSRC, alt
			if (this.image) {
				imageSRC = this.image
				alt = this.image
			} else if (this.consultType == 'mitigation') {
				imageSRC = this.mitigationImageSRC
				alt = 'Elevated House'
			} else if (this.consultType == 'suggestedMitigation') {
				imageSRC = this.mitigationImageSRC
				alt = 'Elevated House'
			} else {
				imageSRC = this.riskImageSRC
				alt = 'House at Risk'
			}
			 
			return {
				'src': require(`@/assets/images/${imageSRC}`), 
				'class': `${this.consultType}-image`,
				'alt': alt,
			}

		},
		...mapState(useConfigStore, ['url','userIP','textContent']),
	},
	watch: {
		isModalActive(newState){
			if (newState) {
				event(this.consultType + '_consult_opened', {'event_category': 'expert_consult', 'location_ID' : window.location.href.split('/').slice(-1)})
				confection.submitEvent(this.consultType + '_consult_opened','location ID: ' + window.location.href.split('/').slice(-1))
			} else {
				event(this.consultType + '_consult_closed', {'event_category': 'expert_consult', 'location_ID' : window.location.href.split('/').slice(-1)})
				confection.submitEvent(this.consultType + '_consult_closed','location ID: ' + window.location.href.split('/').slice(-1))
			}
		}
	}
}

</script>

<!-- CSS -->
<style lang="scss" scoped>
@import "../../style/arkly.scss";

h6 {
	color: black;
}

.expert-consult-container {
	width: auto;
}

.expert-modal-content {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;
	padding-bottom: 50px;
	max-width: 930px;
	width: 70vw;
	height: auto;
	min-height: 100px;
}

.text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: $padding-medium;
	padding: $padding-medium;	
	width: 100%;
	max-width: 100%;
}

.expert-modal-content .text:first-child {
	align-items: flex-start;
	text-align: left;
}

.expert-modal-content .text:last-child {
	align-items: flex-end;
	text-align: right;
}

.modal-image img {
	height: auto;
	width: 100%;
	max-width: 100%;
}

.risk-image {
	padding-top: 20px;
	display: relative;
	top: -63px;
	height: auto;
	width: 100%;
	max-width: 100%;
}

.mitigation-image {
	padding-top: 20px;
	display: relative;
	bottom: 0;
	height: auto;
	width: 100%;
	max-width: 100%;
}

@media screen and (max-width: $mobile-breakpoint) {
	.expert-modal-content {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		padding-bottom: 0px;
		width: 90vw;
	}

	.text {
		justify-content: flex-start;
		overflow-y: scroll;
	}

	.risk-image {
		display: none;
	}

	.mitigation-image {
		display: none;
	}

}

</style>
