<!-- HTML -->
<template>
	<loading-screen :isLoading="isLoading" variant="hightide" :slowInternet="slowInternet"/>
	<div class="grid">
		<control-bar
			id="topbar"
			@controls='handleControls'
			@changeInElevation='handleChangeInElevation'
		/>

		<base-map v-if='coordinates' id='mapbox' :class="{'missing-property': isRouteLocation}"/>
		
		<div 
			class="safariBullshitWrapper"
			id="values"
			v-if='isMobile && !isRouteLocation'
		>
			<info-table
				v-if="$route.params.view == 'economic' && coordinates != null && !isRouteLocation && this.surfData.attributes.area"
				:input='surfData'
				:dataSource='controls.dataSource'
				:year='controls.year'
				:stormCategory='controls.storm'
				:firstFloorElevation='FFE'
				:useType='controls.useType'
				:maxEL='maxEL'
				:expectedLoss='EL'
				:maxAAL='maxAAL'
				:averageAnnualLoss='AAL'
			/>
			
			<socioeconomic-table
				v-if="$route.params.view == 'socioeconomic' && coordinates != null && equity != undefined && !isRouteLocation"
				:input='surfData'
				:dataSource='controls.dataSource'
				:year='controls.year'
				:stormCategory='controls.storm'
				:firstFloorElevation='FFE'
				useType='total'
				:maxEL='maxEL'
				:expectedLoss='EL'
				:maxAAL='maxAAL'
				:averageAnnualLoss='AAL'
				:equity='equity'
			/>
		</div>
		
		<not-found class='not-found-modal' />
	</div>

</template>

<!-- JavaScript -->

<script>
import { mapState, mapGetters, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import { useSurfStore } from '@/store/surf'
import axios from 'axios'
import LoadingScreen from '@/components/LoadingScreen'
import InfoTable from '@/components/govSub/InfoTable'
import SocioeconomicTable from '@/components/govSub/SocioeconomicTable'
import NotFound from '@/components/NotFound'
import BaseMap from '@/components/map/Map'
import Footer from '@/components/Footer'
import ControlBar from '@/components/govSub/ControlBar'
import { getAuthToken } from '@/helpers/auth.js'

import SampleData from '@/assets/data/sample_data.json'

export default {
	name: 'result',
	components: {
		'loading-screen' : LoadingScreen,
		'info-table': InfoTable,
		'socioeconomic-table': SocioeconomicTable,
		'foot': Footer,
		'control-bar': ControlBar,
		'base-map': BaseMap,
		'not-found': NotFound,
	},
	data() {
		return{
			timesCalled: 0,
			isLoading: true,
			slowInternet: false,
			currentLayer: undefined,
			isMapVisible: true,
			isFloodTableReady: false,
			isAddressUnavailable: false,
			tooltips: null,
			mapZoom: 7,
			//firstFloorElevation: null,
			updatedFirstFloorElevation: null,
			changeInElevation: 0,
			expectedLoss: null,
			averageAnnualLoss: null,
			zoom: 14.5,
			stormCategory: '5year',
			stormCategoryLookup: {
				'5year': 'category1',
				'10year': 'category2',
				'25year': 'category3',
				'50year': 'category4',
				'100year': 'category5'
			},
			mapLayer: 'NOAA_5year_2020_mean',
			cmsQuery: `
				query{
					items{
						city_data(filter: { id: { _eq: "Ponce-Inlet" } }) {
							data
							revised_data
						}
						dashboard {
							sea_level_rise_projections
							expected_damage_time_series
							damage_distribution
						}
					}
				}
			`
		}
	},
	computed: {
		endpointURL: function() {
			if (this.$route.params.type == 'property') {
				return `${this.url}/surf/deterministic/${this.$route.params.type}/${this.$route.params.id}`
			} else {
				return `${this.url}/surf/aggregate/${this.$route.params.type}/${this.$route.params.id}`

			}
		},
		...mapState(useConfigStore, ['isRouteLocation','govSubName','url', 'mapConfig', 'controls', 'user', 'isMobile']),
		...mapState(useSurfStore, [
			'EL',
			'maxEL',
			'AAL',
			'maxAAL',
			'coordinates',
			'surfData',
			'FFE',
			'equity',
		]),
	}, 
	created() {
		if (this.$route.params.id == 'Location') {
			this.setRouteIsLocation(true)
		} else {
			this.setRouteIsLocation(false)

		}
		if (this.$route.query.lng && this.$route.query.lat) {
			this.setLngLat(this.$route.query.lng,this.$route.query.lat)
			if (this.$route.query.zoom) {
				this.mapConfig.zoomOverRide = this.$route.query.zoom
			}
		}
	},
	mounted() {
		if (this.isMobile) {
			if (this.isRouteLocation) {
				if (window.outerHeight != window.innerHeight) {
					this.moveElementCSS('-5','155','.mapboxgl-ctrl-logo','bottom')
					this.moveElementCSS('20','80','.mapboxgl-ctrl-bottom-right','bottom')
				} else {
					if (this.$route.params.view == 'socioeconomic') {
						this.moveElementCSS('-5','115','.mapboxgl-ctrl-logo','bottom')
					} else {
						this.moveElementCSS('-5','80','.mapboxgl-ctrl-logo','bottom')
					}
					this.moveElementCSS('20','5','.mapboxgl-ctrl-bottom-right','bottom')
				}
			} else {
				if (this.$route.params.view == 'socioeconomic') {
					this.moveElementCSS('-5','115','.mapboxgl-ctrl-logo','bottom')
				} else {
					this.moveElementCSS('-5','85','.mapboxgl-ctrl-logo','bottom')
				}
				this.moveElementCSS('20','5','.mapboxgl-ctrl-bottom-right','bottom')
			}
		} else {
			if (this.$route.params.view == 'socioeconomic') {
				this.moveElementCSS('-5','115','.mapboxgl-ctrl-logo','bottom')
			} else {
				this.moveElementCSS('-5','85','.mapboxgl-ctrl-logo','bottom')
			}
			this.moveElementCSS('20','5','.mapboxgl-ctrl-bottom-right','bottom')
		}
		this.currentLayer = this.mapConfig.govSubLayers.property[0]
		this.updateSurf()
		var t = setInterval(this.sourceCallback,100);
		this.runningFunction = t
		this.setFavicon()
		this.setTitle()
	},
	watch: {
		'$route': {
			handler: function(result) {
				if ( result.name != 'login') {	
					this.setElevation(0)
					this.updateSurf() 
				}
				if(this.popup != null){
					this.popup.remove()
				}
				if (result.params.id == 'Location') {
					this.setRouteIsLocation(true)
				} else {
					this.setRouteIsLocation(false)
				}
				
			},
			deep: true,
		},
		isRouteLocation(newValue) {
			if (this.isMobile) {
				if (window.outerHeight != window.innerHeight) {
					if (newValue) {
						this.moveElementCSS('80','155','.mapboxgl-ctrl-logo','bottom')
						this.moveElementCSS('5','80','.mapboxgl-ctrl-bottom-right','bottom')
					} else {
						this.moveElementCSS('155','80','.mapboxgl-ctrl-logo','bottom')
						this.moveElementCSS('80','5','.mapboxgl-ctrl-bottom-right','bottom')
					}
				}
			}
			
		},
		firstFloorElevation: function(newElevation) {
			this.updatedFirstFloorElevation = newElevation + this.changeInElevation
		},
		coordinates: function(newValue) {

		},
		EL: {
			handler: function(result) {
				//console.log(result)
			},
			deep: true,
		},
		AAL(newValue) {
			//console.log(newValue)
		}
	},
	methods: {
		sourceCallback() {
			this.timesCalled = this.timesCalled + 1
			if (this.timesCalled > 100) {
				this.slowInternet = true
			}
			// assuming 'map' is defined globally, or you can use 'this'
			if (this.mapConfig.map){
				if (this.mapConfig.map.getSource(this.currentLayer) && this.mapConfig.map.isSourceLoaded(this.currentLayer)) {
					clearInterval(this.runningFunction)
					this.isLoading = false
				}
			}
		},
		handleControls(selection) {
			if (selection.label == 'view'){
				this.$router.push({name: 'economic', params: { view: selection.value, type: this.$route.params.type, id: this.$route.params.id}})
			}
			this.mapLayer = `${selection.dataSource}_${this.stormCategory.replace(/\D/g, '') + 'year'}_${selection.year}_mean`
		},
		handleZoom(newZoom) {
			//this.setZoom(newZoom)

		},
		handleChangeInElevation() {
			//this.updateEL()
		},
		...mapActions(useSurfStore, ['setLngLat','updateSurf', 'updateAAL', 'updateEL']),
		...mapActions(useConfigStore, ['setTitle','setFavicon','moveElementCSS','setRouteIsLocation','setLocation', 'setZoom', 'setElevation']),	
	},
}
</script>

<!-- CSS -->
<style lang="scss" scoped>
@import "../style/style.scss";


#topbar {
	grid-area: topbar;
	position: fixed;	
	width: 100vw;
	height: $topbar-height;
	z-index: 10;
	top: 0; //$navbar-height;
	overflow: visible;
}

#values {
	grid-area: values; 
}

#mapbox {
	grid-area: mapbox;
	position: fixed;
	top: calc(#{$topbar-height}); //+ #{$navbar-height}
	width: 100vw;
	//width: calc(100vw - 500px);
	height: calc(100vh - #{$topbar-height} ); //- #{$navbar-height}
	z-index: 1;
}
#footer { grid-area: footer; }

.grid {
	display: grid;
	grid-template-columns: 1fr 500px;
	grid-template-rows: $topbar-height auto $footer-height;
	grid-template-areas: 
	" topbar topbar "
	" mapbox mapbox "
	" mapbox mapbox ";
	width: 100%;
	background-color: #394149;
	//margin-top: $navbar-height;
	min-height: calc(100vh - #{$navbar-height});
}

@media screen and (max-width: 1160px) {

	#mapbox {
		top: calc(#{$topbar-height}); //+ #{$navbar-height}
		//width: calc(100vw - 500px);
		width: 100%;
	}
	
	.grid {
		grid-template-columns: 1fr 500px;
		grid-template-rows: $topbar-height auto;
	}
}


@media only screen and (max-width: 800px) {
	$topbar-height: 100px;

	#topbar {
		position: relative;
		top: 0px;
		height: 100%;
	}

	#mapbox {
		position: relative;
		height: 100%;
		grid-area: mapbox;
		min-height: 445px;
		height: 67vh;
		width: 100%; 
		top: 0px;
	}

	.grid {
		display: grid;
		width: 100%; 
		grid-template-columns: 100vw;
		grid-template-rows: 33px auto auto;
		grid-template-areas: 
		" topbar "
		" mapbox  "
		" values  ";
	}
}

@media print {
	#mapbox {
		position: relative;
		height: 100%;
		grid-area: mapbox;
		width: 100%;  
	}

	#topbar {
		padding: 0px;
	}

	.grid {
		display: grid;
		max-width: 8.5in; 
		grid-template-columns: 8.5in;
		grid-template-rows: 0 auto $footer-height;
		grid-template-areas: 
		" mapbox " 
		" values "
		" footer "; 
	}

}
.missing-property {
	height: calc(100vh - 33px) !important;
}
</style>
