import { createApp, markRaw } from 'vue'
import App from './App.vue'
import router from './router'
import './style/arkly.scss'

import { createPinia, mapState, mapGetters, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config.js'
import { useSurfStore } from '@/store/surf.js'

import axios from 'axios'
import VueAxios from 'vue-axios'
import VueGtag from 'vue-gtag'
import InlineSvg from 'vue-inline-svg'
import ConfectionVueCable from "@getconfection/confection-vue-cable"
import VueSocialSharing from 'vue-social-sharing'
import Oruga from '@oruga-ui/oruga-next'
import vSelect from 'vue-select'
import './style/oruga.scss'
import './style/style.scss'
import 'vue-select/dist/vue-select.css'


import { library } from '@fortawesome/fontawesome-svg-core'

axios.interceptors.request.use(function (config) {
	//console.log('config: ', config)
	//config.headers['X-Real-IP'] = 'ip'
	return config
})

// internal icons
import {
	faCheck,
	faCheckCircle,
	faInfoCircle,
	faExclamationTriangle,
	faExclamationCircle,
	faArrowUp,
	faAngleRight,
	faAngleLeft,
	faAngleDown,
	faEye,
	faEyeSlash,
	faCaretDown,
	faCaretUp,
	faPencil,
	faXmark,
	faClose,
	faArrowUpFromBracket,
} from "@fortawesome/free-solid-svg-icons"

import { faTwitter, faFacebookF } from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

library.add(
	faCheck,
	faCheckCircle,
	faInfoCircle,
	faExclamationTriangle,
	faExclamationCircle,
	faArrowUp,
	faAngleRight,
	faAngleLeft,
	faAngleDown,
	faEye,
	faEyeSlash,
	faCaretDown,
	faCaretUp,
	faPencil,
	faXmark,
	faTwitter,
	faFacebookF,
	faClose,
	faArrowUpFromBracket,
)

// Adding splice function to String.
// One instance of this is used to 
// properly format rocketflood phone
// number input.
String.prototype.splice = function(index, count, add) {
	if (index < 0) {
		index += this.length
		if (index < 0)
			index = 0
	}
	return this.slice(0, index) + (add || '') + this.slice(index + count)
}

const pinia = createPinia()
export let app = createApp(App)	

pinia.use(({ store }) => {
	store.$route = markRaw(router.currentRoute)
	store.$router = router
})

app.use(ConfectionVueCable, { "id": "confection", "account": 36354, "privacy": "none", "position": "none", "analytics": "true"})
app.use(VueAxios, axios)
app.component('vue-fontawesome', FontAwesomeIcon)
app.use(Oruga, {
	iconComponent: 'vue-fontawesome',
    iconPack: 'fas'
})
app.component('inline-svg', InlineSvg)
app.component('v-select', vSelect)
app.use(pinia)

// Allows router calls to be made using 'Window'
window.router = router

app.use(router)
if (window.location.hostname.includes('dev.arkly')) {
	app.use(VueGtag, {
		includes: [{ id: 'G-5F92QWN7CH' }],
		pageTrackerExcludedRoutes: [
			'login'
		], 
		config: { 
			id: 'G-3Y47JTYJB2',
		}
	}, router)
} else if (window.location.hostname.includes('arkly')) {
	app.use(VueGtag, {
		includes: [{ id: 'G-L4YW9WZL25' }],
		pageTrackerExcludedRoutes: [
			'login'
		], 
		config: { 
			id: 'G-3Y47JTYJB2',
		}
	}, router)
} else if (window.location.hostname.includes('dev.hightide')) {
	app.use(VueGtag, {
		pageTrackerExcludedRoutes: [
			'login'
		], 
		config: { 
			id: 'G-3Y47JTYJB2',
		}
	}, router)
} else if (window.location.hostname.includes('hightide')) {
	app.use(VueGtag, {
		includes: [{ id: 'G-9HR0R5PXYV' }],
		pageTrackerExcludedRoutes: [
			'login'
		], 
		config: { 
			id: 'G-3Y47JTYJB2',
		}
	}, router)
} else {
	app.use(VueGtag, {
		includes: [{ id: 'G-5F92QWN7CH' }],
		pageTrackerExcludedRoutes: [
			'login'
		], 
		config: { 
			id: 'G-3Y47JTYJB2',
		}
	}, router)

}

app.use(VueSocialSharing)

app.mount('#app')

const configStore = useConfigStore()
const surfStore = useSurfStore()
