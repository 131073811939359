<template>
      <div v-if="CA_Actions.length == 0">
        <h3 style="padding: 20px; margin-top: 20px;">No action history to show.</h3>
      </div>
      <table  class="scrolldown" v-if="CA_Actions.length > 0">
        <thead>
          <tr>
            <th class="click-header-cell" v-on:click="sortHistory($event.currentTarget.id)" id="action"><div v-if="currSort == 'down_action'" class="down-arrow"></div><div v-if="currSort == 'up_action'" class="up-arrow"></div> Action</th>
            <th class="click-header-cell" v-on:click="sortHistory($event.currentTarget.id)" id="assetName"><div v-if="currSort == 'down_assetName'" class="down-arrow"></div><div v-if="currSort == 'up_assetName'" class="up-arrow"></div> Asset Name </th>
            <th class="click-header-cell" v-on:click="sortHistory($event.currentTarget.id)" id="assetClass"><div v-if="currSort == 'down_assetClass'" class="down-arrow"></div><div v-if="currSort == 'up_assetClass'" class="up-arrow"></div> Asset Class</th>
            <th class="click-header-cell" v-on:click="sortHistory($event.currentTarget.id)" id="assetType"><div v-if="currSort == 'down_assetType'" class="down-arrow"></div><div v-if="currSort == 'up_assetType'" class="up-arrow"></div> Asset Type</th>
            <th class="click-header-cell" v-on:click="sortHistory($event.currentTarget.id)" id="assetSubType"><div v-if="currSort == 'down_assetSubType'" class="down-arrow"></div><div v-if="currSort == 'up_assetSubType'" class="up-arrow"></div> Asset Subtype</th>
            <th class="click-header-cell" v-on:click="sortHistory($event.currentTarget.id)" id="assetSource"><div v-if="currSort == 'down_assetSource'" class="down-arrow"></div><div v-if="currSort == 'up_assetSource'" class="up-arrow"></div> Asset Source</th>
          </tr>
        </thead>
        <tbody>
        <tr v-for="action in historySorted" class="id-cell" v-on:click="selectRow($event)" :id="'action-history-row-' + action[2]">
          <td>{{processActionName(action[0])}}</td>
          <td>{{action[1].name}}</td>
          <td>{{action[1].class}}</td>
          <td>{{action[1].type}}</td>
          <td>{{action[1].subtype}}</td>
          <td>{{action[1].source}}</td>
        </tr>
      </tbody>
      </table>
      <div style="padding: 10px; width: 100%; height: fit-content; display: flex;justify-content: space-evenly;">
        <btn color='white' v-if="CA_Actions.length > 0" @click="undoSelected()">Undo Selected Actions</btn>
      </div>
</template>
  
<script>
import { useConfigStore } from '@/store/config'
import { useSurfStore } from '@/store/surf'
import { mapState, mapActions } from 'pinia'
import Button from '@/components/Button'
import InlineSvg from 'vue-inline-svg'

export default {
	components: {
		InlineSvg,	
		'btn': Button,
	},
  name: "actionHistory",
  props: [],
  emits: ['closeModal'],
  data() {
		return {
			startProp: 0,
			endProp: 10,
      currSort: undefined,
      needRefresh: false,
      historySorted: [],
      selectedRows: [],

		}
	},
  mounted(){
    this.historySorted = JSON.parse(JSON.stringify(this.CA_Actions))
  },
  computed: {
    ...mapState(useSurfStore, []),
    ...mapState(useConfigStore, ['CA_Actions','textContent','controls']),
  },
  methods: {
    undoSelected() {
      this.selectedRows.forEach(row => {
        const elem = document.getElementById(row);
        elem.className = 'id-cell'
        this.undoSpecificCa_Action(Number(row.split('-').slice(-1)));
      })
      this.selectedRows = []

    },
    selectRow(clickEvent) {
      if (clickEvent.currentTarget.className == 'id-cell-selected') {
        clickEvent.currentTarget.className = 'id-cell'
        this.selectedRows = this.selectedRows.filter(function(e) { return e !== clickEvent.currentTarget.id })
      } else {
        clickEvent.currentTarget.className = 'id-cell-selected'
        this.selectedRows.push(clickEvent.currentTarget.id)
      }
		},
    sortHistory(id) {
      var direction = 'down'
      if (this.currSort && this.currSort.startsWith('down') && this.currSort.endsWith(id)) {
        direction = 'up'
      }
      this.currSort = direction + '_' + id
      var currHistory = JSON.parse(JSON.stringify(this.CA_Actions))
      this.historySorted = currHistory.sort(this.sortByString(direction, id.replace('asset','').toLowerCase()));
		},
    ...mapActions(useSurfStore, []),
		...mapActions(useConfigStore, ['processActionName','undoSpecificCa_Action','sortByString','abbreviateNumbers']),
  },
  watch: {
    CA_Actions(){
      if (!this.currSort) {
        this.historySorted = JSON.parse(JSON.stringify(this.CA_Actions))
      } else {
        this.sortHistory(this.currSort)
      }
    },
  }
};

</script>
  
<style lang="scss" scoped>
@import "../style/style.scss"; 
  
  
  .fadeout {
    animation: fadeout 2s forwards;
  }
  
  @keyframes fadeout {
    to {
      opacity: 0;
      visibility: hidden;
    }
  }

  .slide-fade-enter-active {
    transition: all 0.3s ease-out;
  }

  .slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    /*transform: translatey(-20px);*/
    opacity: 0;
  }

  .loading:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 1500ms infinite;
    animation: ellipsis steps(4, end) 1500ms infinite;
    content: "\2026";
    /* ascii code for the ellipsis character */
    width: 0px;
  }

  table.scrolldown {
    padding: 10px;
    border-spacing: 0px;
    margin-top: 15px;
    width: 100%;
    /* border-collapse: collapse; */
  }

  /* To display the block as level element */
  table.scrolldown tbody,
  table.scrolldown thead {
      display: block;
  }

  th {
    background-color: $blue;
    color: white;
    width:150px;
    max-width:150px;
    padding: 10px;
  }

  table.scrolldown tbody {

      /* Set the height of table body */
      height: 500px;

      /* Set vertical scroll */
      overflow-y: auto;

      /* Hide the horizontal scroll */
      overflow-x: hidden;
  }

  td {
    border: 0.5px solid #000000;
    padding: 10px;
    width:150px;
    max-width:150px;

  }

  .id-cell:hover {
    cursor: pointer;
    background-color: $light-blue;
    color: $dark-blue;

  }
  .id-cell-selected {
    background-color: $light-blue;
    color: $dark-blue;
  }

  .id-cell-selected:hover {
    cursor: pointer;
    filter: brightness(80%);
  }
  
  .click-header-cell:hover {
    cursor: pointer;
    background-color: $light-blue;
    color: $dark-blue;

  }

  .down-arrow {
    padding: 5px;
    position: absolute;
    margin-top: -20px;
    margin-left: -10px;
    width: 30px;
    height: 30px;
    background-image: url("data:image/svg+xml; charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23ffffff' opacity='0.69'%3E%3Cpolygon points='30,55 70,55 50,90' /%3E%3C/svg%3E");
    background-size: 30px, 30px;
  }
  .up-arrow {
    padding: 10px;
    position: absolute;
    margin-top: -20px;
    margin-left: -10px;
    width: 30px;
    height: 30px;
    background-image: url("data:image/svg+xml; charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23ffffff' opacity='0.69'%3E%3Cpolygon points='30,90 70,90 50,55' /%3E%3C/svg%3E");
    background-size: 30px, 30px;
  }

  

  
  </style>