<template>
      <div style='width:200px' v-if="fetching">
        <h3 class="loading" style="padding: 20px; margin-top: 20px;">Retrieving data</h3>
      </div>
      <div v-if="(noQueryResults && !fetching)">
        <h3 style="padding: 20px; margin-top: 20px;">Polygon query returned no data.</h3>
      </div>
      <table class="table table-bordered" v-if="queryResults && !fetching && !needRefresh">
        <thead>
          <tr>
            <th> </th>
            <th> Selected Properties</th>
            <th> Improvement Value</th>
            <th v-if="queryResults[0].expectedLoss"> 100-Year risk in 2020</th>
            <th v-if="queryResults[0].expectedLoss"> 100-Year risk in 2050</th>
            <th v-if="queryResults[0].expectedLoss"> 100-Year risk in 2100</th>
          </tr>
        </thead>
        <tr>
          <td> Totals:</td>
          <td> {{abbreviateNumbers(queryResults.length)}} </td>
          <td> ${{abbreviateNumbers(aggregateResult(queryResults.slice(0,queryResults.length),'tax'))}}</td>       
          <td v-if="queryResults[0].expectedLoss"> ${{abbreviateNumbers(aggregateResult(queryResults.slice(0,queryResults.length),'risk2020'))}}</td>
          <td v-if="queryResults[0].expectedLoss"> ${{abbreviateNumbers(aggregateResult(queryResults.slice(0,queryResults.length),'risk2050'))}}</td>
          <td v-if="queryResults[0].expectedLoss"> ${{abbreviateNumbers(aggregateResult(queryResults.slice(0,queryResults.length),'risk2100'))}}</td>
        </tr>
      </table>
      <table class="table table-bordered" v-if="queryResults && !fetching">
        <thead>
          <tr>
            <th> Address</th>
            <th class="click-header-cell" v-on:click="sortColumn($event)" id="yearBuilt"><div v-if="currSort == 'down_yearBuilt'" class="down-arrow"></div><div v-if="currSort == 'up_yearBuilt'" class="up-arrow"></div> Year Built </th>
            <th class="click-header-cell" v-on:click="sortColumn($event)" id="impValue"><div v-if="currSort == 'down_impValue'" class="down-arrow"></div><div v-if="currSort == 'up_impValue'" class="up-arrow"></div> Improvement Value</th>
            <th v-if="queryResults[0].expectedLoss" class="click-header-cell" v-on:click="sortColumn($event)" id="risk2020"><div v-if="currSort == 'down_risk2020'" class="down-arrow"></div><div v-if="currSort == 'up_risk2020'" class="up-arrow"></div> 100-Year risk in 2020</th>
            <th v-if="queryResults[0].expectedLoss" class="click-header-cell" v-on:click="sortColumn($event)" id="risk2050"><div v-if="currSort == 'down_risk2050'" class="down-arrow"></div><div v-if="currSort == 'up_risk2050'" class="up-arrow"></div> 100-Year risk in 2050</th>
            <th v-if="queryResults[0].expectedLoss" class="click-header-cell" v-on:click="sortColumn($event)" id="risk2100"><div v-if="currSort == 'down_risk2100'" class="down-arrow"></div><div v-if="currSort == 'up_risk2100'" class="up-arrow"></div> 100-Year risk in 2100</th>
          </tr>
        </thead>
        <tr v-for="property in queryResults.slice(startProp,endProp)">
          <td class="id-cell" v-on:click="goToProperty($event)" :id="property._id">{{property.location.address.street}}, {{property.location.address.city}}</td>
          <td>{{property.attributes.yearBuilt<3000 ? + Math.trunc(property.attributes.yearBuilt) : 'No Data'}}</td>
          <td>{{property.attributes.tax.improvementValue>0 ? '$' + abbreviateNumbers(property.attributes.tax.improvementValue) : 'No Data'}}</td>
          <td v-if="property.expectedLoss">${{abbreviateNumbers(property.expectedLoss[this.queryControls['useType']][this.queryControls['dataSource']][[this.queryControls['storm'].replace(' ','')]]['2020']['mean'])}}</td>
          <td v-if="property.expectedLoss">${{abbreviateNumbers(property.expectedLoss[this.queryControls['useType']][this.queryControls['dataSource']][[this.queryControls['storm'].replace(' ','')]]['2050']['mean'])}}</td>
          <td v-if="property.expectedLoss">${{abbreviateNumbers(property.expectedLoss[this.queryControls['useType']][this.queryControls['dataSource']][[this.queryControls['storm'].replace(' ','')]]['2100']['mean'])}}</td>
        </tr>
        <tr>
          <td> Current Totals:</td>
          <td> N/A</td>
          <td> ${{abbreviateNumbers(aggregateResult(queryResults.slice(startProp,endProp),'tax'))}}</td>       
          <td v-if="queryResults[0].expectedLoss"> ${{abbreviateNumbers(aggregateResult(queryResults.slice(startProp,endProp),'risk2020'))}}</td>
          <td v-if="queryResults[0].expectedLoss"> ${{abbreviateNumbers(aggregateResult(queryResults.slice(startProp,endProp),'risk2050'))}}</td>
          <td v-if="queryResults[0].expectedLoss"> ${{abbreviateNumbers(aggregateResult(queryResults.slice(startProp,endProp),'risk2100'))}}</td>
        </tr>
      </table>
      <div style="padding: 10px; width: 100%; height: fit-content; display: flex;justify-content: space-evenly;">
        <btn color='white' v-show="queryResults && !fetching && startProp != 0" @click="changeDisplayedProperties(-10)">Previous 10 Properties</btn>
        <btn color='white' v-show="queryResults && !fetching && queryResults.slice(startProp,endProp).length == 10" @click="changeDisplayedProperties(10)">Next 10 Properties</btn>
      </div>
</template>
  
<script>
import { useConfigStore } from '@/store/config'
import { useSurfStore } from '@/store/surf'
import { mapState, mapActions } from 'pinia'
import Button from '@/components/Button'
import InlineSvg from 'vue-inline-svg'

export default {
	components: {
		InlineSvg,	
		'btn': Button,
	},
  name: "dataTable",
  props: [],
  emits: ['closeModal'],
  data() {
		return {
			startProp: 0,
			endProp: 10,
      currSort: undefined,
      needRefresh: false,
		}
	},
  computed: {
    ...mapState(useSurfStore, ['noQueryResults','fetching','queryResults','queryControls']),
    ...mapState(useConfigStore, ['textContent','controls']),
  },
  methods: {
    goToProperty(clickEvent) {
			var id = clickEvent.srcElement.id
      if (id.includes('/')) {
				id = id.replace('/','forwardSlash')
			}
      this.$emit('closeModal')
			router.push({name: this.$route.name, params: { view: 'economic', id: encodeURIComponent(id), type: 'property'}})
		},
    sortColumn(clickEvent) {
			var id = clickEvent.srcElement.id
      var direction = 'down'
      if (this.currSort && this.currSort.startsWith('down') && this.currSort.endsWith(id)) {
        direction = 'up'
      }
      this.currSort = direction + '_' + id
      this.sortQueryData(this.currSort)
		},
    changeDisplayedProperties(delta){
			this.startProp = this.startProp + delta
			this.endProp = this.endProp + delta
		},
		aggregateResult(currData, type){
			var aggNum = 0 ;
			aggNum = currData.reduce((aggNum, curr) => {
				if (type == 'tax') {
					aggNum += curr.attributes.tax.improvementValue
						return aggNum;
				} else if (type == 'risk2020') {
					aggNum += curr.expectedLoss[this.queryControls['useType']][this.queryControls['dataSource']][[this.queryControls['storm'].replace(' ','')]]['2020']['mean']
						return aggNum;
				} else if (type == 'risk2050') {
					aggNum += curr.expectedLoss[this.queryControls['useType']][this.queryControls['dataSource']][[this.queryControls['storm'].replace(' ','')]]['2050']['mean']
						return aggNum;
				} else if (type == 'risk2100') {
					aggNum += curr.expectedLoss[this.queryControls['useType']][this.queryControls['dataSource']][[this.queryControls['storm'].replace(' ','')]]['2100']['mean']
						return aggNum;
				}}, aggNum);
			return aggNum
		},
    ...mapActions(useSurfStore, ['sortQueryData']),
		...mapActions(useConfigStore, ['abbreviateNumbers']),
  },
  watch: {
    queryResults: {
			handler: function(result) {
				this.startProp = 0
        this.endProp = 10
			},
			deep: true,
		},
		controls: function(newValue) {
      this.needRefresh = true
		},
  }
};

</script>
  
<style lang="scss" scoped>
@import "../style/style.scss"; 
  
  
  .fadeout {
    animation: fadeout 2s forwards;
  }
  
  @keyframes fadeout {
    to {
      opacity: 0;
      visibility: hidden;
    }
  }

  .slide-fade-enter-active {
    transition: all 0.3s ease-out;
  }

  .slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    /*transform: translatey(-20px);*/
    opacity: 0;
  }

  .loading:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 1500ms infinite;
    animation: ellipsis steps(4, end) 1500ms infinite;
    content: "\2026";
    /* ascii code for the ellipsis character */
    width: 0px;
  }
  .table {
    padding: 20px;
    border-spacing: 0px;
    border-collapse: collapse;
    margin-top: 15px;
  } 
  .id-cell:hover {
    cursor: pointer;
    background-color: $light-blue;
    color: $dark-blue;

  }
  .click-header-cell:hover {
    cursor: pointer;
    background-color: $light-blue;
    color: $dark-blue;
    

  }

  .down-arrow {
    padding: 5px;
    position: absolute;
    margin-top: -20px;
    margin-left: -10px;
    width: 30px;
    height: 30px;
    background-image: url("data:image/svg+xml; charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23ffffff' opacity='0.69'%3E%3Cpolygon points='30,55 70,55 50,90' /%3E%3C/svg%3E");
    background-size: 30px, 30px;
  }
  .up-arrow {
    padding: 10px;
    position: absolute;
    margin-top: -20px;
    margin-left: -10px;
    width: 30px;
    height: 30px;
    background-image: url("data:image/svg+xml; charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23ffffff' opacity='0.69'%3E%3Cpolygon points='30,90 70,90 50,55' /%3E%3C/svg%3E");
    background-size: 30px, 30px;
  }

  th {
    background-color: $blue;
    color: white;
    min-width: 150px;
    padding: 10px;
  }

  td {
    border: 1px solid $light-blue;
    padding: 10px;

  }
  </style>