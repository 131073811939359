<!-- HTML: This is Where You Put the HTML Code -->
<template>
	<section class='search-container' :class="{'is-map-control': isMapControl}" ref='search'>
		<o-field class='search-field'>


			<o-autocomplete
				id="search-bar"
				:inputClass="(variant == 'default') ? 'search-bar' : 'alt-search-bar'"
				:menuClass="(variant == 'default') ? 'menu-item' : 'alt-menu-item'"
				:placeholder="(variant == 'alt') ? textContent['property_view']['search-bar-home']['label'] : textContent['property_view']['search-bar']['label']"
				placeholderClass='search-placeholder'
				:data="data"
				:loading="isFetching"
				:debounce-typing="500"
				:size='computedSize'
				:style="{'font-weight': 'bold', 'color': color}"
				:rounded='isRounded'
				@typing="getAsyncData"
				@select="propertySelected"
			>

				<template v-slot="props">
					{{ props.option.address }}
				</template>

			</o-autocomplete>

				<template v-if="logo == 'arkly'">
					<inline-svg 
						
						class= 'arkly-logo-search arkly-logo-search-can-hover'
						title='Arkly Logo'
						:src="require('../assets/icons/arkly-logo.svg')" 
						@click="goHome('search_bar')"
					/>
				</template>
				<template v-if="logo == 'hightide'">
					<inline-svg 
						
						class= 'arkly-logo-search hightide-logo-search'
						title='HighTide Logo'
						:src="require('../assets/icons/ArklyProLogo.svg')" 
					/>
				</template>
		</o-field>
		
		<modal 
			v-model='isMapVisible' 
			ref='modal'	
		>
			<div id='property-selection'>
				<div class='title'>
					<h2> {{ textContent['property_view']['not-found-map-text']['label'] }} </h2>
				</div>
				
				<div  class='search-map-container' ref='map-container' >
					<div id='search-map' ref='map' />
				</div>

			</div>
		</modal>
		
</section>
</template>

<script>
import axios from 'axios'
import { mapState, mapGetters, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config.js'
import { useSurfStore } from '@/store/surf.js'
import { getAuthToken } from '@/helpers/auth.js'
import InlineSvg from 'vue-inline-svg'
import Mapbox from "mapbox-gl"
import { event } from 'vue-gtag'
import Modal from '@/components/Modal'

export default {
	components: {
		'modal': Modal,
	},
	data() {
		return {
			layerToInsertUnder: "waterway-shadow copy",
			prevPropID: undefined,
			data: [],
			selected: null,
			isFetching: false,
			isPropertySelectionModalActive: true,
			isTrue: true,
			propertySelect: {
				isVisible: false,
				map: undefined,
				options: [],
				coordinates: undefined,
				isTesting: false,
				accuracy: undefined,
			},
			mapSearchOptions: [],
			minimumTextTyped: 7,
			maxDistance: 30,
			propertiesReturned: 8
		}
	},
	async mounted() {
		this.setMapConfigVersion()

		if (Boolean(this.addRef === 'true') || this.isMobile) {
			//console.log('search ref added')
			this.addComponentRef('search', this.$refs.search) 
		}
		this.propertySelect.coordinates = this.coordinates ? this.coordinates : this.startCoordinates

		if (this.propertySelect.isTesting) {
			this.isVisible = true
			this.propertySelected({
				"address": "63 Calle Delcasse, San Juan, Puerto Rico 907",
				"lngLat": "-66.075266,18.455713"
			})		
		}
	},
	props: {
		placeholder: {
			type: String,
			default: 'placeholder'
		},
		variant: {
			type: String,
			default: 'default'
		},
		addRef: {
			type: String,
			default: 'true'
		},
		logo: {
			type: String,
			default: 'arkly',
		},
		size: {
			type: String,
			default: 'small',
			validator: function (value) {
				return ['small', 'medium', 'large'].indexOf(value) !== -1;
			},
		},
		color: {
			type: String,
			default: 'black',
		},
		isMapControl: {
			type: Boolean,
			default: false,
		},
		startCoordinates: {
			type: Object,
			default: {
				'lng': -81.760254,
				'lat': 27.994402
			},
		},
	},
	computed: {
		isModalVisible() {
			return this.$refs.map.getStyle()
		},
		isMapVisible: {
			get() {
				return this.propertySelect.isVisible
			}, 
			set(value) {
				this.propertySelect.isVisible = value
			}
		},
		computedSize() {
			if( this.screenSize == 'mobile') {
				return 'small'
			} else {
				return this.size
			}
		},
		isRounded() {
			return this.variant != 'default' 
		},
		...mapState(useConfigStore, ['isMobile','isRouteLocation','url', 'screenSize', 'mapConfig', 'textContent']),
	},
	methods: {
		getAsyncData(textTyped) {

			// Clear Previous Results
			this.data = []
			//console.log(textTyped)
			// Only Search if more than n characters are typed
			if (textTyped.length > this.minimumTextTyped) {
				// Set Starting Values
				this.isFetching = true

				const instance = axios.create()
				
				let state = 'florida'
				if (this.$route.name == 'puertoRico' || this.$route.name == 'puertoRicoHome') {
					state = 'puertoRico'
				} 


				const boundingBoxes = {
					'florida': '-87.634938,24.523096,-80.031362,31.000888',
					'puertoRico': '-67.940245,17.912176,-65.223149,18.5160948',
				}
				axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${textTyped}.json?autocomplete=true&country=US&access_token=${this.mapConfig.accessToken}&types=district,place,locality,address&bbox=${boundingBoxes[state]}&limit=10`)
				// axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${textTyped}.json?autocomplete=true&country=US&access_token=${this.mapConfig.accessToken}&types=address&bbox=${boundingBoxes[state]}&limit=7`)
					 .then(response => {
						this.data = []
						//let surfSearchString = ""
						//console.log(response)
						response.data.features.forEach((item) => {
							let address = item.place_name
							
							// Accuracy is an optional value, so we need to make sure it exists before requesting it
							let accuracy = ''
							if ('properties' in item && 'accuracy' in item.properties) {
								accuracy = item.properties.accuracy
							}

							if (this.data.length < 10) {
								this.data.push({
									'address':address, 
									'placeType': item.place_type,
									'lngLat': `${item.center[0]},${item.center[1]}`,
									'accuracy': accuracy
								}) 
							}

						})
						
					 })
					 .catch((error) => {
						this.data = []
						throw error
					 })
					 .finally(() => {
						this.isFetching = false
					 })
			} 
		},
		propertySelected(option) {
			
			const instance = axios.create()
			//const lngLat = encodeURIComponent(option.lngLat)
			const lngLat = option.lngLat.split(',')
			this.propertySelect.coordinates = lngLat
			let routeName = this.$route.name	
			if (this.$route.params.id && this.$route.params.id == 'Location') {
				routeName = this.$route.name 
			} else if (this.$route.fullPath == '/') {
				routeName = 'arkly'
			} else if (this.$route.fullPath == '/pr') {
				routeName = 'puertoRico' 
			}
			if (option.placeType[0] != 'address') {
				//console.log(option)
				event('place_selected', {'event_category': 'search', 'placeType': option.placeType[0], 'placeName': option.address, 'location_ID' : window.location.href.split('/').slice(-1)})
				confection.submitEvent('place_selected','current location ID: ' + window.location.href.split('/').slice(-1) + ',placeType:' + option.placeType[0] + 'placeName: ' + option.address)
				router.push({
					name: routeName, 
					params: { 'id': 'Location', 'type': 'property', 'view': 'Location'},
					query: { 'lat': lngLat[1], 'lng': lngLat[0], 'zoom': 9},
				})
				this.setRouteIsLocation(true)
				//console.log(true, 'lat:' + lngLat[1].toLocaleString() + ',lng:' + lngLat[0].toLocaleString() + ',zoom:9',lngLat)
				this.setPropertyNotFound(true, 'lat:' + lngLat[1].toLocaleString() + ',lng:' + lngLat[0].toLocaleString() + ',zoom:9',lngLat)
			} else {
				this.isRouteLocation = false
				// Accuracy of the mapbox lat,lng (ie is the latLng 'estimated' or an actual value that mapbox has)
				this.propertySelect.accuracy = option.accuracy
				const houseNumber = option.address.split(" ")[0]
				const maxDistance = this.maxDistance
				const propertiesReturned = this.propertiesReturned
				const url = `${this.url}/surf/deterministic/property/nearest/?lngLat=${lngLat}&maxDistance=${maxDistance}&limit=${propertiesReturned}`
				//console.log(url)
				const address = option.address
				event('property_selected', {'event_category': 'search', 'address' : address, 'location_ID' : window.location.href.split('/').slice(-1)})
				confection.submitEvent('property_selected','location ID: ' + window.location.href.split('/').slice(-1) + ', address: ' + address)

				axios.get(url, {
					headers: {
						"Content-Type": "application/json",
						"Authorization": getAuthToken()
					}
				})
					.then(response => {
						
						const data = response.data
						//console.log(data)
						if (data.length != 0) {
							let correctLocation, correctBlockgroup, correctCounty

							data.forEach(property => {
								const streetAddress = property['location']['address']
								//console.log(streetAddress)
								if (streetAddress['street'].includes(houseNumber) || this.$route.name.includes('puertoRico')) {
									//('includes house number: ', houseNumber)
									const id = encodeURIComponent(property['_id'])
									this.setPropertyNotFound(false, undefined, undefined)
									correctBlockgroup = property['location']['blockgroup']
									correctCounty = correctBlockgroup.slice(0,6)
									correctLocation = id	
								} 	
							})	
							
							// If current current location exists
							if (correctLocation != undefined) { 

								if (this.$route.name == 'puertoRico' || this.$route.name == 'puertoRicoHome') {
									event('property_select_visible', {'event_category': 'search', 'location_ID' : window.location.href.split('/').slice(-1)})
									confection.submitEvent('property_select_visible','current location ID: ' + window.location.href.split('/').slice(-1))
									this.propertySelect.options = data.map(property => property['_id'])
									this.propertySelect.isVisible = true	
								} else {
									event('property_found', {'event_category': 'search', 'new_location_ID': correctLocation, 'location_ID' : window.location.href.split('/').slice(-1)})
									confection.submitEvent('property_found','current location ID: ' + window.location.href.split('/').slice(-1) + ', new location ID: ' + correctLocation)

									this.setPropertyNotFound(false, undefined, undefined)
									if (correctLocation.includes('/')) {
										correctLocation = correctLocation.replace('/','forwardSlash')
									}
									if (this.mapConfig.isEquityVisible) {
											router.push({
												name: routeName, 
												params: { 'id': correctBlockgroup, 'type': 'blockgroup', 'view': 'socioeconomic'},
											})
									} else  {//if (currZoom >= this.mapConfig.layerSettings.property['zoomRange'][0]) {
										router.push({
											name: routeName, 
											params: { 'id': correctLocation, 'type': 'property', 'view': 'economic'},
										})
									} /*else if (currZoom >= this.mapConfig.layerSettings.blockgroup['zoomRange'][0]) {
										router.push({
											name: routeName, 
											params: { 'id': correctBlockgroup, 'type': 'blockgroup', 'view': 'economic'},
										})
									} else if (currZoom < this.mapConfig.layerSettings.blockgroup['zoomRange'][0]) {
										router.push({
											name: routeName, 
											params: { 'id': correctCounty, 'type': 'county', 'view': 'economic'},
										})
									}*/
								}
							} else {
								event('property_not_found_no_match', {'event_category': 'search', 'lat': lngLat[1], 'lng': lngLat[0], 'location_ID' : window.location.href.split('/').slice(-1)})
								confection.submitEvent('property_not_found_no_match','current location ID: ' + window.location.href.split('/').slice(-1) + 'lat ' + String(lngLat[1]) +  ', lng' + String(lngLat[0]))
								router.push({
									name: routeName, 
									params: { 'id': 'Location', 'type': 'property'},
									query: { 'lat': lngLat[1], 'lng': lngLat[0] },
								})
								this.setRouteIsLocation(true)
								this.setPropertyNotFound(true, address, lngLat)
								
								
							}
						} else {
							event('property_not_found_with_distance', {'event_category': 'search', 'lat': lngLat[1], 'lng': lngLat[0], 'location_ID' : window.location.href.split('/').slice(-1)})
							confection.submitEvent('property_not_found_with_distance','current location ID: ' + window.location.href.split('/').slice(-1) + 'lat ' + String(lngLat[1]) +  ', lng' + String(lngLat[0]))
							router.push({
								name: routeName, 
								params: { 'id': 'Location', 'type': 'property'},
								query: { 'lat': lngLat[1], 'lng': lngLat[0] },
							})
							this.setRouteIsLocation(true)
							this.setPropertyNotFound(true, address, lngLat)
						}
						return
					})
					.catch((error) => {
						this.data = []
						throw error
					})
					.finally(() => {
						this.isFetching = false
					})
				}

		},
		initializeMap: async function() {
			Mapbox.accessToken = this.mapConfig.accessToken
			//console.log(this.propertySelect.coordinates)
			const map = (window.map = new Mapbox.Map({
				container: 'search-map', 
				style: 'mapbox://styles/hightideintelligence/cloiy3xqa004601ntd25b9uxs',
				center: this.propertySelect.coordinates, 
				zoom: 18,
				minZoom: 13,
				maxBounds: [[parseFloat(this.propertySelect.coordinates[0])-0.05, parseFloat(this.propertySelect.coordinates[1])-0.05],
							[parseFloat(this.propertySelect.coordinates[0])+0.05, parseFloat(this.propertySelect.coordinates[1])+0.05]],
				antialias: true,
				attributionControl: false,
				preserveDrawingBuffer: true,
			}))
			return new Promise((resolve) => { 
				map.on('load', () => {	

					const keys = Object.keys(this.mapConfig.arklyLayers);
					keys.forEach(key => {
						if (key.includes('property')){ //floodzones are in the style, but could get pulled out
							this.mapConfig.arklyLayers[key].forEach(layer => {
								if (layer.includes('puertorico')) { //only PR has the layers pulled out of the style sheet so far
									this.addMapboxLayer(layer,map)
								}
								map.on('click', layer, (e) => {
									const properties = e.features[0].properties
									//console.log(properties)
									var propertyID = properties.id
									event('property_select_map_click', {'event_category': 'search_map', 'selectedID': propertyID, 'location_ID' : window.location.href.split('/').slice(-1)})
									confection.submitEvent('property_select_map_click','current location ID: ' + window.location.href.split('/').slice(-1) + ', selectedID: ' +  propertyID)
									if (propertyID.includes('/')) {
										propertyID = propertyID.replace('/','forwardSlash')
									}
									this.$router.push({
										name: 'puertoRico', 
										params: {id: propertyID, type: 'property' }
									})
									this.propertySelect.isVisible = false
								}, this)
								
							})
						}
					})
					this.propertySelect.map = map
					resolve(map)
				})

				let hoveredPolygonId = undefined
				let lastStruID = undefined
				let sourceLayer = undefined
				this.mapConfig.arklyLayers.property.forEach(layer => {	
					if (layer.includes('puertorico')) {

						// When the user moves their mouse over the state-fill layer, 
						// we'll update the feature state for the feature under the mouse.
						map.on('mousemove', layer, (e) => {
							if (e.features.length > 0) {

								if (hoveredPolygonId !== undefined) {
									map.setFeatureState(
										{ source: sourceLayer, sourceLayer: sourceLayer, id: hoveredPolygonId },
										{ hover: true }
									)

								}

								hoveredPolygonId = e.features[0].id
								sourceLayer = e.features[0]['sourceLayer']
								map.setFeatureState(
									{ source: sourceLayer, sourceLayer: sourceLayer, id: hoveredPolygonId },
									{ hover: true }
								)
								
								if (this.prevPropID) {
									if (this.prevPropID != hoveredPolygonId) {
										map.setFeatureState(
											{ source: sourceLayer, sourceLayer: sourceLayer, id: this.prevPropID },
											{ hover: false }
										)
									}
								}
								
								if (!lastStruID) {
									lastStruID = e.features[0].properties.id
									event('property_select_map_hover', {'event_category': 'search_map', 'hoveredID': lastStruID, 'location_ID' : window.location.href.split('/').slice(-1)})
									confection.submitEvent('property_select_map_hover','current location ID: ' + window.location.href.split('/').slice(-1) + ', hoveredID: ' +  lastStruID)
								} else if (lastStruID != e.features[0].properties.id) {
									lastStruID = e.features[0].properties.id
									event('property_select_map_hover', {'event_category': 'search_map', 'hoveredID': lastStruID, 'location_ID' : window.location.href.split('/').slice(-1)})
									confection.submitEvent('property_select_map_hover','current location ID: ' + window.location.href.split('/').slice(-1) + ', hoveredID: ' +  lastStruID)
								}
								this.prevPropID = hoveredPolygonId
							}
						})
					}

					// When the mouse leaves the state-fill layer, 
					// update the feature state of the previously hovered feature.
					map.on('mouseleave', layer, (e) => {
						if (hoveredPolygonId !== null) {
							map.setFeatureState(
								{ source: sourceLayer, sourceLayer: sourceLayer, id: hoveredPolygonId },
								{ hover: false }
							)
						}
						hoveredPolygonId = null
					})
				})


		})
		},
		addMapboxLayer(layer, map) {
			map.addSource(layer, {
				type: 'vector',
				url: 'mapbox://hightideintelligence.' + layer,
				});
			map.addLayer({'id': layer,'type': 'fill','source': layer, 'source-layer': layer,'layout': {},'paint': {'fill-color': '#ffffff'}}, this.layerToInsertUnder);

		},
		setMapStyle() {
			const keys = Object.keys(this.mapConfig.arklyLayers);
			keys.forEach(key => {
				if (key.includes('property')){
					this.mapConfig.arklyLayers[key].forEach(layer => {
						if (layer.includes('puertorico')) {

							if (this.propertySelect.map) {
								
								// Set zoom levels
								this.propertySelect.map.setLayerZoomRange(layer, 0, 22)
								this.propertySelect.map.setPaintProperty(layer, 'fill-opacity', 0.8)
								this.propertySelect.map.setPaintProperty(layer, 'fill-outline-color', 'black')
								this.propertySelect.map.setPaintProperty(layer, 'fill-color', ['case',['boolean',['feature-state', 'hover'],false],'black','white'])
							}
						}
					})
				}
			})
		},
		...mapActions(useConfigStore, ['setRouteIsLocation','goHome','addComponentRef', 'setMapSearchOptions', 'setSidePanelVisibility', 'setMapConfigVersion']),
		...mapActions(useSurfStore, ['setPropertyNotFound']),
	},
	watch: {
		screenSize(newSize) {
			//console.log(newSize)
		},
		'$route': {
			handler: function(newRoute) {

			},
			deep: true,
		},
		isMapVisible(newState) {
			
			if (newState == true) {
				setTimeout(() => {
				if (this.propertySelect.map == undefined) {	
					this.initializeMap().then(map => {
						this.setMapStyle()
						this.propertySelect.map.resize()
					})

				} else {
					this.setMapStyle()
					this.propertySelect.map.jumpTo({ 'center': this.propertySelect.coordinates, 'zoom': 18 })
					this.propertySelect.map.resize()
				}
				}, 10)
			}

		},
	},
}
</script>


<!-- CSS -->
<style lang="scss">
@import "../style/style.scss";
@import "../style/oruga.scss";

$map-height: calc(90vh - 120px);
$map-width: calc(80vw - 120px);
#property-selection {
	display: grid;
	grid-template-rows: 1fr $map-height ;
	grid-template-columns: 1fr;
	width: 80vw;
	max-width: 960px;
	height: 88vh;
	padding: 10px;
	
}

#search-map-container {
	position: relative;
	width: 100%;
	max-width: 100%;
	height: 100%;
	border: 2px solid black;
    border-radius: 8px;
}

#search-map {
	border: 2px solid black;
    border-radius: 8px;
	position: relative;
	display: flex;
	width: 100%; 
	height: 100%;
	background-color: grey;
}

.is-map-control {
	position: fixed;
	top: 5px;
	left: 5px;
	background-color: white;
	border-radius: var(--oruga-input-border-radius, 4px);
}

#search-map .mapboxgl-map {
	position: absolute;
    width: 100% !important;
	height: 100% !important;
}

#search-map .mapboxgl-canvas {
	left: 0;
}

.arkly-modal #close-modal-container {
	padding-bottom: 0px;
}

.title {
	display: flex;
	align-items: center;
	justify-content: center;
	height: min-content;
	width: 100%;
	padding-bottom: 10px;
}

.title * {
	font-family: $header-family;

}



/*
.mapboxgl-map {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
}


.not-found-modal {
	position: absolute;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
}
*/

.search-container {
	width: 100%; 
	z-index: 11;
}

.search-field {
	width: 100%;
}

.search-bar {
	width: 100%;
	background-color: rgba(242, 244, 245, 1) !important;
	border-color: transparent !important;
	opacity: 1 !important;
}

.alt-search-bar {
	color: black !important;
	background-color: rgba(242, 244, 245, 1) !important;//rgba(0, 0, 0, 0.5) !important;
	border-color: white !important;
	opacity: 1 !important;
	font-size: 18px !important;
	height: 65px !important;
}


.alt-menu-item {
	border-radius: 0 0 4px 4px !important;
	width: calc(100% - 36px) !important;
	margin-left: 18px !important;
}

.o-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 1 !important; /* Firefox */
  color: inherit !important;
}

.o-input.search-bar.o-input--small {
	height: auto !important;
	font-weight: bold !important;
}


.o-input.search-bar.o-input--medium {
	height: 46px !important;
	font-weight: bold !important;
}

.o-input.search-bar.o-input--large {
	color: $primary !important;
}


.search-placeholder {
	color: $primary !important;
	opacity: 1;
}

@media screen and (max-width: 800px) {
	
	.search-container {
		width: calc(100% - 10px);
	}

	$map-height: calc(90vh - 120px);

	#property-selection {
		display: grid;
		grid-template-rows: 1fr $map-height ;
		grid-template-columns: 1fr;
		width: 90vw;
		max-width: 960px;
		height: 90vh;
		padding: 10px;
	}
	
	.mapboxgl-ctrl-top-left .container {
		width: 100vw;
	}

	.search-field{
		padding: 2px 5px;
	}

}

.arkly-logo-search-noshow {
	display: none;
}

.arkly-logo-search {
	height: 46px;
	width: 46px;
	padding: 5px;
	color: black;
	border-radius: var(--oruga-input-border-radius, 4px);
	background-color: white;	
}

@media screen and (max-width: 800px) {
	.arkly-logo-search {
	height: 36px;
	width: 36px;
	padding: 5px;
	padding-right: 0px;
	border-radius: 0 4px 4px 0;
	background-color: white;
	}
}

.hightide-logo-search {
	border-radius: 0 4px 4px 0;
}

.arkly-logo-search-can-hover:hover {
	color: $blue;
	filter: brightness(90%) !important;
	cursor: pointer;
}


.o-acp {
	width: 100%;
}


</style>
