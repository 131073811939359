<template>
<div  
	v-if="isVisible == true"
>
	<div class='house-lifting-content'>
		<h3 style='margin: 0; '> Elevation: </h3>
		<o-field class='slider'>
			<o-slider
				v-model='changeInElevation'
				:disabled='isLoading'
				size='medium' 
				:min='1' 
				:max='15' 
				tooltipAlways
				lazy
				:customFormatter='tooltipFormatter'
			>
			
		</o-slider>
		</o-field>
		<div class='response'>
			<h3  style='margin: 0;'> 
				Cost:<div v-if="!isLoading">&nbsp;{{ this.showLiftCost(houseLiftingCost) }}</div>
				<div v-else class="loading"></div>
			</h3> 
			<h3 v-if="!isLoading" style='margin: 0;'>BCR:&nbsp;
				<h3 v-if='benefitCostRatio == 0'> N/A</h3>
				<h3 v-else>{{ benefitCostRatio }}</h3>
				<svg-loader 
					v-if='benefitCostRatio >= 1'
					style='margin-left: 4px;'  
					name='checkmark'
					height='15px'
					width='15px'
					color='#82B8A3'
				/>
				<svg-loader 
					v-else-if='benefitCostRatio > 0'
					style='margin-left: 4px;'  
					name='xmark'
					height='15px'
					width='15px'
					color='#ca171d'
				/>
			</h3>
			<h3 v-else style='margin: 0;' class="loading"> BCR:
			</h3>
		</div>
	</div>
</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import { useSurfStore } from '@/store/surf'
import chroma from 'chroma-js'
import SvgLoader from '../SvgLoader.vue'
import axios from 'axios'
import debounce from 'lodash/debounce'
import '@/style/oruga.scss'
import { getAuthToken } from '@/helpers/auth.js'

export default {
	components: {
		SvgLoader,
	},
	props: ['map', 'style', 'isVisible'],
	mounted() {
		this.changeInElevation = this.elevation
	},
	data(){
		return {
			changeInElevation: undefined, 
			updatedFirstFloorElevation: 1,
			houseLiftingCost: 0,
			benefitCostRatio: 0,
			current: '+0',
			lastElevation: 0,
			isLoading: false
		}
	},
	computed: {
		style() {
			if (isVisible) {
				return 'display: flex;'
			} else {
				return 'display: none;'
			}
		},
		...mapState(useConfigStore, ['url', 'elevation']),
		...mapState(useSurfStore, ['EL']),
	},
	methods: {
		tooltipFormatter: function(value) {
			if (value == 1) {
				this.current = '+0 ft'
				return this.current
			} else {
				this.current = '+' + value.toString() + ' ft'
				return this.current
			}
		},
		updateHouseLiftingCost: function(changeInElevation) {
			if (changeInElevation > 1){
				this.isLoading = true
				this.setSurfDataFetching(true)
				const instance = axios.create()
				this.houseLiftingCost = null
				this.setElevation(changeInElevation)
				this.updateAAL(false)
				let percentile_url = `${this.url}/surf/deterministic/property/${this.$route.params.id}/costToLiftBy/${changeInElevation}/feet?foundationType=open`
			instance.get(percentile_url, {
						headers: {
							"Content-Type": "application/json",
							"Authorization": getAuthToken()
						}
				})
						.then(response => {
							this.houseLiftingCost = response.data['mean'] 	
							this.benefitCostRatio = response.data['bcr'].toFixed(1).toLocaleString()
							this.updateEL(false)

							this.$emit('changeInElevation', changeInElevation)
							this.isLoading = false
							this.setSurfDataFetching(false)
							this.lastElevation = changeInElevation
							return
				})
						.catch(function (error) {	
							console.log(error)
							this.isLoading = false
							this.setSurfDataFetching(false)
							return
				})
			} else {
				if (this.lastElevation != 0) {
					this.benefitCostRatio = 0
					this.houseLiftingCost = 0
					this.setElevation(0)
					this.updateSurf()
					this.updateAAL()
					this.$emit('changeInElevation', 0)
				}
			}
		},
		showLiftCost ( houseLiftingCost ) {
			if (houseLiftingCost == 0) {
				return ''
			} else {
				return '$' + this.abbreviateNumbers(houseLiftingCost)
			}
		},
		...mapActions(useConfigStore, ['abbreviateNumbers','setElevation']),
		...mapActions(useSurfStore, ['updateEL', 'updateSurf', 'updateAAL','setSurfDataFetching'])
	},
	watch: {
		changeInElevation: {
			handler: debounce(function(newValue, oldValue){	
				this.updateHouseLiftingCost(newValue)	
			}, 300),
			deep: true
		},
		EL: {
			handler: debounce(function(newValue, oldValue){	
			}, 300),
			deep: true
		},
		style(value) {
			console.log(value)
		}
	},
}
	

</script>

<!-- CSS -->
<style lang="scss" scoped> 
@import "./../../style/style.scss";
@import "./../../style/oruga.scss";

$primary: #5082c4;

h3 {
	font-family: $font-family;
	font-family: $header-family;
	font-weight: normal; 
	color: $grey;
	text-shadow: 0 0.5px 0 #585858;
	display: flex;
	font-size: 15px;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 0px;
	padding-bottom: 0px;
}


.slider {
	padding: 25px 20px  0 20px;
	margin: 0 0 0 10px !important;
	width: 100%;
}

.response {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	width: 300px;
}

.house-lifting-content {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px;
	width: 300px;
}

.loading h3 {
	white-space: nowrap; 
	display: inline;
}
.loading:after {
  overflow: hidden;
  display: inline-block;
  //vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 1500ms infinite;
  animation: ellipsis steps(4, end) 1500ms infinite;
  content: "\2026";
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 12px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 12px;
  }
}

</style>
