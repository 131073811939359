<template>
<div class='settings-container' > <!--v-if="$route.meta.platform != 'govSub'"-->
	<div class='btn-container'>
		<div id='hamburger-menu' :style='hamburgerSpacing'>	
			<inline-svg 
				:src="require('@/assets/icons/hamburgerMenu.svg')" 
				class='v-center mapboxgl-ctrl-icon' 
				@click='isExpanded = !isExpanded'
			/>
		</div>
	</div>

	<div class='settings-content' v-show='isExpanded'>
		<div class='title'> <h6> {{ textContent['buttons']['settings-btn']['label'] }} </h6> </div> 	
		
		<div v-if="$route.meta.platform != 'govSub'" class='v-center'> 
			<p> {{ textContent['buttons']['language-selector']['label'] }}: </p>
			<div id='language-selector'>
				<language-selector />
			</div>
		</div>
		<div class='v-center' v-if="!onlyLanguage && $route.meta.platform != 'govSub'"> 
			<p> {{ textContent['buttons']['basemap-btn']['label'] }}: </p>
			<div id='basemap-selector'>
				<select id="settings-selector" v-model='currentBasemap'>
					<option value='Mapbox'>Mapbox</option>
					<option value='Satellite'>{{ textContent['buttons']['basemap-btn-sat']['label'] }}</option>
				</select>
			</div>
		</div>
		<div class='v-center' v-if="!onlyLanguage && $route.meta.platform != 'govSub'"> 
			<p> {{ textContent['buttons']['timeline-settings-btn']['label'] }}: </p>
			<div id='timeline-selector'>
				<select id="settings-selector" v-model='currentTimeLine'>
					<option value='year'>{{ textContent['buttons']['timeline-btn-time']['label'] }}</option>
					<option value='depth'>{{ textContent['buttons']['timeline-btn-depth']['label'] }}</option>
				</select>
			</div>
		</div>
		<div class="v-center govSubStuff" v-if="$route.meta.platform == 'govSub'">		
			<div class='username label' >Username: <div style='color: #0054A4'>{{ username }} </div></div>
			<div class='button-container'> 
				<o-button 
					v-if='isLoggedIn' 
					class='auth button'
					v-on:click='showBugReport'
					>
					Report Bug
				</o-button>
			</div>	
			<div class='button-container'> 
				<o-button 
					v-if='isLoggedIn' 
					class='auth button'
					v-on:click='logout'
					>
					Logout
				</o-button>
			</div>
		</div>
	</div>
</div>
<Teleport to='body'>

<bug-report v-model='isBugReportVisible' />

</Teleport>

</template>

<script>
import { mapState, mapGetters, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import { isLoggedIn, logoutUser, getUserInfo } from './../../helpers/auth.js'

import Button from '@/components/Button'
import InlineSvg from 'vue-inline-svg'
import Modal from '@/components/Modal'
import LanguageSelector from '@/components/arkly/LanguageSelector'
import BugReport from '@/components/BugReport'


export default {
	name: 'Settings',
	components: {
		'inline-svg': InlineSvg,
		'btn': Button,
		'modal': Modal,
		'language-selector': LanguageSelector,	
		'bug-report': BugReport,
	},
	props: {
		modelValue: {
			type: Boolean,
		},
		onlyLanguage: {
			type: Boolean,
			default: false
		},
	},
	emits: ['update:modelValue'],
	data() {
		return {
			panelDimensions: undefined,
			isExpanded: false,
			isLoggedIn: null,
			username: null,
			isBugReportVisible: false,
		}
	},
	created() {	
		document.addEventListener('keydown', this.handleEscape);
	},
	mounted() {
		this.isLoggedIn = isLoggedIn()
		if (this.isLoggedIn){
			this.username = getUserInfo()['email']
		}
		//this.addComponentRef('bugReport', this.$refs.bugReport[0])
	},
	unmounted() {
		document.removeEventListener('keydown', this.handleEscape);

	},
	methods: {
		handleEscape (event) {
	        if (event.key === 'Escape' && this.isExpanded) {
	         //if esc key was not pressed in combination with ctrl or alt or shift
	            const isNotCombinedKey = !(event.ctrlKey || event.altKey || event.shiftKey);
	            if (isNotCombinedKey) {
	                //console.log('Escape key was pressed with out any group keys')
	              	this.isExpanded = false
	            }
	        }
		},
		showBugReport() {
			this.isBugReportVisible = true
			console.log(this.isBugReportVisible)
		},
		logout() {
			console.log('logout')
			logoutUser()
			this.isLoggedIn = isLoggedIn()
			router.push({name: 'login'})
		},
		iconSRC(icon) {
			return require(`@/assets/icons/${icon}.svg`)
		},
		...mapActions(useConfigStore, ['setUserLanguage','setBaselayer','changeTimeLine']),
		...mapGetters(useConfigStore, ['getComponentRef']),
	},
	computed: {
		hamburgerSpacing(){
			if (this.$route.meta.platform == 'govSub' && this.isMobile){
				return 'margin: 45px 10px 0 0 !important;'
			} else if (this.$route.meta.platform == 'govSub') {
				return 'margin: 0px 10px 0 0 !important;'
			}
		},
		currentBasemap: {
			get() {
				return this.controls.baselayer
			},
			set(newBaselayer) {
				this.setBaselayer(newBaselayer)
			},
		},
		currentTimeLine: {
			get() {
				return this.controls.timeLineUnit
			},
			set(newTimeLine) {
				this.changeTimeLine(newTimeLine)
			},
		},
		currentLanguage: {
			get() {
				return this.userLanguage
			},
			set(newLanguage) {
				this.setUserLanguage(newLanguage)
			},
		},
		...mapState(useConfigStore, ['isDevOrLocal','controls','userLanguage', 'colors', 'componentRefs', 'screenSize', 'textContent','isMobile']),
	},
	watch: {
		'componentRefs': {
			handler: function(newRef) {

			},
			deep: true,
		},
		'$route': {
			handler: function(result) {
				this.isLoggedIn = isLoggedIn()
			},
			deep: true,
		},
	},
}
</script>

<style lang="scss" scoped>
@import "../../style/arkly.scss";
@import 'vue-select/dist/vue-select.css';

#copy-url {
	position: relative;
	left: 8px;
}

.settings-container {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto auto;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;	
}

.btn-container {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
	grid-column: 1/2;
	grid-row: 1/2;
}

.settings-container #hamburger-menu {
	height: 29px !important;
	width: 29px !important;
	box-shadow: 0 0 0 2px rgba(0,0,0,.1);
	float: right;
	margin: 10px 10px 0 0;
	background: white;
	border-radius: 4px;
	z-index: 11;
	float: right;	
}

.settings-container .btn-container #hamburger-menu > svg{
	color: black;
	background-color: rgb(0 0 0/5%);	
	border: 0;
	box-sizing: border-box;
	cursor: pointer;
	display: block;
	padding: 4px;
	z-index: 100;
}

.settings-container #hamburger-menu:hover{
	filter: brightness(95%);
}

.settings-content {
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 10px 0 10px 0;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
	width: 250px;
	height: fit-content;
	background-color: white;
	grid-column: 1/-1;
	grid-row: 1/-1;
	box-shadow: 0 0 0 2px rgba(0,0,0,.1);
	border-radius: 4px;
	margin-right: 5px;
	margin-top: 5px;
}

#language-selector {
	width: 100%;
	padding-left: 5px;
	padding-right: 5px;
}
#settings-selector {
	width: 160px;
}

.title {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 7px;
	font-size: 25px;
	color: black;
	line-height: 1;
	width: 100%;
}

.close-panel {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
}

.close-panel:hover, .fa-copy:hover {
	filter: brightness(90%);
	fill: grey;
	color: grey;
}

h1 {
	text-align: center;
	margin: 50px 0 80px;
}

.share-network-list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	gap: 10px;
	justify-content: space-between;
	max-width: 240px;
	height: auto;
	margin: auto;
	text-decoration: none;
}

.share-network-list * {	
	text-decoration: none;
}

a[class^="share-network-"] {
	flex: none;
	color: #FFFFFF;
	background-color: #333;
	border-radius: 3px;
	overflow: hidden;
	width: auto;
	display: flex;
	flex-direction: row;
	align-content: center;
	align-items: center;
	cursor: pointer;
	margin: 0 0 10px 0;
}

a[class^="share-network-"]:hover {
	filter: brightness(90%);
}

a[class^="share-network-"] .fah {
	background-color: rgba(0, 0, 0, 0.2);
	padding: 10px;
	flex: 0 1 auto;
}

a[class^="share-network-"] span {
	padding: 0 10px;
	flex: 1 1 0%;
	font-weight: 500;
}


/* GovSub Username, Logout, and Bug Report things */

.username {
	margin-top: -30px;
}

.settings-content .govSubStuff > * {
  padding: 11px 16px;
  text-decoration: none;
  display: block;
  background-color: transparent;
}

.settings-content .button-container {
	width: fit-content;
	display: flex;
	padding: 0 0 4px 0;
	flex-align: center;
	justify-content: center;
	background-color: transparent;
	border-radius: 3px;
}
.settings-content .button-container * {
	width: 150px !important;
}

@media only screen and (max-width: $mobile-breakpoint) {
	.settings-panel {
		position: relative;
		//max-width: calc(100vw - 10px) !important;
		//top: 0px;
		left: calc((100vw - 250px) /2) !important;
		right: calc((100vw - 250px) /2) !important;
	}

}

</style>
