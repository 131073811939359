<template>
<!-- Sidebar Button -->
<span class='container'>
	<h3 class='title'> {{ label }}: </h3>
	<div style="display: flex; width: 100%; justify-content: space-evenly;">
	<div 
		v-if="type == 'select' && options != undefined"
		class='select' 
		v-for='option in options'	
	>
		<div class='button' :class="{ 'is-selected': (option == currentValue)}" @click='selectOption(option)'> 
			{{option}} 
		</div>
	</div>
	<div v-else-if="type == 'dropdown'">
	<div class='dropdown-button' @click='showDropdown()' @mouseleave="closeDropdown()" style="border: 1px solid #B7C1DA; width: 200px"> 
		{{currentValue}}
	</div>
		<ul v-if="dropdownActive" style="z-index: zIndex !important;" class="dropdown" @mouseenter="mouseLeft=false" @mouseleave="closeDropdown()">
        <li v-for="option in options" :class="{ 'is-selected': (option == currentValue)}" @click='selectOption(option)'>
          {{ option }} 
        </li>
      </ul>
	</div>
	<o-field class='slider-container' v-else-if="type == 'slider' && range != undefined">
		<o-slider 
				class='slider' 
				:lazy='true' 
				:min='range.min' 
				:max='range.max'
				:step='range.step'
				aria-label="Fan" 
				:tooltip="false"
				v-model='currentValue'
				ticks
		>
			<template v-for="val in ticks" :key="val">
				<o-slider-tick :value="val"> 
					<template v-if='tickLabels.includes(val)'>	
						{{ val}}  
					</template>
				</o-slider-tick>
			</template>
		</o-slider>
	</o-field>
	<o-switch class='switch-container'
		v-else-if="type == 'switch'" 
		:rounded="false" 
		v-model='currentValue' 
	/>
	</div>
</span>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import { computed, onMounted, onUnmounted, ref } from 'vue'

export default {
	name: 'Control',
	components: {

	},
	props: {
		label: {
			type: String,
			default: '',
		},
		selected: {

		},
		type: {
			type: String,
			default: 'select',
		},
		options: {
			type: Array,
			default: undefined,
		},
		range: {
			type: Object,
			default: undefined,
		},
		width: {
			type: Number,
			default: 220
		},
		zIndex: {
			type: Number,
		},
		noSelected: {
			type: Boolean,
			default: false,
		}
	},
	mounted() {
		
	},
	data() {
		return {
			currentSelection: this.selected,
			panelDimensions: {},
			buttonWidth: {},
			currentValue: this.selected,
			dropdownActive: false,
			mouseLeft: false
		}
	},
	computed: {
		ticks(){
			if (this.range) {
				const steps = [...Array((this.range.max - this.range.min) / this.range.step + 1)].map(
					(_, i) => this.range.min + this.range.step * i
				)
				return steps	
				
			} else {
				return undefined 
			}
		},
		tickLabels() {
			if (this.range) {
				let mid = ((this.range.max - this.range.min)/2) + this.range.min
				return [this.range.min, mid, this.range.max]
			} else {
				return undefined
			}
		},
		...mapState(useConfigStore, ['navbarHeight', 'topbarHeight'])
	},
	methods: {
		updateSlider(selection){
			this.currentSelection = this.range[selection]
		},
		selectOption(option) {
			this.currentValue = option
			if (this.noSelected){
				setTimeout(() => {
					this.currentValue = undefined
				},100)
			}
			this.isPanelVisible = false
			this.dropdownActive = false
			//this.$emit('selection', {'label': this.label.toLowerCase(), 'value': option})
		},
		showDropdown() {
			this.dropdownActive = false
			if (this.dropdownActive){
				this.dropdownActive = false
			} else {
				this.dropdownActive = true
			}
		},
		closeDropdown() {
			this.mouseLeft = true
			setTimeout(() => {
				if (this.dropdownActive && this.mouseLeft){
				this.dropdownActive = false
			}
			}, 1000);
		}
	},
	watch: {
		currentValue(newValue, oldValue){
			this.$gtag.event('control-changed', {
				'event_category': 'category',
				'event_label': 'control-changed',
				'control': this.label.toLowerCase(),
				'newValue': newValue,
				'oldValue': oldValue 
			})

			let value = String(newValue)
			if (this.type == 'switch') {
				value = newValue
			}
			this.$emit('selection', {'label': this.label.toLowerCase(), 'value': value})
		}
	}
}

</script>

<style lang="scss" scoped>
//@import "../style/oruga.scss";
@import "../../style/style.scss";

.o-tip {
	z-index: 9999;
}

.container {
	display: flex;
	align-items: center;
	flex-direction: row;
	width: 100%;
	padding: 0 10px 0 10px;
}

.button {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: calc(#{$topbar-height} - 4px);
	width: auto;
	padding: 2.5px;
	//margin: 2px 2px;
	border-radius: 3px 3px;	
	border: 1px transparent solid;
	box-sizing: border-box;
	background-color: transparent;
	cursor: pointer;	
	overflow: visible;
	font-family: $header-family;
	font-weight: 500;
	font-size: 1.25rem;
	color: $grey;
	white-space: nowrap; //background-image: url("data:image/svg+xml;utf8,<svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='8.5' cy='8.5' r='7' fill='white' stroke='#557AB2' stroke-width='3'/></svg>") !important;
}

.panel .button {
	margin: 5px 2.5px;
}

.button:hover {
	background-color: $light-blue;
	color: $dark-blue;
	//border: 1px $dark-blue solid;
}

.dropdown-button {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: calc(#{$topbar-height} - 4px);
	width: auto;
	padding: 2.5px;
	//margin: 2px 2px;
	border-radius: 3px 3px;	
	border: 1px transparent solid;
	box-sizing: border-box;
	background-color: $light-blue;
	cursor: pointer;	
	overflow: visible;
	font-family: $header-family;
	font-weight: 500;
	font-size: 1.25rem;
	color: $dark-blue;
	//white-space: nowrap;background-image: url("data:image/svg+xml;utf8,<svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='8.5' cy='8.5' r='7' fill='white' stroke='#557AB2' stroke-width='3'/></svg>") !important;
}

.panel .dropdown-button {
	margin: 5px 2.5px;
}

.dropdown-button:hover {
	background-color: $blue;
	color: white;
	//border: 1px $dark-blue solid;
}

.is-selected {
	color: #fdffff;
	background-color: $blue;
	//-webkit-transition: background-color 50ms linear;
    //-ms-transition: background-color 50ms linear;
    //transition: background-color 50ms linear;
	//-webkit-transition: color 50ms linear;
    //-ms-transition: color 50ms linear;
    //transition: color 50ms linear;
	
}
.is-selected:hover {
	color: #fdffff !important;
	background-color: $blue !important;
	//-webkit-transition: background-color 50ms linear;
    //-ms-transition: background-color 50ms linear;
    //transition: background-color 50ms linear;
	//-webkit-transition: color 50ms linear;
    //-ms-transition: color 50ms linear;
    //transition: color 50ms linear;
	
}

//<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="8.5" cy="8.5" r="7" fill="white" stroke="#557AB2" stroke-width="3"/><circle cx="8.5" cy="8.5" r="3" fill="#557AB2" stroke="#557AB2" stroke-width="3"/></svg>
.float-right {
	margin-left: auto;
}

.panel {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: auto;
	background-color: #fff;
	inline-size: fit-content;
}

.slider-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	//padding: 10px;
	width: 100%;
	color: $blue;
}

.switch-container {
	padding-bottom: 3px;
}

.slider {
	padding: 20px;
	margin: 0 0 0 10px !important;
	width: 100%;
}

.tooltip{
	overflow: visible;
	z-index: 9999;
}

.title{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	//margin: 2px 0 0 5px;
	margin: 5px 2.5px;
	white-space: nowrap;
	width: fit-content;
	inline-size: fit-content;
	text-transform: capitalize;
	color: $blue;
	font-weight: 500;
	margin-right: 5px;
	padding-bottom: 0px;
	min-width: 150px !important;
	width: fit-content;
}

.label * {
	line-height: 0px;
}

.icon {
	display: flex;
	align-items: center;
	justify-content: center;
	color: inherit;
	width: 30px;
	max-width: 30px;
	min-width: 30px;
	max-height: 20px;
	background-color: transparent;
}

.icon-container {
	display: block;
	width: 30px;
}

.dropdown{
	position: fixed;
	background: white;
	padding: 10px;
	width: 200px;
	margin-top: -5px;
	margin-left: 2px;
	list-style-type: none;
	display: flex;
    gap: 5px;
    flex-direction: column;
	color:black;
	border: 1px solid $light-blue;
	border-radius: 3px 3px;	
}
.dropdown:hover{
	color: black;
}
.dropdown li {
	cursor: pointer;
	border-radius: 3px 3px;	
	width: 100%;
}
.dropdown li:hover {
	background-color: $light-blue;
}

@media only screen and (max-width: 800px) {
	.button {
		margin: 5px 0px 5px 5px;
	}
}

@media only screen and (max-width: 650px) {
	
	.title {
		margin: 0 2px;
	}

	.button {
		margin: 5px 0px !important;
		padding: 2px;
		font-size: 1rem;
		min-width: 30px;
	}

	.container {
		display: flex;
		flex-direction: row;
		width: 100%;
		padding: 0;
	}
}

</style>

