<template>
	<div class='damage-by-storm-category' :style="{ height: height, width: width }">
	<h2 class='title'> 
		{{ writtenExplanation }}
	</h2>
	<div class='chart'>
		<BarWithErrorBarChart 
			:data='chartData' 
			:options='options'
			style="width: 100%; height: 100% !important;"
			:height="100"
		/>
	</div>
</div>
</template>

<script>
import Chart from 'chart.js/auto'
import { createTypedChart, Bar } from 'vue-chartjs'
import { BarWithErrorBarsController } from 'chartjs-chart-error-bars'
import { mapState, mapGetters, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'

Chart.register(BarWithErrorBarsController)

const BarWithErrorBarChart = createTypedChart(BarWithErrorBarsController.id, BarWithErrorBarsController)

export default{
	name: 'App',
	components: {
		BarWithErrorBarChart,
	},
	props: {
		propertiesAtRisk: {}, 
		buildingCategory: {}, 
		year: {
			type: String,
			default: 2020
		},
		storm: {
			type: String,
		},
		projection: {}, 
		yAxisLabel: {},
		xAxisLabel: {},
		max: {},
		width: {
			type: String,
			default: '100%',
		},
		height: {
			type: String,
			default: '100%',
		},
		startingExpectedLoss: {},
	},
	data() {
		return {
			stormCategory: 'category1',
			stormColors: {
				'cat1': '#ffffcc',
				'cat2': '#ffe775',
				'cat3': '#ffc140',
				'cat4': '#ff8f20',
				'cat5': '#ff6060',
			},
			primaryColor: '#5082c4',
			secondaryColor: '#AFC0E4',
		}
	},
	mounted() {	
		Chart.defaults.font.family = this.bodyFont.family
		Chart.defaults.font.size = this.bodyFont.size
		Chart.defaults.font.weight = '400'
	},
	computed: {
		chartData: function() {
			return {
				labels: this.propertiesAtRisk.labels,
				datasets: [
					{
						data: this.propertiesAtRisk.values,
						backgroundColor: this.primaryColor,
						errorBarLineWidth: 3,
						errorBarWhiskerLineWidth: 3,
						errorBarWhiskerColor: this.secondaryColor,
						errorBarColor: this.secondaryColor,
						borderColor: 'rgb(80, 130, 196)', 
					},
				],
			}
		},
		options: function() {
			return {
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					legend: {
						display: false,
					},
					tooltip: {
						callbacks: {
							title: (context) => {
								return null
							},
							label: (context) => {
								return [`There are ${context.parsed.y} households`, `at risk of displacement in the year ${context.label}`]

							},
						},
					},
				},
				scales: {
					x: {
						title: {
							display: true,	
							text: this.xAxisLabel,
							font: {
								size: 18,
								weight: '600'
							},
							padding: 6,
						},
					},
					y: {
						title: {
							display: true,	
							text: this.yAxisLabel,
							font: {
								size: 14,
								weight: '600'
							},
							padding: 4,
						},
						beginAtZero:true,
						fontSize: 14,
						fontStyle: '400',
						min: 0,
						suggestedMax: this.max,
						ticks: {
							callback: (value, index, values) => {
								const abbreviatedValue = this.abbreviateNumbers(value, 3) 
								return abbreviatedValue
							},
							padding: 10,
						}
					},	
				},
			}
		},
		formattedData: function() {
			/*
			let data = this.propertiesAtRisk
			console.log(data)


			const keys = this.settings['storm']
			const values = Object.values(data)
			const formattedArray = []
			keys.forEach( storm =>  {
				const valueAtYear = data[storm.replace(' ', '')][String(this.year)]
				function calcDeviation(mean, stdev) {
					let deviation = mean + stdev
					if ( deviation < 0 ){
						return 0
					} else {
						return deviation
					}
				}
				
				formattedArray.push( {
					y: valueAtYear['mean'],
					yMin: calcDeviation(valueAtYear['mean'], -valueAtYear['stdev']),
					yMax: calcDeviation(valueAtYear['mean'], valueAtYear['stdev'])
				})
			})
			*/

			const colors = keys.map((key) => {
				return (this.stormCategory == key) ? 'rgb(80, 130, 196)' : 'rgb(74, 74, 74)'
			})

			return { colors: colors }
		},
		/*
		max() {
			let data
			if (this.$route.params.type == "city") {
				data = this.startingExpectedLoss['total'][this.buildingCategory]
			} else {
				data = this.startingExpectedLoss['total']
			}

			
			//Makes array of all possible RCP values
			let maxValues = Object.values(data).map(projection => { 
					return Object.values(projection).map(category => {
						return Object.values(category).map(year => {
							// Using Exponential Notation ie: 8e+2
							// Precision is set to 0, so only one digit is in front of the 'e'
							return Math.ceil((this.findDeviation(year, 1)).toExponential(1))					
						}).flat()
					}).flat()
				}).flat()//merges each inner array into one array of all values
			
			let max = Math.max(...maxValues) 
			return max
		},
		*/
		writtenExplanation() {
			/*
			const data = this.expectedLoss['total'][this.projection]
			const storm = this.settings.storm.map(x => x.replace(' ', ''))
			const dataType = this.$route.params.type


			let response = ``
			
			if (data[storm[0]][this.year]['mean'] > 0){
				response = '1'			
			} else if (data[storm[1]][this.year]['mean'] > 0){
				response = '5'
			} else if (data[storm[2]][this.year]['mean'] > 0){
				response = '10'
			} else if (data[storm[3]][this.year]['mean'] > 0){
				response = '50'
			} else if (data[storm[4]][this.year]['mean'] > 0){
				response = '100'
			} else {
				return `In ${this.year}, this ${dataType} is unlikely to experience coastal flooding.`
			}
			
			return `In ${this.year}, this ${dataType} is likely to be flooded during a ${response} year storm and above.`
			*/
			return `Households at Risk of Displacement from a ${this.storm} Storm`.replace('year','Year')
		},
		...mapState(useConfigStore, ['bodyFont', 'colors', 'settings']),
	},
	methods: {
		findDeviation(data, numberAboveOrBelow){
			const deviation = data['mean'] + numberAboveOrBelow*data['stdev']
			return deviation < 0 ? 0 : deviation
		},
		abbreviateNumbers ( number, precision ) {

			const max = this.max
			const abbrev = {'':1, 'K':1000, 'M':1000000, 'B':1000000000}
			const unrangifiedOrder = Math.floor(Math.log10(Math.abs(number)) / 3)
			const order = Math.max(0, Math.min(unrangifiedOrder, Object.keys(abbrev).length -1 ))
			const suffix = Object.keys(abbrev)[order]

			if ((number / Math.pow(10, order * 3)) == 0) {
				return 0
			} else {
				return Number((number / Math.pow(10, order * 3)).toPrecision(precision)) + suffix
			}	
		},
	},
	watch: {
		height: function(newValue) {
			for (var id in Chart.instances) {
				Chart.instances[id].resize()
			}
		},
		width: function(newValue) {
			for (var id in Chart.instances) {
				Chart.instances[id].resize()
			}
		},
		max: function(newMax){

		},
	}
}
</script>

<style lang="scss" scoped>
@import '../../style/style.scss';

.controls {
	display: flex;
	flex-direction: row;
	justify-content: space-around;;
	align-items: center;
}

.control {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	height: auto;
}

// nth-child(n+2) allows you to select every item that isn't 
// the first child. Unlike :not(:first-child) it is accepted in 
// IE
.control *:nth-child(n+2) {
	margin-left: 10px;
}

@media screen and (max-width: 600px) {
	.controls {
		flex-direction: column;
	}
	
	h3 {
		margin: 0px;
	}
}

.control-label {
	padding-bottom: 4px;
}

.damage-by-storm-category {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows:
		auto
		auto;
	min-height: 350px;  /* NEW */
	min-width: 0;   /* NEW; needed for Firefox */
	width: 100%;
	flex-direction: column;
	color: $grey;
	overflow: hidden;
}

.title {
}

</style>

