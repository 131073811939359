import { reactive } from 'vue'
import { io } from 'socket.io-client'


export const socketState = reactive({
  connected: false,
  quotes: [],
})

// "undefined" means the URL will be computed from the `window.location` object
//console.log(window.location.hostname.includes('dev.'))

// Only RocketFlood can change where the webhook points
let URL = 'https://dev-api.hightide.ai'

export let socket = undefined

export const connectToSocket = async (applicationId) => {
	return new Promise((myResolve, myReject) => {
		socket = io(URL, {
			// The applicationId value is sent to the backend,
			// so that the newly created connection can be added
			// to a "room" for that application
			auth: {
				'applicationId': applicationId, 
			},
			path: '/ws/socket.io/',
			transports: ['websocket', 'polling'],
			cors: {
				credentials: true,
			}
		})

		socket.onAny((event, ...args) => {
			//console.log(`got ${event}`);
		})

		socket.on("error", (error) => {
			console.log(error)
		})

		socket.on("connect", () => {	
			console.log('connected to websocket')
			socketState.connected = true
			myResolve(socket)
		})
		
	})
}

/*
if (socket && socketState.connected) {
	socket.on('new_quote', (...args) => {
		console.log('new quote received')
		console.log(args[0])
		socketState.quotes.push(args[0])
	})

	socket.on("disconnect", () => {
	  socketState.connected = false
	})
}

export class Socket {
	constructor () {
		this.connected = false
		this.socket = undefined
		this.applicationId = undefined
		this.quotes = []
		this.OnNewQuote = undefined
	}
	
	async connect(applicationId, onNewQuote){
		return new Promise((myResolve, myReject) => {
			this.applicationId = applicationId
			this.onNewQuote = onNewQuote
			socket = io(URL, {
				auth: {
					'applicationId': applicationId, 
				},
				path: '/ws/socket.io/',
				transports: ['websocket', 'polling']
			})

			socket.on('new_quote', quote => {
				this.quotes.push(quote)
				if (this.onNewQuote) {
					this.onNewQuote
				}
			})

			socket.on("connect", () => {
				this.connected = true
				myResolve(socket)
			})

			socket.on("disconnect", () => {
				this.connected = false
			})
		})
	}
}
*/

