<!-- HTML -->
<template>
<div class='risk-container'>
	<!--
	<div class='close-content'>
		<btn 
			backgroundColor='transparent'
			size='small' 
			color='grey' 
			icon='chevron-left'
			@click='closePanel'	
		/>
	</div>-->
	<div class='risk-content'>
		<notification 
			variant='warning' 
			title='Attention'
			content='Flood insurance is not included with homewners insurance'
		/>


		<h1 align="left"> Know Your Risk </h1>
		<hr /> 
		<h2 align="left"> Understand your flood zone </h2>
		<p align="left">  If your flood zone starts with A or V, you are in FEMA’s Special Flood Hazard Area. If you have a federally-backed mortgage, you are required to buy flood insurance. </p>

		<hr />

		<h2> Understand your annual flood risk </h2>
		<p align="left"> Flooding is the most expensive natural hazard, which is why it’s not bundled with your homeowners insurance coverage.</p> 

		<p align="left">Flood risk can be difficult to understand. To make it easier, we estimated the value of your yearly flood risk in dollars. The technical term used by engineers and actuaries for this number is “average annual loss”. You can also think of it as a "self-insurance estimate."</p>

		<p align="left">If you don’t buy flood insurance or reduce your risk, your self-insurance estimate is the amount of money you would have to set aside each year to keep up with the cost of long term flood damages. You can compare the self-insurance estimate directly to the annual cost of insurance or an elevation project.</p>

		<expert-consult variant='arkly' consultType='risk'/>

		<hr />

		<h2> Understand different types of flooding </h2>
		<p align="left"> Flooding can be caused by storm surge from the coast, heavy rainfall, or overflowing rivers. </p>

		<p align="left"> Our self-insurance estimate only includes flooding from storm surge. Use our Flood Risk Time Machine on the map to see how risk increases over time due to sea level rise. </p>

		<p align="left"> Enter your email here to stay updated on our progress as we further develop our flood risk model and expand coverage area. </p>

		<o-field>
			<o-input
				v-model='email'
				placeholder="Email... " 
				size='medium'
				labelClass='btn-label'
				:height='24'
			/>
			<o-button variant="primary" size='small' :height='24' v-on:click="postBug">Submit</o-button>
		</o-field>

		<sidepanel-footer />
	</div>
</div>
</template>

<!-- JavaScript -->
<script>
import { mapState, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import { useSurfStore } from '@/store/surf'
import Button from '@/components/Button'
import ExpertConsult from '@/components/arkly/ExpertConsult'
import Notification from '@/components/sidepanel/Notification'
import Footer from '@/components/sidepanel/Footer'

export default {
	name: 'flood-risk',
	components: {
		'btn': Button,
		'notification': Notification,	
		'sidepanel-footer': Footer,
		'expert-consult': ExpertConsult,
	},
	data() {
		return {
			email: undefined,
		}
	},
	computed: {
		...mapState(useConfigStore, []),
		...mapState(useSurfStore, ['meanNFIP']),
	},
	methods: {
		postBug() {
			if (this.email) {
				this.reportBug(`Wishes to be Notified.` , this.email)
			}
		},
		closePanel() {
			this.$emit('closePanelContent') 
		},
		...mapActions(useSurfStore, ['reportBug']),
		...mapActions(useConfigStore, []),	
	},
}

</script>

<!-- CSS -->
<style lang="scss" scoped>
@import "../../style/oruga.scss";
@import "../../style/arkly.scss";


h1 {
	text-align: center;
	font-size: 30px;
	font-weight: bold;	
	margin: 20px 5px;
}

h2 {
	text-align: center;
	line-height: 1.4;
	margin: 20px 5px 10px;
}

p {
	margin-left: 5px;
	margin-right: 5px;
}

.close-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	height: 25px;
	width: min-content;
	z-index: 999;
}

.risk-container {
	display: grid;
	height: 100%;
	max-width: 100%;
	grid-template-rows: min-content auto;
	grid-template-columns: 1fr;
}

.risk-content {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	max-width: 100%;
	width: 100%;
	height: 100%;
	overflow: hidden;
	padding-bottom: 20px;
}

@media only screen and (max-width: $mobile-breakpoint) {
	.risk-content {
		max-width: calc(100vw - 40px);
	}
}

</style>

